import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
class forgotpassword extends Component {
    constructor(props){
        super(props);
        this.state = {	
		   fields: {},
           errors: {},
		   render: false
        }
		this.redirectDashboard=this.redirectDashboard.bind(this);
     }

    logout() {
    if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  
  componentDidMount(){	  
  }
  handleChangeName(field, e){         
  let fields = this.state.fields;
  fields[field] = e.target.value;        
  this.setState({fields});
  }
  redirectDashboard(){
  this.props.history.push('/');
  }
  handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  const query = new URLSearchParams(this.props.location.search);
      const token = query.get('token');
     //param 
     fields["action"]="resetPassword";
	 fields["authetication_token"]=token;
      //message
      if(!fields["user_password"]){
         formIsValid = false;
         errors["message"] = "Enter New Password";
      }
	  
	  if(!fields["user_password_confirmation"]){
         formIsValid = false;
         errors["message"] = "Enter Confirm Password";
      }
	  
     this.setState({errors: errors});
     return formIsValid;
    }
	
  updatepassword(e){
      e.preventDefault();
      if(this.handleValidation()){
		 console.log(this.state.fields); 
        this.resetPasswordApi();
      }else{
         alert("Mandatory fields are empty");
      }
    }
	
  resetPasswordApi()
  {
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
              alert('Password Reset Successfully!');
           }
           else{
             alert('Something went wrong, please try again');
           }
      });
    }
	handleNewPassword(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
     }
	 handleConfirmPassword(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
     }
 
render() {
 return (
    <div id="ember361" className="ember-view ember-app showback">
	<div class="navbar navbar-inverse navbar-static-top" role="navigation">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="navbar-collapse collapse">
      <ul class="nav navbar-nav navbar-left">
        <li><a class="home1" onClick={this.redirectDashboard}>Home</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
      </ul>
    </div>
  </div>
</div>
	  <div className="container">
      <div className="row"><div className="col-xs-12"></div></div>
      <div className="row">
      <div className="col-xs-12">
	  <h2>Change your password</h2>
<form 
className="new_user"
id="new_user"
onSubmit= {this.updatepassword.bind(this)} >
<div><label htmlFor="user_password">New password</label><br />
<input 
autoFocus="autofocus" 
id="user_password" 
name="user_password" 
type="password"
onChange={this.handleNewPassword.bind(this, "user_password")}
value={this.state.fields["user_password"]} />
</div>
<div><label htmlFor="user_password_confirmation">Confirm new password</label><br />
<input
id="user_password_confirmation"
name="user_password_confirmation"
type="password"
onChange={this.handleConfirmPassword.bind(this, "user_password_confirmation")}
value={this.state.fields["user_password_confirmation"]} /></div>
<div className="row"><div className="col-xs-12"></div></div>
<div className="row"><div className="col-xs-12"></div></div>
<div className="row"><div className="col-xs-12"></div></div>
<div className="row"><div className="col-xs-12"></div></div>
<div className="row"><div className="col-xs-12"></div></div>
<div className="row"><div className="col-xs-12"></div></div>
<div><input name="commit" type="submit" class="btn btn-primary" value="Change my password" /></div>
</form>
<a href="https://safeguardmerp.com">Sign in</a><br />
</div>
</div>
</div>
	  
</div>
    );
} 
}

export default forgotpassword;