import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization'
import Home from './Home';
import Popup from './components/EmailPopup';
import Login from './Login';
import Profile from './Profile';
import  alaSQLSpace from 'alasql';
import { Document, Page } from 'react-pdf';
import Pdf from './pdf/Booking.pdf';
import { Preview, print } from 'react-html2pdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
/*import MultiPage from "./components/MultiPage";*/
import PrintButton from "./components/PrintButton";
import $ from 'jquery';

class Charts extends Component {
       constructor(props){
       super(props);
       this.state = {
	   ObjCollection: [],
	   checklistType: 0,
	   chartStatus_template:{},
	   ObjCharts: [],
	   isLoggedIn: false,
	   render: false,
	   numPages: null,
	   pdf_template : {},
	   loader_class: '',
	   ObjPDF: [],
	   showPopup: false,
	   showHelpPopup: false,
	   pageNumber: 1
        }
    this.Gotoorg=this.Gotoorg.bind(this);
	this.GotoHelp=this.GotoHelp.bind(this);
	this.GotoHome=this.GotoHome.bind(this);
    this.GotoDoc=this.GotoDoc.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
    this.GotoProfile=this.GotoProfile.bind(this);
	this.GotoSendMessage=this.GotoSendMessage.bind(this);
	this.GotoViewMessage=this.GotoViewMessage.bind(this);
	this.GotoForm=this.GotoForm.bind(this);
    }
	  onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
      }

      getChartList(){
      const query = new URLSearchParams(this.props.location.search);
      const collectionid = query.get('collectionid');
      let safeguard= localStorage.getItem("safeguard");
      let safeguarddata=JSON.parse(safeguard);
      let charts=safeguarddata.charts;
	  let collections=safeguarddata.collections;
	  let res_collection = [];
	  res_collection = alaSQLSpace('SELECT * FROM ? where id = ?', [collections,collectionid]);
	  let checklist_active = res_collection[0].checklist_active;
	  let res;
	  if(checklist_active=='1')
	  {
      res= alaSQLSpace('SELECT * FROM ? where collection = ? and (checklist = ? or checklist = ?)', [charts,collectionid,'1','0']);
	  }else{
	  res= alaSQLSpace('SELECT * FROM ? where collection = ? and checklist = ?', [charts,collectionid,'0']);	  
	  }

      this.setState({
        ObjCharts:res
      })
    }
	
    Gotoorg(){
        this.props.history.push('/Organization');
        //alert("click");
    }
	GotoHelp(){
		this.props.history.push('/help');
	}
	GotoReport()
	{
		this.props.history.push('/Report');
	}
	GotoForm()
	{
		this.props.history.push('/Form');
	}
	GotoHome(){
    this.props.history.push('/Collection');
    }
	redirectAction(chartid,multichecklistid)
	{
	  localStorage.setItem('current_step', 1);	
	  const query = new URLSearchParams(this.props.location.search);
      const collectionid = query.get('collectionid');	
	  let chartStatus_template = this.state.chartStatus_template;
	  chartStatus_template["chartid"]=chartid;
	  chartStatus_template["action"]='getChartType';
	  
	  fetch(global.api_url,
     {
         method: 'POST',
		 crossDomain:true,
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.chartStatus_template)
     })
      .then((response) => response.json())
      .then((response) => {
			 let status=response.status;
			 let checklist = response.checklist;
			 if(status=='true')
			 {
			 this.props.history.push('/ChartsAction?collectionid='+collectionid+'&chartid='+chartid+'&checklistid='+multichecklistid+'&chartType='+checklist);
			 localStorage.setItem('chartid', '');
		localStorage.setItem('chartid', chartid);
		localStorage.setItem('chart_class', 'ember-view ember-app ember-application');
		localStorage.setItem('current_multichecklistid', '');
			 }else{
			 this.props.history.push('/ChartsAction?collectionid='+collectionid+'&chartid='+chartid+'&checklistid='+multichecklistid+'&chartType='+checklist);
			 localStorage.setItem('chartid', '');
		localStorage.setItem('chartid', chartid);
		localStorage.setItem('chart_class', 'ember-view ember-app ember-application');
		localStorage.setItem('current_multichecklistid', '');
			 }
			 
      });
		
	}
	
	togglePopup() {
   this.setState({
     showPopup: !this.state.showPopup
   });
 }
 
 openPDF()
 {
	  //alert('Please wait while your pdf is loading . . .');
      const query = new URLSearchParams(this.props.location.search);
      const collectionid = query.get('collectionid');
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid=session_array.organization_id;
	  let organization_name = this.state.ObjOrganization;
	  organization_name = organization_name.toLowerCase();
	  organization_name = organization_name.replace(/:\s*/g, " ");
	  organization_name=organization_name.replace(/\//g,"");
	  organization_name=organization_name.replace(/\s+/g,"_");
	  let filename = organization_name+'_'+collectionid+'.pdf';
	  
	  let pdf_template = this.state.pdf_template;
      let ObjPDF = this.state.ObjPDF;
	  pdf_template["organizationid"]=organizationid;
	  pdf_template["collectionid"]=collectionid;
	  pdf_template["action"]='showPDF';
	  /*window.location.href = 'https://safeguardapi.safeguardmerp.com/download/'+filename;*/
	  
	  /*this.setState({
              loader_class: 'loader1'
          })*/
	  
	  
	 fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.pdf_template)
     })
      .then((response) => response.json())
      .then((response) => {
			 const status=response.status;
			 if(status === "true")
             {
				 window.location.href = 'https://safeguardapi.safeguardmerp.com/download/'+filename;
			 }
      });
	  
 }
	
	GotoDoc(){
		this.props.history.push('/Doc');
  }

  GotoProfile(){
    this.props.history.push('/Profile');
  }
 
 GotoSendMessage()
  {
	  this.props.history.push('compose');
  }
  
  GotoViewMessage()
  {
	this.props.history.push('messages');
	 
  }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  
  componentDidMount(){
  this.getCollectionList();
  this.getChartList();
  this.getOrganizationList();
  }
  getOrganizationList()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }
  getCollectionList()
  {
	  const query = new URLSearchParams(this.props.location.search);
      const collectionid = query.get('collectionid');
      let safeguard= localStorage.getItem("safeguard");
      let safeguarddata=JSON.parse(safeguard);
      let collections=safeguarddata.collections;
      let res = alaSQLSpace('SELECT * FROM ? where id = ?', [collections,collectionid]);
      this.setState({
        ObjCollection:res
      })
  }
  qualityPDF(collectionid,organization_id)
  {
	const input = document.getElementById('application');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
		const pdf = new jsPDF({
          orientation: 'landscape',
        });
		const pdfWidth = 900;
		const pdfHeight = 700;
        pdf.addImage(imgData, 'PNG', 0, 0);
		
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
  }
  render() {
	  const { pageNumber, numPages } = this.state;
	  const query = new URLSearchParams(this.props.location.search);
      const collectionid = query.get('collectionid');
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organization_id=session_array.organization_id;
	  let user_level_2 = session_array.admin;
	  let user_level_1 = session_array.organization_admin;
	  let user_super_admin = session_array.super_admin;
	  
	
	let chartList = this.state.ObjCharts.map((v,index) => (
	<li key={index}>{v.color_theme!=''? <a style={{'backgroundColor': v.color_theme === 'yellow' ? '#f5c200' : v.color_theme === 'orange' ? '#db5700' : v.color_theme === 'maroon' ? '#730e12' : v.color_theme === 'olive_green' ? '#649409' : v.color_theme === 'dark_green' ? '#1d3c0e' : v.color_theme === 'royal_blue' ? '#0d2a7a' : ''}} id={v.id} className="ember-view" onClick={() => this.redirectAction(v.id,v.multichecklistid)}>{v.title}</a> : <a id={v.id} className="ember-view" onClick={()=>this.redirectAction(v.id,v.multichecklistid)}>{v.title}</a>}</li>
		));
	
	
	let chartContentTitle = this.state.ObjCharts.map((v,index) => (
	  <h2 key={index}>{v.supporting_materials_title}</h2>
    ));
	
	let chartContent = this.state.ObjCharts.map(v => (
	  ReactHtmlParser(v.supporting_material_content)
    ));
	
	let supporting_material_title = this.state.ObjCollection.map((v,index) => (
	  <h2 key={index}>{v.supporting_material_title}</h2>
    ));
	let supporting_material_content = this.state.ObjCollection.map(v => (
	  ReactHtmlParser(v.supporting_material_content)
    ));
if (localStorage.getItem('session')) {	  
 return (
		 
	<div className="ember-view ember-app ember-application"><div className="menu">
        <ul>
          <li className="homeicon"><a onClick= {this.GotoHome}>h</a></li>
          <li className="back"><a onClick= {this.GotoHome}>M</a></li>
          <li className="titlebar">{this.state.ObjOrganization}</li>
          <li className="logout"><a onClick= {this.logout}>o<span className="text">Logout</span></a></li>
          <li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>
          <li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
		  <li className="docs" onClick={this.GotoForm}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/form.png" width="22px" height="20px" /> <span>Form</span></span></a></li>
		  <li className="docs" onClick={this.GotoReport}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/report.png" width="22px" height="20px" /> <span>Report</span></span></a></li>
          <li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
          {user_level_1==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
        </ul>
      </div>
      <div id="application">
        <ul className="book charts">
        {chartList}
        </ul>
        <div className="chart">
		
		<div className={this.state.loader_class}></div>
        <div id="ab" className="supporting_material_content">
		{supporting_material_title}
        {supporting_material_content}
        </div>
        </div>
        </div>
        </div>
    );
} else {
 window.location.href = '/';
}	
  }
}

export default Charts;