import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
const options = [];
const seleted_ids=[];

class EditFileFolder extends Component {
        constructor(props){
        super(props);
        this.state = {
		defaultValue: 'Select a color',
        currentValues: [],	
        name: '',
		selected: [],
        ObjOrganizations: [],
        ObjGroup: [],
		ObjFileFolder: [],
        fields: {},
		groupList: '',
		toggle_no: 0,
        organization_template: {},
        group_template: {},
		filefolder_template: {},
        error: {}
        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectAttachment=this.redirectAttachment.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.clickSelect=this.clickSelect.bind(this);
		this.GotoReport=this.GotoReport.bind(this);
     }
     
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	  
      handleChangeName(field, e){      
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
    redirectOrganization(){
      this.props.history.push('/Organization');
      }
	  
	  redirectAttachment()
	  {
		  const query = new URLSearchParams(this.props.location.search);
          const organizationid = query.get('organization');
		  this.props.history.push('/Attachment?organization='+organizationid);
	  }

      GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {
            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }
	  GetFileFolderDetails(){
      let filefolder_template = this.state.filefolder_template;
      let ObjFileFolder = this.state.ObjFileFolder;
      filefolder_template["action"]="detailFileFolder";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const folderid = query.get('folderid');
      filefolder_template["organizationid"]=organizationid; 
	  filefolder_template["folderid"]=folderid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.filefolder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const folder_details = response.folder_details;
           if(status === "true")
           {
            this.setState({
              ObjFileFolder: response.folder_details,
			  groupList: response.folder_details.group_list
          })
           }       
     });
 }
 
      handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  const folderid = query.get('folderid');
	  let file_folder_name = document.getElementById('file_folder_name').value;
	  
	  
	  const resource_array = this.state.ObjGroup;
	  const collection_array = [];
	  
	  for (let i=0;i<resource_array.length;i++){
        if(resource_array[i].check_class ===true){
            collection_array.push(resource_array[i].id);
        }
	  }
	  const resource_string = collection_array.toString();
	  fields["groups"]= resource_string;	  
     //action 
     fields["action"]="editFileFolders";
     fields["organizationid"]=token;
	 fields["folderid"]=folderid;
	 
      if(file_folder_name==''){
         formIsValid = false;
         errors["file_folder_name"] = "Cannot be empty";
      }else{
		  fields["file_folder_name"]=file_folder_name;
	  }
     this.setState({errors: errors});
     return formIsValid;
    }
    
      folderSubmit(e){
      e.preventDefault();
      if(this.handleValidation()){
        this.editFolderApi();
        console.log(this.state.fields);
      }else{
         alert("Mandatory Field Emplty!");
      }
    }
	
	  editFolderApi()
      {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  const folderid = query.get('folderid');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
              this.props.history.push('/Attachment?organization='+ token);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
	 GotoReport()
	{
		this.props.history.push('/Report');
	}
	 clickSelect()
	 {
		 let toggleno = this.state.toggle_no;
		 if(toggleno==0)
		 {
			 document.getElementById("multiselect_ul").style.display="block";
			 this.setState({
              toggle_no: 1
             })
		 }else{
			 document.getElementById("multiselect_ul").style.display="none";
			 this.setState({
              toggle_no: 0
             })
		 }
		
		 
	 }
      
      getEditGroupList(){
      let group_template = this.state.group_template;
      let ObjGroup = this.state.ObjGroup;
      group_template["action"]="getEditGroupList";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const folderid = query.get('folderid');
      group_template["organizationid"]=organizationid;
	  group_template["folderid"]=folderid;
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.group_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const group_details = response.group_details;
           if(status === "true")
           {
            this.setState({
              ObjGroup: group_details
          })

           for(let i=0;i<this.state.ObjGroup.length;i++){
            options.push(
              { 
                label: this.state.ObjGroup[i].name,
                value: this.state.ObjGroup[i].id
               }
            )
           }
		   
           }       
     });
     
    }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  
  onChangepick=event=>{
  seleted_ids.length=0;
  for(let j=0;j<event.length;j++)
  {
  seleted_ids.push(event[j].value)
  }
 }
 
 onToggle(index, e){
  	let newItems = this.state.ObjGroup.slice();
		newItems[index].check_class = !newItems[index].check_class
  	this.setState({
    	ObjGroup: newItems
    })
  }
 
 
  componentDidMount() {
    this.GetOrganizationDetail();
    this.getEditGroupList();
	this.GetFileFolderDetails();
  }

  render() {
	let groupList = this.state.ObjGroup.map(v => ( 
	<li>
              <label className="dropdown-menu-item checkbox" htmlFor={v.id} >
                {v.check_class!='true'?<input id={v.id} name="file_folder_group_ids" type="checkbox" value={v.id} onChange={this.onChangepick} />:<input id={v.id} name="file_folder_group_ids" type="checkbox" value={v.id} onChange={this.onChangepick} defaultChecked="true" />}
				
                {v.name}
              </label>
            </li>
    ));
	const allSelected = options.map(({ value }) => value);
	if (localStorage.getItem('session')) {
    return (
        <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			  <li className=""><a onClick={this.GotoReport}>Report</a></li>
            <li><a>Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » <a onClick={this.redirectAttachment}>Supporting Files</a>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Edit File Folder</h2>
  
  <form acceptCharset="UTF-8"
  onSubmit= {this.folderSubmit.bind(this)}
  className="new_file_folder"
  id="new_file_folder">
  <div className="form-group">
      <label htmlFor="file_folder_name">Name</label>:
      <input 
	  className="form-control"
      placeholder="Name"
      id="file_folder_name" 
      name="file_folder_name" 
      type="text" 
      onChange={this.handleChangeName.bind(this, "file_folder_name")}
      defaultValue ={this.state.ObjFileFolder.name}
     />
    </div>
	 <h4>Access For the Groups</h4>
     
	
	<div className="form-group">
    <div className="btn-group" role="group" aria-label="...">
      <div className="btn-group">
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" onClick={this.clickSelect}> Select Group Access <span className="caret"></span></button>
        <ul className="dropdown-menu" id="multiselect_ul">
		  {this.state.ObjGroup.map((item, i) =>
		  <li key={i}>
		  <label className="dropdown-menu-item checkbox" htmlFor={item.id} >
		  <input id={item.id}
		   name="file_folder_group_ids"
		   type="checkbox"
		   value={item.id}
		   defaultChecked={item.check_class}
		   onChange={this.onToggle.bind(this, i)}
		   />
		  {item.name}
		  </label>
		  </li>
		   )}
        </ul>
      </div>
    </div>
</div>
  
    <hr/>
    <div className="form-group">
      <input className="btn btn-success" name="commit" type="submit" value="Save"/>&nbsp;
      <a className="btn btn-default" onClick={this.redirectAttachment}>Cancel</a>
    </div>
  <hr/>
  </form>
          </div>
        </div>
      </div>
    </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default EditFileFolder;