import React, { Component } from 'react';
import './renamepdf_style.css';
import axios from 'axios';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class RenamePDF extends Component{
	constructor(props){
        super(props);
        this.state = {
        variable_full_name: '',
		ObjComments : [],
		comments_template : {},
        fields: {},
        error: {},
		chartid : '',
		edit_template : {},
		success : '',
		notification : '',
		pdf_name:''
        }
		 this.handleKeyUp = this.handleKeyUp.bind(this);
     }
	handleKeyUp(event) {
	let pdfName = localStorage.setItem('pdf_name', event.target.value);
	
  }
  componentDidMount()
  {
  let pdfname = localStorage.getItem('pdf_name');
  this.setState({
	pdf_name : pdfname
});
  }	
  render() {
  const styleWarning = {
      color: "red",
      padding: "10px",
      fontFamily: "Arial",
	  fontSize: "12px"
    };
	const styleSuccess = {
      color: "green",
      padding: "10px",
      fontFamily: "Arial",
	  fontSize: "12px"
    };
	  
    return (
      <div className='popup'>
        <div className='popup_inner modal-sm height_maxCont'>
		<div className="modal-header">
          <h4>Rename PDF</h4>
		  </div>
		  <div className="modal-body">
				<table className="table table-striped table-bordered table wice-grid">
				<tbody>
				<tr>
			  <td>
			  <div>
			  <input
				type="text"
				className="form-control"
				name="edit_pdf"
				id="edit_pdf"
				defaultValue={this.state.pdf_name}
				onKeyUp={this.handleKeyUp}
				/>
			  </div>
			  </td>
			  </tr>
				</tbody>
				</table>
                </div>
				<div className="modal-footer"><button onClick={this.props.closeRenamePopup} className="btn btn-success">Submit</button>&nbsp;<button onClick={this.props.cancelRenamePopup} className="btn btn-warning">Cancel</button></div>
        </div>
      </div>
    );
  }
}
export default RenamePDF;