import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import alaSQLSpace from 'alasql';
import Select from 'react-select';
import Comments from './components/Comments';
import Rename from './components/Rename';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import $ from 'jquery';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class Form extends Component {
    constructor(props){
        super(props);
        this.state = {
		   storage_template: {},
		   ObjStorage: [],
		   ObjCollection: [],
		   ObjCharts: [],
		   fields: {},
		   userid : '',
		   groupid : '',
		   checklistid : '',
		   reportnameid : '',
		   ObjOrganization:'',
		   isLoggedIn: false,
		   messages_template : {},
		   group_template : {},
		   reportname_template : {},
		   checklist_template : {},
		   ObjMessages : [],
		   user_template : {},
		   ObjUsers : [],
		   ObjGroup : [],
		   ObjReportName : [],
		   ObjSearch : [],
		   ObjNoCharts : [],
		   ObjPDF : [],
		   checklistCount : '',
		   loader: 'spinner',
		   filepath: '',
		   delete_template : {},
		   report_column_template : {},
		   report_name_template : {},
		   org_description:'',
		   render: false,
		   pdf_template : {},
		   button_class : 'button-hide',
		   showPopup: false,
		   showRenamePopup: false,
		   report_name : 'Incident',
		   lockStatus_template : {},
		   loader_class: '',
		   checkboxArray : []
        }
    this.Gotoorg=this.Gotoorg.bind(this);
	this.GotoHelp=this.GotoHelp.bind(this);
    this.GotoDoc=this.GotoDoc.bind(this);
    this.GotoProfile=this.GotoProfile.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
	this.GotoSendMessage=this.GotoSendMessage.bind(this);
	this.GotoEmergency=this.GotoEmergency.bind(this);
	this.GotoShooter=this.GotoShooter.bind(this);
	this.GotoViewMessage=this.GotoViewMessage.bind(this);
	this.redirectChart=this.redirectChart.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.GotoForm=this.GotoForm.bind(this);
	this.redirectWebViewer=this.redirectWebViewer.bind(this);
     }
    redirectWebViewer(filepath,id)
	{
		let weburl = "https://safeguardses.com/"+filepath;
		window.open(weburl, "_blank");
	}
    redirectDashboard(){
		this.props.history.push('/Collection');
	}
    Gotoorg(){
        this.props.history.push('/Organization');
    }
	GotoHelp(){
		this.props.history.push('/help');
	}
	
	GotoDoc(){
		this.props.history.push('/Doc');
    }
	GotoReport()
	{
		this.props.history.push('/Report');
	}
    GotoForm()
	{
		this.props.history.push('/Form');
	}

	redirectChart(moduleid){
		this.props.history.push('/CollectionList?moduleid='+moduleid);
		localStorage.setItem('moduleid', '');
		localStorage.setItem('moduleid', moduleid);
    }

  GotoProfile(){
    this.props.history.push('/Profile');
  }
 
 GotoSendMessage()
  {
	  this.props.history.push('compose');
  }
  
  GotoEmergency()
  {
	  this.props.history.push('emergency');
  }
  
  GotoShooter()
  {
	  this.props.history.push('shooter');
  }
  
  GotoViewMessage()
  {
	this.props.history.push('messages');
	 
  }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  componentWillMount() {
  this.getChecklist();
  }


  getChecklist()
  {
	  this.setState({
			loader_class: 'checklist_loader'
			})
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  let user_details = localStorage.getItem("session");
      let user_array=JSON.parse(user_details);
	  let userid = user_array.id;
	  let checklist_template = this.state.checklist_template;
	  let ObjCharts = this.state.ObjCharts;
	  checklist_template["action"]="getChecklistPDFForm";
	  checklist_template["organizationid"]=organizationid;
	  checklist_template["userid"]=userid;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.checklist_template)
         })
          .then((response) => response.json())
          .then((response) => { 
			
            const status=response.status;
			const checklist=response.list;
			const code = response.code;
           if(status == "true")
           {  
            this.setState({
			  loader_class: '',			  
              ObjCharts : checklist
            }) 
           }else{
			this.setState({				 
              ObjCharts : checklist
            }) 
		   }
     });
	  
  }

  
    render() {
	let organization_details= localStorage.getItem("organization_details");
    let organization_details_array=JSON.parse(organization_details);
	let organization_messaging_enabled = organization_details_array.messaging_enabled;
	let organization_id = organization_details_array.id;
	let token = localStorage.getItem("session");
	let token_array=JSON.parse(token);
	let current_user = token_array.id;
	let user_level_2 = token_array.admin;
	let user_level_1 = token_array.organization_admin;
	let view_message_button = token_array.view_message_button;
	let user_super_admin = token_array.super_admin;
	let organization_token = localStorage.getItem("organization_details");
	let organization_token_array=JSON.parse(organization_token);
	let user_logo = this.state.filepath;
	let org_description = this.state.org_description;
	let filepath = <img className="orgLogo" src="/assets/images/ses-logo.png" /> ;
	let selected_checklist = this.state.checkboxArray;
	
	let collectionList_count = this.state.ObjCollection.length;
	let userList;
	let groupList;
	let reportnameList;
	let checklist_all;
	let search_table;
	let collections_header;
	let checklistCount = this.state.checklistCount;

	search_table = this.state.ObjCharts.map((v, key) => (
      <tr id={key}>
      <td>{key + 1}</td>
	  <td>{v.change_filename}</td>
	  <td>{v.organization_id}</td>
	  <td>{v.userid}</td>
	  <td>{v.created_date}</td>
	  <td>{v.chartid}</td>
	  <td>{v.multichecklist_id}</td>
	  <td><button type="button" className="btn btn-primary" onClick={()=>this.redirectWebViewer(v.filepath,v.id)}>View</button></td>
	  </tr>
    ));
	
	
if (localStorage.getItem('session')) {	  
 return (
    <div id="ember361" className="ember-view ember-app"><div className="menu">
        <ul>
          <li className="homeicon"><a onClick={this.redirectDashboard}>h</a></li>
          <li className="back"><a>M</a></li>
          <li className="titlebar">{this.state.ObjOrganization}</li>
          <li className="logout"><a onClick= {this.logout}>o<span className="text">Logout</span></a></li>
          <li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>
          <li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
		  <li className="docs" onClick={this.GotoForm}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/form.png" width="22px" height="20px" /> <span>Form</span></span></a></li>
		  <li className="docs" onClick={this.GotoReport}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/report.png" width="22px" height="20px" /> <span>Report</span></span></a></li>
          <li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
          {user_level_1==1 || user_super_admin==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
        </ul>
      </div>
      <div id="application" className="ses-form">
      <div>
        <div className={this.state.loader_class}></div>
          <div className="content tableBox">
			<div className="headingButton">
			<div className="orgLogo-container">Form</div>
			</div>
			<div>		
  <table className="table table-striped table-bordered table wice-grid report_table">
  <thead>
    <tr>
      <th>SL No.</th>
      <th>File Name</th>
      <th>Organization</th>
	  <th>Created By</th>
	  <th>Date</th>
	  <th>Checklist Name</th>
	  <th>Report Name</th>
	  <th>Action</th>
    </tr>
  </thead>
<tbody>
{search_table}
</tbody>
</table>
			</div>
          </div>
        
      </div>
      </div>
      </div>
    );
} else {
 window.location.href = '/';
}	
}
}
export default Form;