import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Base64 from 'base-64';
import alaSQLSpace from 'alasql';
import $ from 'jquery';

class flow_cards extends Component {
    state = { flowChartCards: [], activeCardIndex: 0, newFlowCards: [], actionLog: [], log_template: {}, ObjActions: 0, action_template: {}, lastFlowCardIndex:0, lastIndex:0, preIndex:0};

    componentDidMount = async() => {
	//console.log("lastindexprops", this.props.lastFlowCardIndex);
        const { cards, chartid, actions } = this.props;
        this.initialState(cards, chartid, actions);
        this.getCardList();
    }

    componentWillReceiveProps(nextProps) {
	//console.log("willlastindexprops", this.props.lastFlowCardIndex);
    //localStorage.setItem('lastIndex',this.props.lastFlowCardIndex);
        const { cards, chartid, actions, activeBreadCrumb } = nextProps;
        if (this.props.chartid !== chartid) {
            this.initialState(cards, chartid, actions);
            this.props.resetBreadcrumb();
        }
        if (activeBreadCrumb && activeBreadCrumb.length) {
			let blockIndexNumber;
			if(this.props.lastFlowCardIndex>0)
		    {
			blockIndexNumber = this.props.lastFlowCardIndex - 1;
		    }else{
			blockIndexNumber = this.state.activeCardIndex;
		    }
           
            const index = this.state.flowChartCards.indexOf(activeBreadCrumb[blockIndexNumber]);
            this.setState({ activeCardIndex: index });
			
        }
    }

    getCardList() {
        const chartid = localStorage.getItem("chartid");
        let action_template = this.state.action_template;
        let ObjActions = this.state.ObjActions;
        action_template["action"] = "getActionsExist";
        action_template["chartid"] = chartid;
        fetch(global.api_url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.action_template)
            })
            .then((response) => response.json())
            .then((response) => {
                const status = response.status;
                const count = response.count;
                if (status === "true") {
                    this.setState({
                        ObjActions: count
                    })
                }
            });
    }

    initialState = (cards, chartid, actions) => {
	    
		let currentIndex;
		//localStorage.setItem('lastIndex', this.props.lastFlowCardIndex);
		localStorage.setItem('lastIndex',this.props.lastFlowCardIndex);
		//currentIndex = this.props.lastFlowCardIndex;
		currentIndex = localStorage.getItem('lastIndex');
		//console.log(currentIndex);
		
        const flowChartCards = cards.filter(item => item.chart_id === chartid);
        let beginIndex;
		let endIndex;
        flowChartCards.map((item, idx) => {
            let flowChartActions = [];
            item.actions.map((actionItem) => {
                flowChartActions.push(actions.filter(item => item.id === actionItem)[0]);
            });
            item.actions = flowChartActions;
			
            if (item.card_type === 'begin')
			{
                beginIndex = idx;
			}else if (item.card_type === 'end')
			{
                endIndex = idx;
			}else{
			}
			
        });
		
		let currentArray = [];
        const temp = flowChartCards.splice(beginIndex, 1);
        flowChartCards.splice(0, 0, temp[0]);
		//console.log(JSON.stringify(flowChartCards));
		const tempEnd = flowChartCards.splice(endIndex, 1);
		flowChartCards.push(tempEnd[0]);
		
		
		if(currentIndex>0)
		{
			for(let i=0;i<=currentIndex;i++)
			{
				currentArray[i] = flowChartCards[i];
			}
			this.setState({ flowChartCards, newFlowCards: [currentArray[currentIndex]], activeCardIndex: 0});
		}else{
			this.setState({ flowChartCards, newFlowCards: [flowChartCards[0]], activeCardIndex: 0 });
		}

		
		
		
		
        //this.setState({ flowChartCards, newFlowCards: [flowChartCards[0]], activeCardIndex: 0 });
    };

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    saveLog = (actionLog) => {
        let uppercase_actionLog = this.Capitalize(actionLog);
        let drill_local_storage = localStorage.getItem("drill_log");
        let chartid = localStorage.getItem("chartid");
        let session = localStorage.getItem("session");
        let session_array = JSON.parse(session);
        let user_id = session_array.id;
        if (drill_local_storage != '') {
            let drilllog = JSON.parse(drill_local_storage);
            let log_template = this.state.log_template;
            log_template["action"] = "saveLog";
            log_template["log_type"] = actionLog;
            log_template["log_details"] = drilllog;
            log_template["user_id"] = user_id;
            log_template["chart_id"] = chartid;
          
            fetch(global.api_url,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.state.log_template)
                })
                .then((response) => response.json())
                .then((response) => {
                    const status = response.status;
                    const count = response.count;
                    if (status === "true") {
                        alert(uppercase_actionLog + ' log saved successfully!');
                        window.location.reload();
                    } else {
                        alert(uppercase_actionLog + ' log not saved successfully, please try again');
                    }
                });
        } else {
        }
    }
    handleNextButton = actionSelected => {
        const { resetActiveBreadCrumb} = this.props;
        resetActiveBreadCrumb();
        const { activeCardIndex, newFlowCards, flowChartCards} = this.state;
        const actionIndex = flowChartCards.findIndex(item => item.id === actionSelected.destination);
        this.setState({ newFlowCards: [...newFlowCards, flowChartCards[actionIndex]], activeCardIndex: activeCardIndex + 1});
        //console.log(this.state.activeCardIndex);
        let date = new Date().getDate(); //Current Date
        let year = new Date().getFullYear(); //Current Year
        let hours = new Date().getHours();
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minute = new Date().getMinutes();
        minute = minute < 10 ? '0' + minute : minute;
        let second = new Date().getSeconds();
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let now = new Date();
        let thisMonth = months[now.getMonth()];
        let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
        let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
        let current_datetime = dayName + '. ' + thisMonth + ' ' + date + ', ' + year + ' ' + hours + ':' + minute + ':' + second + ' ' + getCurrentAmPm;
        let card_content = actionSelected.content;
        let card_type = actionSelected.card_type;
        let card_id = actionSelected.card_id;
        let destination = actionSelected.destination;
        if (card_content != '') {
            card_content = card_content;
        } else {
            card_content = 'Moved forward to ' + card_type + '(' + destination + ')';
        }
        let drill_local_storage = localStorage.getItem("drill_log");
        if (drill_local_storage == '') {
            let drill_arr = [];
            drill_arr.push({
                datetime: current_datetime,
                content: card_content,
                type: card_type,
                source: card_id,
                destination: destination
            });

            localStorage.setItem('drill_log', JSON.stringify(drill_arr));
        } else {
            let drilllog = JSON.parse(drill_local_storage);
            drilllog.push({
                datetime: current_datetime,
                content: card_content,
                type: card_type,
                source: card_id,
                destination: destination
            });
            localStorage.setItem('drill_log', JSON.stringify(drilllog));
        }
    };

    handlePrevButton = () => {
        const { activeCardIndex, newFlowCards, flowChartCards } = this.state;
		let currentIndex;
		
		//this.setState({preIndex : this.props.lastFlowCardIndex});
		currentIndex = localStorage.getItem("lastIndex");
		
		//console.log(currentIndex);
		
		let currentArray = [];
		let currentPreVal;
        
		
		if(currentIndex>0)
		{
			for(let i=0;i<=currentIndex;i++)
			{
				currentArray[i] = flowChartCards[i];
			}
			this.setState({
            newFlowCards: currentArray.filter((itm, idx) => idx !== currentArray.length - 1),
            activeCardIndex: currentIndex - 1
        });
		if(currentIndex>0)
		{
		localStorage.setItem('lastIndex', currentIndex - 1);
		}
			 
		}else{
			this.setState({
            newFlowCards: newFlowCards.filter((itm, idx) => idx !== newFlowCards.length - 1),
            activeCardIndex: activeCardIndex - 1
        });
			
		}
		
		
		
		
		
		
		
        //this.setState({
//            newFlowCards: newFlowCards.filter((itm, idx) => idx !== newFlowCards.length - 1),
//            activeCardIndex: activeCardIndex - 1
//        });
		
        let currentAction = this.state.newFlowCards[newFlowCards.length - 1].id;

        let safeguard = localStorage.getItem("safeguard");
        let safeguarddata = JSON.parse(safeguard);
        let actions = safeguarddata.actions;
        let res = alaSQLSpace('SELECT * FROM ? where destination = ?', [actions, currentAction]);
       
        let card_content = res[0].content;
        let card_type = res[0].card_type;
        let destination = res[0].card_id;
        let card_id = res[0].destination;
        if (card_content != '') {
            card_content = card_content;
        } else {
            card_content = 'Moved backword to ' + card_type + '(' + destination + ')';
        }

        let date = new Date().getDate(); //Current Date
        let year = new Date().getFullYear(); //Current Year
        let hours = new Date().getHours();
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minute = new Date().getMinutes();
        minute = minute < 10 ? '0' + minute : minute;
        let second = new Date().getSeconds();
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let now = new Date();
        let thisMonth = months[now.getMonth()];
        let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
        let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
        let current_datetime = dayName + '. ' + thisMonth + ' ' + date + ', ' + year + ' ' + hours + ':' + minute + ':' + second + ' ' + getCurrentAmPm;

        let drill_local_storage = localStorage.getItem("drill_log");
        if (drill_local_storage != '') {
            let drilllog = JSON.parse(drill_local_storage);
            drilllog.push({
                datetime: current_datetime,
                content: card_content,
                type: card_type,
                source: card_id,
                destination: destination
            });
            localStorage.setItem('drill_log', JSON.stringify(drilllog));
        }
    };

    render() {
        let drill_local_storage = localStorage.getItem("drill_log");
        let drill = 'drill';
        let incedent = 'incident';
        let actionCount = this.state.ObjActions;
        let data_type;
        let data_class;
        let title_final;
		let blockIndexNumber;
        const { newFlowCards, activeCardIndex } = this.state;
        const { addBreadcrumb } = this.props;
		//console.log(this.props.lastFlowCardIndex);
		//console.log(activeCardIndex);
		let liveIndex;
		let lastPreValue;
		lastPreValue = localStorage.getItem("lastIndex");
		
		
		
		//console.log(JSON.stringify(newFlowCards));
        const activeCardContent = newFlowCards && newFlowCards.length ? newFlowCards[activeCardIndex] : null;
		//console.log(JSON.stringify(activeCardContent));
		
		if(activeCardContent!=undefined)
		{
        if (actionCount > 0) {
            data_type = ' - ' + activeCardContent.card_type;
            data_class = `${activeCardContent.card_type} activecard`;
        } else {
            data_type = '';
            data_class = 'end activecard';
        }
		}
		
		
        const { title } = this.props;
        const renderCardItem = activeCardContent ? (
            <Fragment>
                {(activeCardIndex >= 1 || lastPreValue>=1) && <a className="backbutton" onClick={this.handlePrevButton} />}
                <li className={data_class} data-type={data_type} data-card={activeCardContent.id} data-chart-title={title}>

                    {ReactHtmlParser(Base64.decode(activeCardContent.content))}
                    <p className="card-content-actions">{
                        activeCardContent.actions.length ? activeCardContent.actions.map(item => (
                            <button key={item.id} id={item.card_id} onClick={() => {
                                this.handleNextButton(item);
                                addBreadcrumb(activeCardContent);
                            }}>
                                {(item.content == '') ? 'Next' : item.content}</button>
                        ))
                            :
                            drill_local_storage ?
                                <Fragment>
                                    <button onClick={() => this.saveLog(incedent)}>Save As Incident Report</button>
                                    <button onClick={() => this.saveLog(drill)}>Save As Drill Log</button>
                                </Fragment>

                                : null
                    }</p>
                </li>
            </Fragment>
        ) : null;
        return (
            <div className="chart">
                {this.props.ObjActions > 0 ?
                    <a className="overview-link" onClick={() => this.props.handleOverViewClick(this.state.activeCardIndex + this.props.lastFlowCardIndex )}>p</a>
                    : ''}
		<div className="trail">
                    <ul>
                        {this.props.breadcrumbs && this.props.breadcrumbs.map(item => (
                            <li className={`breadcrumb ${item.card_type}`} />
                        ))}
                    </ul>
                </div>
                    <ul className="book cards" id="action_area">
                    <Fragment>
                    {renderCardItem}
                    </Fragment>
                </ul>
            </div>
        );
    }
};
export default flow_cards;