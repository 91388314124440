import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization'
import Home from './Home';
import Login from './Login';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import alasql from 'alasql';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Doc extends Component {
    constructor(props){
        super(props);
        this.state = {
          orgid:null,
          ObjFolder: [],
          ObjLinks:[],
          objfolderloading:false,
		  ObjCollection : [],
		  collection_template: {},
          openfolder:false,
          openfile:false,
		  customValue: '',
		  foldername: '',
		  ObjOrganization: ''

        }
        this.Gotoorg=this.Gotoorg.bind(this);
        this.GetFolderList=this.GetFolderList.bind(this);
        this.GotoHome=this.GotoHome.bind(this);
		this.GotoBack=this.GotoBack.bind(this);
        this.Gotoorg=this.Gotoorg.bind(this);
        this.GotoHelp=this.GotoHelp.bind(this);
		this.GotoReport=this.GotoReport.bind(this);
        this.GotoDoc=this.GotoDoc.bind(this);
        this.GotoProfile=this.GotoProfile.bind(this);
        this.GotoForm=this.GotoForm.bind(this);
     }

Gotoorg(){
  this.props.history.push('/Organization');
  }

  GotoBack()
  {
	  this.props.history.goBack();
  }
  GotoHome(){
    this.props.history.push('/Collection');
  }
GotoHelp(){
  this.props.history.push('/help');
}

GotoDoc(){
  this.props.history.push('/Doc');
}
GotoReport()
{
	this.props.history.push('/Report');
}
GotoForm()
	{
		this.props.history.push('/Form');
	}
GotoProfile(){
  this.props.history.push('/Profile');

}

  logout() {
    if (window.confirm("Are you sure you want to log out?")) {
  localStorage.clear();
  window.location.href = '/';
  }
}

 componentDidMount(){
 this.GetFolderList();
 this.getCollectionList();
 this.getOrganizationList();
 }
     getOrganizationList()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }
     GetFolderList(){
      let sessiondata= localStorage.getItem("safeguard");
      let sdata=JSON.parse(sessiondata);
            this.setState({
              ObjFolder: sdata.folders,
              orgisloading:false,
              openfolder:true
          })
    }
    Gotoopenfile(e){
      let safeguard= localStorage.getItem("safeguard");
      let safeguarddata=JSON.parse(safeguard);
      let filedata=safeguarddata.links;
	  let folderdata=safeguarddata.folders;
      let res= alasql('SELECT * FROM ? where file_folder_id = ?', [filedata,e]);
	  let res_folder = alasql('SELECT * FROM ? where id = ?', [folderdata,e]);
	  let folder_name = res_folder[0].name;
      this.setState({
        ObjLinks:res,
        openfolder:false,
        openfile:true,
		foldername:folder_name
      })
    }

    CloseFolder(){
      this.setState({
        openfolder:true,
        openfile:false
      })
    }

    logout() {
      localStorage.clear();
      window.location.href = '/';
  }
  toggle(id) {
    this.setState({
          customValue: id
      })
  }

  getCollectionList()
  {
	  let safeguard= localStorage.getItem("safeguard");
      let safeguard_array=JSON.parse(safeguard);
	  let collection_array = safeguard_array.collections;
	  if(collection_array!='')
	  {
	  let first_collection_id = collection_array[0].id;
	  this.setState({
          ObjCollection: safeguard_array.collections,
		  customValue: first_collection_id
      })
	  }
  }
  render() {
	let token = localStorage.getItem("session");
	let token_array=JSON.parse(token);
	let view_files = token_array.view_files;
	let user_level_2 = token_array.admin;
	let user_level_1 = token_array.organization_admin;
	let user_super_admin = token_array.super_admin;  
	let safeguard= localStorage.getItem("safeguard");
	let safeguard_array = JSON.parse(safeguard);
	let folders = safeguard_array.folders;
    if(this.state.orgisloading){
      return <p>Loading ...</p>;
    }
   let folder_file_block = this.state.ObjLinks.map((lin,index) => {
	const filename = lin.name;
	const filename_array = filename.split(".");
	const filename_array_count = filename_array.length;
	const file_extension = parseInt(filename_array_count) - 1;
	const extension = filename_array[file_extension];
   return <div key={index}><div className="file" key={lin.id}><a href={lin.url} data-type={extension} target="_blank">{lin.name}</a></div></div>;
	  });
	let supporting_material = this.state.ObjCollection.map((v, index) => {
	return (		
	<p key={index}>															 
<a data-collection-id={v.id} className={this.state.customValue==v.id? 'active accordion-head': 'accordion-head'} onClick={()=>this.toggle(v.id)}>{v.supporting_material_title}</a>
    <div data-collection-id={v.id} className="active">
	<div className={this.state.customValue==v.id? 'supporting_material_content': 'supporting_material_content hideobject'} id={v.id}>{ReactHtmlParser(v.supporting_material_content)}</div>
	
    </div>
	</p>
	)
	});
	
    return (
      <div id="ember361" className="ember-view ember-app bodyStyle">
      <div className="menu">
      <ul>
        <li onClick= {this.GotoHome} className="home"><a data-ember-action="388">h</a></li>
        <li className="back"><a data-ember-action="389">M</a></li>
        <li className="titlebar">{this.state.ObjOrganization}</li>
        <li className="logout" onClick= {this.logout}><a data-ember-action="391">o<span className="text">Logout</span></a></li>
        <li className="profile" onClick={this.GotoProfile}><a data-ember-action="392">u<span className="text">Profile</span></a></li>
        <li className="docs"><a data-ember-action="393">d<span className="text">Docs</span></a></li>
		<li className="docs" onClick={this.GotoForm}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/form.png" width="22px" height="20px" /> <span>Form</span></span></a></li>
		<li className="docs" onClick={this.GotoReport}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/report.png" width="22px" height="20px" /> <span>Report</span></span></a></li>
        <li className="help" onClick={this.GotoHelp}><a data-ember-action="394">?<span className="text">Help</span></a></li>
        {user_level_1==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
      </ul>
    </div>
    <div id="application">
      <div id="ember1291" className="ember-view document-view"><div id="ember1295" className="ember-view document-view">
      <div className="overlay-container">
        <a className="close" onClick= {this.GotoBack}>x</a>
        <div className="document_menu">
            {folders.length>0 && view_files==1 ?<a data-ember-action="1301" className="files_view">Files</a>:''}
            <a className="materials_view">Emergency<br></br>Phone Numbers</a>
        </div>
		
        <div className="support_materials">
		{folders.length>0 && view_files==1?
            <div className="files">
           {this.state.openfolder ?
           <div>
            { this.state.ObjFolder.map((fol) =>							
				  { return fol.type=='Folder' ? <div className="folder" key={fol.id} onClick={()=>this.Gotoopenfile(fol.id)}><a>{fol.name}</a></div> : <div className="file" key={fol.id}><a href={fol.filepath} data-type={fol.extension} target= "_blank">{fol.name}</a></div>}
            )
			}
             </div>
              :''}
			  
           {this.state.openfile ?
           <div>
		   <div className="back" onClick={()=>this.CloseFolder()}>
		   <a>Close {this.state.foldername}</a>
		   </div>
           {folder_file_block}
           </div>

          :''}
            </div>
			: ''}
            <div className="materials">
            <div id="accordion">
			{supporting_material}
			</div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    <div id="welcome">
      <div className="copyright">
        Copyright © 2019 P.E.R.P.<br></br><strong>Patent Pending</strong>
      </div>
    </div>
    
    </div>
    
    </div>
    );
  }
}

export default Doc;
