import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization'
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import PDFTron from './pdfTron';
import { Editor } from '@tinymce/tinymce-react';
import alaSQLSpace from 'alasql';
import decode from 'decode-html';
import Base64 from 'base-64'
import FlowChart from './flowchart';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import FlowCard from './flow_cards';
import { render } from 'react-dom';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
class ChartsAction extends Component {
	constructor(props) {
		super(props);
		this.displayData = [];
		this.displayBeginData = [];
		this.ObjDrill = [];
		this.itemRefs = {};
		this.pStyle = {
			background: '#af4600'
		};
		this.state = {
			chart_name: '',
			checklist_template : {},
			all_report_template : {},
			pdfNameTemplate : {},
			ObjCollection: [],
			ObjCharts: [],
			ObjDrillLog: [],
			showDocViewer: false,
			ObjChartsContent: [],
			ObjCardContents: [],
			ObjCards: [],
			ObjBegin: [],
			ObjActions: 0,
			checkbox_state : true,
			showdata: [],
			reopen_status : 'false',
			ObjOrganization: '',
			value: '',
			showbegindata: [],
			isLoggedIn: false,
			begin_chart: 0,
			clicked: false,
			render: false,
			showFlowChart: false,
			isPaneOpen: false,
			isPaneOpenLeft: false,
			isLogPaneOpen: false,
			isLogPaneOpenLeft: false,
			isClass: 'material',
			isClass2: 'log',
			chart_material_title: '',
			chart_material_content: '',
			breadcrumbs: [],
			action_template: {},
			activeBreadCrumb: [],
			lastFlowCardIndex: 0,
			checkListArray : [],
			checkListArrayCount : 0,
			stepCount : 0,
			message : '',
			result_template : {},
			reopen_template : {},
			action_template : {},
			blockCurrentStep : '',
			cardArray : ["1"],
			loader_class: '',
			signature_name : '',
			report_name : '',
			signature_date : '',
			filepath : '',
			filename : '',
			active_cardid: ''
		};
		this.Gotoorg = this.Gotoorg.bind(this);
		this.GotoHelp = this.GotoHelp.bind(this);
		this.GotoHome = this.GotoHome.bind(this);
		this.gotoBack = this.gotoBack.bind(this);
		this.GotoDoc = this.GotoDoc.bind(this);
		this.GotoReport = this.GotoReport.bind(this);
		this.GotoProfile = this.GotoProfile.bind(this);
		this.GotoSendMessage = this.GotoSendMessage.bind(this);
		this.GotoViewMessage = this.GotoViewMessage.bind(this);
		this.destinationAction = this.destinationAction.bind(this);
		this.handleOverViewClick = this.handleOverViewClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleOverViewClose = this.handleOverViewClose.bind(this);
		this.docViewer = this.docViewer.bind(this);
		this.docViewerSaved = this.docViewerSaved.bind(this);
		this.redirectWebViewer = this.redirectWebViewer.bind(this);
		this.GotoForm=this.GotoForm.bind(this);
		
	}
    redirectWebViewer(weburl,cardid) {
		let true_option = document.getElementById('true_option_'+cardid).checked;
	     let false_option = document.getElementById('false_option_'+cardid).checked;
		 if(true_option==false && false_option==false)
		 {
			 toast.error('choose any one option to proceed!', {
      position: toast.POSITION.TOP_RIGHT,
	  autoClose: 4000
    });
		 }else{
			 window.open(weburl, "_blank");
		 }
	}
	getChartList() {
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let organizationid = session_array.organization_id;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		/*const checklistid = query.get('checklistid');
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let multichecklist = safeguarddata.multichecklist;
		let collections=safeguarddata.collections;
		let res_collection = [];
	    res_collection = alaSQLSpace('SELECT * FROM ? where id = ?', [collections,collectionid]);
	    let checklist_active = res_collection[0].checklist_active;*/
		
		
		let all_report_template = this.state.all_report_template;
		all_report_template["action"] = "getMultipleChartChecklist";
		all_report_template["chartid"] = chartid;
		all_report_template["organizationid"] = organizationid;

		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.all_report_template)
			})
			.then((response) => response.json())
			.then((response) => {
					this.setState({
						ObjCharts: response
					})
			});
		
		/*let res;
		  if(checklist_active=='1')
		  {
		  res= alaSQLSpace('SELECT * FROM ? where collection = ? and (checklist = ? or checklist = ?) and id = ?', [charts,collectionid,'0','1',chartid]);
		  res = res[0].multichecklist_array;
		  }else{
		  res= alaSQLSpace('SELECT * FROM ? where collection = ? and checklist = ?', [charts,collectionid,'0']);	  
		  }
		this.setState({
			ObjCharts: res
		})*/
		$(".checklist-content").addClass("boxSlideCONT");
	}

	scrollTo(id) {
		this.itemRefs[id].scrollIntoView();
	}
	GotoReport()
	{
		this.props.history.push('/Report');
	}
	GotoForm()
	{
		this.props.history.push('/Form');
	}
	Gotoorg() {
		this.props.history.push('/Organization');
	}
	GotoHelp() {
		this.props.history.push('/help');
	}

	GotoHome() {
		this.props.history.push('/Collection');
	}
	gotoBack() {
		let collectionid = localStorage.getItem("collectionid");
		this.props.history.push('/Charts?collectionid=' + collectionid);
	}
	deleteNote = (index) => {
		let drill_local_storage = localStorage.getItem("drill_log");
		let drilllog = JSON.parse(drill_local_storage);
		if (index > -1) {
			drilllog.splice(index, 1);
		}
		localStorage.setItem('drill_log', JSON.stringify(drilllog));
		let drill_local_storage_new = localStorage.getItem("drill_log");
		let drilllog_new = JSON.parse(drill_local_storage_new);
		this.setState({ ObjDrillLog: drilllog_new })
	}
	destinationAction = (card_id, destination) => {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		let charts = safeguarddata.charts;
		let previous_cardid = 'ember' + card_id;
		document.getElementById("action_area").innerHTML = "";
		let res = alaSQLSpace('SELECT * FROM ? WHERE id = ?', [cards, destination]);
		let res_charts = alaSQLSpace('SELECT * FROM ? WHERE id = ?', [charts, chartid]);
		if (res.length == 1) {
			let res_action = alaSQLSpace('SELECT * FROM ? WHERE card_id = ?', [actions, res[0].id]);
			this.displayData = [];
			this.displayData.push(<li id={'ember' + res[0].id} className={'ember-view ' + res[0].id + ' ' + res[0].card_type + ' activecard'} data-type={res[0].card_type} data-card={res[0].id} data-chart-title={res_charts[0].title}>
				{ReactHtmlParser(Base64.decode(res[0].content))}
				<p className="card-content-actions"><button onClick={() => this.destinationAction(res[0].id, res_action[0].destination)}>{res_action[0].content != '' ? res_action[0].content : 'Next'}</button></p>
			</li>);
			this.setState({
				showdata: this.displayData,
				begin_chart: 1
			});
		}
	}
	redirectAction(chartid,multichecklistid) {
		localStorage.setItem('current_multichecklistid', '');
		localStorage.setItem('current_step', 1);
		$(".checklist-content").removeClass("boxSlideCONT");
		this.setState({
        cardArray : ["1"]
        })
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let organizationid = session_array.organization_id;
	    let safeguard = localStorage.getItem("safeguard");
		
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let charts = safeguarddata.charts;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		this.props.history.push('/ChartsAction?collectionid='+collectionid+'&chartid='+chartid+'&checklistid='+multichecklistid);
		let res = alaSQLSpace('SELECT checklist FROM ? where id = ?', [charts, chartid]);
		let checklist_status = res[0].checklist;
		if(checklist_status==1)
		{
			$('.checklist-content').show();
			$('.tab-content').hide();
			
this.setState({
checkListArray : [],
message: '',
loader_class: 'checklist_loader'
})

let checklist_template = this.state.checklist_template;
checklist_template["organizationid"]=organizationid;
checklist_template["chartid"]=chartid;
checklist_template["multichecklistid"]=multichecklistid;
checklist_template["userid"]=userid;
checklist_template["action"]="checklistMobileApi";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.checklist_template)
  })
   .then((response) => response.json())
   .then((response) => {

     const status=response.status;
	 const signature_name=response.signature_name;
	 const signature_date=response.signature_date;
	 const report_name=response.report_name;
	 const reopen_status=response.reopen_status;
	 this.setState({
		signature_name : signature_name,
		report_name : report_name,
		signature_date : signature_date,
		reopen_status : reopen_status
	 })
	 const task_list = response.task_list;
	 const step_count = response.step_count;
    if(status === "true")
    {
	        this.setState({
			checkListArray : task_list,
			
			stepCount : step_count,
			loader_class: ''
			})
	$(".checklist-content").addClass("boxSlideCONT");
    }       
});
		}else{
			$('.checklist-content').hide();
			$('.tab-content').show();
			this.getCardContent(chartid);
		}
            this.getChartContent(chartid);
	}
	
	/*redirectCurrentAction(chartid,multichecklistid,step) {
		$(".checklist-content").removeClass("boxSlideCONT");
		this.setState({
        cardArray : ["1","2"]
        })
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let organizationid = session_array.organization_id;
	    let safeguard = localStorage.getItem("safeguard");
		
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let charts = safeguarddata.charts;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		this.props.history.push('/ChartsAction?collectionid='+collectionid+'&chartid='+chartid+'&checklistid='+multichecklistid);
		let res = alaSQLSpace('SELECT checklist FROM ? where id = ?', [charts, chartid]);
		let checklist_status = res[0].checklist;
		if(checklist_status==1)
		{
			$('.checklist-content').show();
			$('.tab-content').hide();
			
this.setState({
checkListArray : [],
message: '',
loader_class: 'checklist_loader'
})

let checklist_template = this.state.checklist_template;
checklist_template["organizationid"]=organizationid;
checklist_template["chartid"]=chartid;
checklist_template["multichecklistid"]=multichecklistid;
checklist_template["userid"]=userid;
checklist_template["action"]="checklistMobileApi";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.checklist_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
	 const signature_name=response.signature_name;
	 const signature_date=response.signature_date;
	 const report_name=response.report_name;
	 const reopen_status=response.reopen_status;
	 this.setState({
		signature_name : signature_name,
		report_name : report_name,
		signature_date : signature_date,
		reopen_status : reopen_status
	 })
	 const task_list = response.task_list;
	 const step_count = response.step_count;
    if(status === "true")
    {
	        this.setState({
			checkListArray : task_list,
			
			stepCount : step_count,
			loader_class: ''
			})
	$(".checklist-content").addClass("boxSlideCONT");
    }       
});
		}else{
			$('.checklist-content').hide();
			$('.tab-content').show();
			this.getCardContent(chartid);
		}
            this.getChartContent(chartid);
	}*/
	
	GotoDoc() {
		this.props.history.push('/Doc');
	}

	GotoProfile() {
		this.props.history.push('/Profile');
	}

	GotoSendMessage() {
		this.props.history.push('compose');
	}

	GotoViewMessage() {
		this.props.history.push('messages');
	}
	logout() {
		if (window.confirm("Are you sure you want to log out?")) {
			localStorage.clear();
			window.location.href = '/';
		}
	}

	componentWillMount() {
		this.getCollectionList();
		this.getChartList();
		this.getCardList();
		this.getBeginArray();
		this.getOrganizationList();
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		const multichecklistid = query.get('checklistid');
		this.getChartContent(chartid);
	}
	handleNext(next_step,cardid,option_status,button_name,index)
	{
	let step_count = this.state.stepCount;
	let position = index + 1;
	var id = $('.checklist_content:visible').data('id');
	let report_name = document.getElementsByClassName('rename_report')[0].value;
	this.setState({
	blockCurrentStep: id
	});
	    let result_template = this.state.result_template;
	    const query = new URLSearchParams(this.props.location.search);
		const chartid = query.get('chartid');
		let multichecklistid = query.get('checklistid');
		if(multichecklistid!='' && multichecklistid!=null)
		{
			multichecklistid = multichecklistid;
		}else{
			multichecklistid = '';
		}
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let organizationid = session_array.organization_id;
		let current_multichecklistid = localStorage.getItem("current_multichecklistid");
    let comments = document.getElementById('comments_'+cardid).value;
	var card_array = this.state.cardArray;
    var nextId = next_step;
	if(option_status=='yes')
	{
	let true_option = document.getElementById('true_option_'+cardid).checked;
	let false_option = document.getElementById('false_option_'+cardid).checked;
	
	  if((true_option==false && false_option==false) || (report_name==''))
	  {
		  /*this.setState({
			message : <div data-alert="" className="alert alert-danger">requied field(s) missing !</div>
		});*/
		  toast.error('Choose any one option to proceed!', {
           position: toast.POSITION.TOP_RIGHT,
	       autoClose: 4000
            });
	  }else{
		  
		result_template["action"] = "saveChecklistResult";
		result_template["chartid"] = chartid;
		result_template["multichecklistid"] = multichecklistid;
		result_template["current_multichecklistid"] = current_multichecklistid;
		result_template["cardid"] = cardid;
		result_template["true_option"] = true_option;
		result_template["false_option"] = false_option;
		result_template["decision_button"] = '';
		result_template["comments"] = comments;
		result_template["userid"] = userid;
		result_template["report_name"] = report_name;
		result_template["organizationid"] = organizationid;
		
		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.result_template)
			})
			.then((response) => response.json())
			.then((response) => {
				   /*console.log(JSON.stringify(response));*/
				const status = response.status;
				const code = response.code;
				const description = response.description;
				const multiplechecklist_array = response.multiplechecklist_array;
				console.log('do not remove this');
				
				this.setState({
			    ObjCharts: multiplechecklist_array
		        })
				if (status === "true") {
		let cards = this.state.checkListArray;
		let res = alaSQLSpace('SELECT * FROM ? where current_step = ?', [cards, nextId]);
		
	if(res.length>0)
	{
	$('[data-id="'+id+'"]').hide();
    $('[data-id="'+nextId+'"]').show();
	this.setState({
			message : ''
		});
	/*console.log(card_array);*/
	card_array.push(nextId);
	this.setState({
	cardArray: card_array
	});
	}else{
		this.setState({
			message : <div data-alert="" className="alert alert-danger">Destination step is not exist under this checklist!</div>
		});
	}
    if(id >= 1 && res.length>0){
        $('.back_check').show();
    }
	localStorage.setItem("current_step",next_step);				
				}
			});
	  }	
	}else{
		result_template["action"] = "saveChecklistResult";
		result_template["chartid"] = chartid;
		result_template["multichecklistid"] = multichecklistid;
		result_template["current_multichecklistid"] = current_multichecklistid;
		result_template["cardid"] = cardid;
		result_template["true_option"] = '';
		result_template["false_option"] = '';
		result_template["decision_button"] = button_name;
		result_template["comments"] = comments;
		result_template["userid"] = userid;
        result_template["report_name"] = report_name;
		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.result_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				if (status === "true") {
					
		let cards = this.state.checkListArray;
		let res = alaSQLSpace('SELECT * FROM ? where current_step = ?', [cards, nextId]);
		
	if(res.length>0)
	{
	$('[data-id="'+id+'"]').hide();
    $('[data-id="'+nextId+'"]').show();
	this.setState({
			message : ''
		});
	card_array.push(nextId);

	this.setState({
	cardArray: card_array
	});
	}else{
		this.setState({
			message : <div className="alert alert-danger">Destination step is not exist under this checklist!</div>
		});
		$('.back_check').hide();
	}
    if(id >= 1 && res.length>0){
        $('.back_check').show();
    }
				}
			});
	}
	}
	componentDidMount() {
		/*document.getElementsByClassName("checklist_step").disabled = true;*/
		let current_step = localStorage.getItem("current_step");
		Modal.setAppElement(this.el);
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let organizationid = session_array.organization_id;
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let card_array = this.state.cardArray;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		let multichecklistid = query.get('checklistid');
		if(multichecklistid!='' && multichecklistid!=null)
		{
			multichecklistid = multichecklistid;
		}else{
			multichecklistid = 0;
		}
		
		let res = alaSQLSpace('SELECT checklist FROM ? where id = ?', [charts, chartid]);
		let checklist_status = res[0].checklist;
		if(checklist_status==1)
		{
$('.checklist-content').show();
$('.tab-content').hide();
let checklist_template = this.state.checklist_template;
checklist_template["organizationid"]=organizationid;
checklist_template["chartid"]=chartid;
checklist_template["multichecklistid"]=multichecklistid;
checklist_template["userid"]=userid;
checklist_template["action"]="checklistMobileApi";
this.setState({
loader_class: 'checklist_loader'
})

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.checklist_template)
  })
   .then((response) => response.json())
   .then((response) => {
     const status=response.status;
	 const signature_name=response.signature_name;
	 const report_name=response.report_name;
	 const signature_date=response.signature_date;
	 const reopen_status=response.reopen_status;
	 const multiplechecklist_array = response.multiple_checklist_array;
	 this.setState({
		signature_name : signature_name,
		report_name : report_name,
		signature_date : signature_date,
		reopen_status : reopen_status
	 })
	 const task_list = response.task_list;
	 const step_count = response.step_count;
    if(status === "true")
    {
		/*$('[data-id="1"]').hide();
		$('[data-id="2"]').hide();
		$('[data-id="4"]').hide();*/
        /*console.log(this.state.ObjCharts);*/
		
	   this.setState({
			checkListArray: task_list,
			stepCount : step_count,
			loader_class: ''
			})
	   /* $('[data-id="1"]').hide();
		$('[data-id="2"]').hide();
		$('[data-id="4"]').hide();*/
		/*this.getChartContent(chartid);*/
		let cards = this.state.checkListArray;
		let res = alaSQLSpace('SELECT * FROM ? where current_step = ?', [cards, current_step]);
		
		if(res.length>0)
			{
			$(".checklist_content").hide();
	        $('[data-id="'+current_step+'"]').show(); 
	        $(".checklist-content").addClass("boxSlideCONT");
			for (let i = 2; i <= current_step; i++) {
			card_array.push(i);
            }
			/*console.log(card_array);*/
			/*card_array.push(current_step);
			this.setState({
			cardArray: card_array
			});*/
			 if(current_step > 1 && res.length>0){
				 
             $('.back_check').show();
             }
			this.setState({
					message : ''
				});
			
			}else{
				this.setState({
					message : <div className="alert alert-danger">Destination step is not exist under this checklist!</div>
				});
			}
	   
    }       
});
		}else{
			$('.checklist-content').hide();
			$('.tab-content').show();
			 this.getCardContent(chartid);
		}
		this.getChartContent(chartid);
	}
	
	getOrganizationList() {
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		this.setState({
			ObjOrganization: session_array.organization
		})
	}
	getChartContent(chartid) {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let res = alaSQLSpace('SELECT * FROM ? where collection = ? and id = ?', [charts, collectionid, chartid]);
		this.setState({
			ObjChartsContent: res
		})
	}
    getCardContent(chartid) {
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let res = alaSQLSpace('SELECT * FROM ? where chart_id = ?', [cards, chartid]);
		this.setState({
			ObjCardContents: res
		})
	}
	getCollectionList() {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let collections = safeguarddata.collections;
		let res = alaSQLSpace('SELECT * FROM ? where id = ?', [collections, collectionid]);
		this.setState({
			ObjCollection: res
		})
	}
	getCardList() {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		let res = alaSQLSpace('SELECT cards FROM ? where id = ?', [charts, chartid]);

		let action_template = this.state.action_template;
		let ObjActions = this.state.ObjActions;
		action_template["action"] = "getActionsExist";
		action_template["chartid"] = chartid;

		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.action_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const count = response.count;
				if (status === "true") {
					this.setState({
						ObjActions: count
					})
				}
			});

		this.setState({
			ObjCards: res[0].cards
		})
	}
	getBeginArray(chartid) {
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		this.state.ObjCards.map((item, i) => {
			let res = alaSQLSpace('SELECT * FROM ? WHERE id = ? AND chart_id = ? AND card_type = ?', [cards, item, chartid, 'begin']);
			if (res.length == 1) {
				let res_action = alaSQLSpace('SELECT * FROM ? WHERE card_id = ?', [actions, res[0].id]);
				this.displayBeginData = [];
				this.displayBeginData.push(<li id={'ember' + res[0].id} className={'ember-view ' + res[0].id + ' ' + res[0].card_type + ' activecard'} data-type={res[0].card_type} data-card={res[0].id} data-chart-title="Incident Management Systemsss">
					{ReactHtmlParser(Base64.decode(res[0].content))}
					<p className="card-content-actions"><button onClick={() => this.destinationAction(res[0].id, res_action[0].destination)}>{res_action[0].content != '' ? res_action[0].content : 'Next'}</button></p>
				</li>);
			}
		})

		this.setState({
			showbegindata: this.displayBeginData
		});
	}
	handleOverViewClick(lastFlowCardIndex) {
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let organizationid = session_array.organization_id;
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let res = alaSQLSpace('SELECT * FROM ? where id = ?', [charts, chartid]);
		let chart_name = res[0].default_title;
		chart_name = chart_name.toLowerCase();
		chart_name = chart_name.replace(/:\s*/g, " ");
		chart_name = chart_name.replace(/\//g, "");
		chart_name = chart_name.replace(/\s+/g, "_");
		this.setState({
			chart_name: chart_name,
			showFlowChart: true,
			lastFlowCardIndex: lastFlowCardIndex,
		});
	}
	handleOverViewClose() {
		this.setState({ showFlowChart: false });
	}
	hanldeNote(e) {
		e.preventDefault();
		var list = document.getElementById("scroll-div");
		//alert(list.scrollHeight);
		let note = document.getElementById('drill_note').value;
		let date = new Date().getDate(); //Current Date
		let year = new Date().getFullYear(); //Current Year
		let hours = new Date().getHours();
		hours = hours % 12;
		hours = hours ? hours : 12;
		let minute = new Date().getMinutes();
		minute = minute < 10 ? '0' + minute : minute;
		let second = new Date().getSeconds();
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		let now = new Date();
		let thisMonth = months[now.getMonth()];
		let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
		let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
		let current_datetime = dayName + '. ' + thisMonth + ' ' + date + ', ' + year + ' ' + hours + ':' + minute + ':' + second + ' ' + getCurrentAmPm;
		let drill_local_storage = localStorage.getItem("drill_log");
		if (drill_local_storage != '') {
			let drilllog = JSON.parse(drill_local_storage);
			drilllog.push({
				datetime: current_datetime,
				content: note,
				type: 'note',
				source: '0',
				destination: '0'
			});
			localStorage.setItem('drill_log', JSON.stringify(drilllog));
		} else {
			let drill_arr = [];
			drill_arr.push({
				datetime: current_datetime,
				content: note,
				type: 'note',
				source: '0',
				destination: '0'
			});
			localStorage.setItem('drill_log', JSON.stringify(drill_arr));
		}
		let drill_local_storage_new = localStorage.getItem("drill_log");
		let drilllog_new = JSON.parse(drill_local_storage_new);
		this.setState({ ObjDrillLog: drilllog_new })
		document.getElementById('drill_note').value = '';

	}
	addBreadcrumb = addNew => this.setState({ breadcrumbs: [...this.state.breadcrumbs, addNew] });
	resetBreadcrumb = addNew => this.setState({ breadcrumbs: [], activeBreadCrumb: [] });
	resetActiveBreadCrumb = () => this.setState({ activeBreadCrumb: [] });
	hanldeBreadCrumb = item => {
		this.setState({ breadcrumbs: [...this.state.breadcrumbs, item], activeBreadCrumb: [item] }, () => { console.log('---', this.state.activeBreadCrumb) });
	};
	modalOnClickClose = () => {
		var boxWidth = $(".slide-pane__overlay").width();
		boxWidth = boxWidth + "px";

		$(".slide-pane__overlay").animate({ left: boxWidth });
		this.setState({ isLogPaneOpen: false, isClass2: 'log', isClass: 'material' });
	}
	modalOnClickClose1 = () => {
		var boxWidth = $(".slide-pane__overlay").width();
		boxWidth = boxWidth + "px";
		$(".slide-pane__overlay").animate({ left: boxWidth });
		this.setState({ isPaneOpen: false, isClass: 'material', isClass2: 'log' });
	}
	customLogClick = () => {
		let drilllog;
		let drill_local_storage = localStorage.getItem("drill_log");
		if (drill_local_storage != '') {
			drilllog = JSON.parse(drill_local_storage);
		} else {
			drilllog = '';
		}
		this.setState({
			isLogPaneOpen: true,
			isClass2: '',
			isClass: '',
			ObjDrillLog: drilllog
		})
		setInterval(function () {
			$('#scroll-div li').last().addClass('active-li').focus();
		}, 300);
	}
	addComments(cardid)
	{
		$('#comments_'+cardid).toggle();
	}
	handleLast(next_step,cardid)
	{
	let step_count = this.state.stepCount;
	var id = $('.checklist_content:visible').data('id');
    var nextId = next_step;
	let comments = document.getElementById('comments_'+cardid).value;
	let true_option = document.getElementById('true_option_'+cardid).checked;
	let false_option = document.getElementById('false_option_'+cardid).checked;
	let signature_name = document.getElementById('signature_name_'+cardid).value;
	let signature_date = document.getElementById('signature_date_'+cardid).value;
	//let report_name = document.getElementById('report_name_'+cardid).value;
	let report_name = document.getElementsByClassName('rename_report')[0].value;
	
	  if(true_option==false && false_option==false)
	  {
		  /*this.setState({
			message : <div data-alert="" className="alert alert-danger">You have to choose one option!</div>
		});*/
		  toast.error('Choose any one option to proceed!', {
           position: toast.POSITION.TOP_RIGHT,
	       autoClose: 4000
            });
	  }else{
		  if(signature_name=='' || signature_date=='' || report_name=='')
		  {
		   /*this.setState({
			message : <div data-alert="" className="alert alert-danger">Required fields can not be blank!</div>
		});	*/ 
		   toast.error('Required fields can not be blank!', {
           position: toast.POSITION.TOP_RIGHT,
	       autoClose: 4000
            });
		  }else{
		let result_template = this.state.result_template;
	    const query = new URLSearchParams(this.props.location.search);
		const chartid = query.get('chartid');
		let multichecklistid = query.get('checklistid');
		if(multichecklistid!='' && multichecklistid!=null)
		{
			multichecklistid = multichecklistid;
		}else{
			multichecklistid = '';
		}
		
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let current_multichecklistid = localStorage.getItem("current_multichecklistid"); 
		result_template["action"] = "saveChecklistResultLast";
		result_template["chartid"] = chartid;
		result_template["multichecklistid"] = multichecklistid;
		result_template["current_multichecklistid"] = current_multichecklistid;
		result_template["cardid"] = cardid;
		result_template["true_option"] = true_option;
		result_template["false_option"] = false_option;
		result_template["userid"] = userid;
		result_template["comments"] = comments;
		result_template["signature_name"] = signature_name;
		result_template["signature_date"] = signature_date;
		result_template["report_name"] = report_name;
		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.result_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				if(status === "true") 
				{
			this.setState({
			message : <div data-alert="" className="alert alert-success">Digital signature saved successfully!</div>
		});
				}
			});
		  }
		
	  }
	}
	handleChecklistReopen(cardid)
	{
		if($('#reopen_'+cardid).prop('checked') == true){
		const query = new URLSearchParams(this.props.location.search);
		const chartid = query.get('chartid');
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		
		let reopen_template = this.state.reopen_template;
		reopen_template["action"] = "reopenChecklist";
		reopen_template["chartid"] = chartid;
		reopen_template["cardid"] = cardid;
		reopen_template["userid"] = userid;
		
		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.reopen_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				if(status=='true')
				{
					alert('Your checklist has been reopened');
					$('#signature_name_'+cardid).val('');
					$('#signature_date_'+cardid).val('');
					$('#report_name_'+cardid).val('');
					
				}
			});
		}
	}
	handleCheckboxChangeTrue(cardid,option_value)
	{
		let report_name = document.getElementsByClassName('rename_report')[0].value;
		if(report_name!='' && report_name!=null)
		{
		if($('#true_option_'+cardid).prop('checked') == true){
         $('#false_option_'+cardid).prop('checked', false);
		 let comments = document.getElementById('comments_'+cardid).value;
		let result_template = this.state.result_template;
	    const query = new URLSearchParams(this.props.location.search);
		const chartid = query.get('chartid');
		let multichecklistid = query.get('checklistid');
		if(multichecklistid!='' && multichecklistid!=null)
		{
			multichecklistid = multichecklistid;
		}else{
			multichecklistid = '';
		}
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let current_multichecklistid = localStorage.getItem("current_multichecklistid");  
		result_template["action"] = "saveChecklistResult";
		result_template["chartid"] = chartid;
		result_template["multichecklistid"] = multichecklistid;
		result_template["current_multichecklistid"] = current_multichecklistid;
		result_template["cardid"] = cardid;
		result_template["true_option"] = 'true';
		result_template["false_option"] = 'false';
		result_template["decision_button"] = '';
		result_template["comments"] = comments;
		result_template["userid"] = userid;
        /*result_template["report_name"] = report_name;*/
		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.result_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				const description = response.description;
				localStorage.setItem('current_multichecklistid', description);
			});
	
		 
       }else{
		   $('#false_option_'+cardid).prop('checked', true);
	   }
	   }else{
           $('#true_option_'+cardid).prop( 'checked', false );
		   toast.error('report name is blank!', {
           position: toast.POSITION.TOP_RIGHT,
	       autoClose: 4000
            });
		}
	}
	handleCheckboxChangeFalse(cardid,option_value)
	{
		let report_name = document.getElementsByClassName('rename_report')[0].value;
		if(report_name!='' && report_name!=null)
		{
		if($('#false_option_'+cardid).prop('checked') == true){
         $('#true_option_'+cardid).prop('checked', false);
		 let result_template = this.state.result_template;
		 let comments = document.getElementById('comments_'+cardid).value;
	    const query = new URLSearchParams(this.props.location.search);
		const chartid = query.get('chartid');
		let multichecklistid = query.get('checklistid');
		if(multichecklistid!='' && multichecklistid!=null)
		{
			multichecklistid = multichecklistid;
		}else{
			multichecklistid = '';
		}
		
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let current_multichecklistid = localStorage.getItem("current_multichecklistid"); 
		result_template["action"] = "saveChecklistResult";
		result_template["chartid"] = chartid;
		result_template["multichecklistid"] = multichecklistid;
		result_template["current_multichecklistid"] = current_multichecklistid;
		result_template["cardid"] = cardid;
		result_template["true_option"] = 'false';
		result_template["false_option"] = 'true';
		result_template["decision_button"] = '';
		result_template["comments"] = comments;
		result_template["userid"] = userid;
        /*result_template["report_name"] = report_name;*/
		fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.result_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				const description = response.description;
				localStorage.setItem('current_multichecklistid', description);
			});
	
		 
		 
       }else{
		   $('#true_option_'+cardid).prop('checked', true);
	   }
	    }else{
			$('#false_option_'+cardid).prop( 'checked', false );
			toast.error('report name is blank!', {
      position: toast.POSITION.TOP_RIGHT,
	  autoClose: 4000
    });
		}
	}
    
	handleCalender(cardid)
	{
		let signature_date = document.getElementById('signature_date_'+cardid).value;
		let pieces = signature_date.split("-");
		let year = pieces[0];
		let month = pieces[1];
		let day = pieces[2];
		let format_date = month + '-' + day + '-' + year;
		this.setState({
			signature_date : format_date
		});
	}
	handleBack()
	{
	this.setState({
	message : ''
	});
	var id = $('.checklist_content:visible').data('id');
	 let savedArray = this.state.cardArray;
	 var prevId = savedArray[savedArray.length-2];
	 
	 $('[data-id="'+id+'"]').hide();
     $('[data-id="'+prevId+'"]').show();
	 localStorage.setItem('current_step', prevId);
	 if(prevId == 1){
	 $('.back_check').hide();
	 }
var newArr = savedArray.slice(0, -1);
	this.setState({
	cardArray : newArr
});
	/*console.log(this.state.cardArray);*/
	}
	handleEditPrevious()
	{
	$('.end').hide();
    $('.cards-holder').show();
	}
	 handleKeyPress(e) {
		const target = e.target;
		$('#scroll-div li').last().attr('tabindex', '');
	}
	 docViewer = (filename,filepath,cardid) => {
		 localStorage.setItem('pdf_name','');
		 localStorage.setItem('pdfid','');
		 let true_option = document.getElementById('true_option_'+cardid).checked;
	     let false_option = document.getElementById('false_option_'+cardid).checked;
		 let report_name = document.getElementsByClassName('rename_report')[0].value;
		 localStorage.setItem('report_name',report_name);
		 if(true_option==false && false_option==false)
		 {
			 toast.error('choose any one option to proceed!', {
      position: toast.POSITION.TOP_RIGHT,
	  autoClose: 4000
    });
		 }else{
			 filepath = Base64.encode(filepath);
		     this.setState({
			 showDocViewer: true,
			 filepath : filepath,
			 filename : filename,
			 active_cardid: cardid,
			 report_name: report_name
		});
		 }
		
	}
	 docViewerSaved = (filename,filepath,cardid,pdfid) => {
		
		 localStorage.setItem('pdfid', pdfid);
		 const query = new URLSearchParams(this.props.location.search);
		 const multichecklistid = query.get('checklistid');
		 const chartid = query.get('chartid');
		 let session = localStorage.getItem("session");
		 let session_array = JSON.parse(session);
		 let userid = session_array.id;
		 let report_name = document.getElementsByClassName('rename_report')[0].value;
		 localStorage.setItem('report_name',report_name);
		 
		 let pdfNameTemplate = this.state.pdfNameTemplate;
		 pdfNameTemplate["action"] = "getPDFFileName";
		 pdfNameTemplate["chartid"] = chartid;
		 pdfNameTemplate["cardid"] = cardid;
		 pdfNameTemplate["userid"] = userid;
		 pdfNameTemplate["pdfid"] = pdfid;
		 fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.pdfNameTemplate)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				const pdf_name = response.name;
				localStorage.setItem('pdf_name', pdf_name);
				if(status=='true')
				{
					 let true_option = document.getElementById('true_option_'+cardid).checked;
					 let false_option = document.getElementById('false_option_'+cardid).checked;
					 if(true_option==false && false_option==false)
					 {
						 toast.error('choose any one option to proceed!', {
				  position: toast.POSITION.TOP_RIGHT,
				  autoClose: 4000
				});
					 }else{
						 filepath = Base64.encode(filepath);
						 this.setState({
						 showDocViewer: true,
						 filepath : filepath,
						 filename : filename,
						 active_cardid: cardid
					});
					 }
		 
				}else{
					alert('something went wrong,please try again!');
				}
			});
	}
	 handleClose = (checklistid) =>{
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		const chartType = query.get('chartType');
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
		let organizationid = session_array.organization_id;
		if(chartType!='' && chartType!=null)
		{
			/*console.log(this.state.cardArray);*/
			window.location.replace('/ChartsAction?collectionid='+collectionid+'&chartid='+chartid+'&checklistid='+checklistid);
		}else{
			window.location.replace('/ChartsAction?collectionid='+collectionid+'&chartid='+chartid+'&checklistid='+checklistid);
		/*this.setState({ showDocViewer: false, filename: '', filepath: '' });
		$(".checklist-content").addClass("boxSlideCONT");
		let current_step = localStorage.getItem('current_step');
		let savedArray = this.state.cardArray;
		
		this.setState({
		cardArray : savedArray
	    });
		var divsToHide = document.getElementsByClassName("checklist_content");
		for(var i = 0; i < divsToHide.length; i++){
			divsToHide[i].style.display = "none";
		}
		document.getElementById( 'content-'+current_step ).style.display = 'block';
		$('.back_check').show();*/
		}
		
	}
	render() {
		let checkbox_state = this.state.checkbox_state;
		let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let organizationid = session_array.organization_id;
		let token = localStorage.getItem("session");
		let token_array = JSON.parse(token);
		let user_level_2 = token_array.admin;
		let user_level_1 = token_array.organization_admin;
		let user_super_admin = token_array.super_admin;
		let isClassName = this.state.isClass;
		let isClassName2 = this.state.isClass2;
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let cards = safeguarddata.cards;
		let actions = safeguarddata.actions;
		let charts = safeguarddata.charts;
		const query = new URLSearchParams(this.props.location.search);
		const collectionid = query.get('collectionid');
		const chartid = query.get('chartid');
		const checklistid = query.get('checklistid');
	    let current_step = localStorage.getItem("current_step");
		const chartType = query.get('chartType');
		let chart_class = localStorage.getItem("chart_class");
		let report_name = localStorage.getItem("report_name");
		let className = this.state.clicked ? 'ember-view ember-app ember-application' : 'ember-view ember-app ember-application chartview';
		let date = new Date().getDate();
		let year = new Date().getFullYear();
		let hours = new Date().getHours();
		hours = hours % 12;
		hours = hours ? hours : 12;
		let minute = new Date().getMinutes();
		minute = minute < 10 ? '0' + minute : minute;
		let second = new Date().getSeconds();
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		let now = new Date();
		let thisMonth = months[now.getMonth()];
		let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
		let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
		let current_datetime = dayName + '. ' + thisMonth + ' ' + date + ', ' + year + ' ' + hours + ':' + minute + ':' + second + ' ' + getCurrentAmPm;
		let drilllog;
		let pdf_checklistid;
		if(checklistid!='')
		{
			pdf_checklistid = checklistid;
		}else{
			pdf_checklistid = localStorage.getItem("current_multichecklistid");
		}
		if (this.state.showDocViewer) {
			  return (
					  <PDFTron
					  filepath={this.state.filepath}
					  filename={this.state.filename}
					  closeFileView={this.handleClose}
					  chartid={chartid}
					  checklistid={pdf_checklistid}
					  organizationid={organizationid}
					  cardid={this.state.active_cardid}
					  userid={token_array.id}
					  report_name={report_name}
					  />
					  );
		  }

		let drill_local_storage = localStorage.getItem("drill_log");
		let drill_log;
		if (drill_local_storage != '') {
			drilllog = JSON.parse(drill_local_storage);
		} else {
			drilllog = '';
		}

		let drill_log_content = '';
		let drill = this.state.ObjDrillLog;
		let drill_count = drill.length;
		if (drill_count > 0) {
			const rowLen = this.state.ObjDrillLog.length;
			const last_row_element = parseInt(rowLen) - 1;
			drill_log_content = this.state.ObjDrillLog.map((v, index) => (
				<li key={index} id={index} tabIndex={index == last_row_element ? "1" : ""}>
					<div className="time">{v.datetime}</div>
					<div className="content">{v.type == 'note' ? <a className="delete" onClick={() => this.deleteNote(index)}>x</a> : ''} {v.content}</div>
				</li>
			));
		} else {
			drill_log_content = '';
		}
		let tab_title = this.state.ObjChartsContent[0].title;
		let checklist_block = this.state.checkListArray.map((v,index) => (
            <div key={index}>
			
			<a className="backbutton back_check" onClick={()=>this.handleBack()}></a>
			<input type="hidden" id={index + 1} name={index + 1} className={'checklist_div'+(index + 1)} value={index + 1} />
			<div className="checklist_content" id={"content-" + (index + 1)} data-id={index + 1} style={{display: index === 0 ? 'block' : 'none'}}>
			    <h2>{tab_title} - Step : {index + 1}</h2>
				{ReactHtmlParser(v.content)}
{v.checkbox_view_status=='yes'?<span>
<input type="checkbox"
className="checklist_step"
value={v.option1}
name={'true_option_'+v.id}
id={'true_option_'+v.id}
onChange={()=>this.handleCheckboxChangeTrue(v.id,v.option1)}
defaultChecked={v.option_result === v.option1}
/>
{v.option1}
</span>:''}
{v.checkbox_view_status=='yes'?<span><input type="checkbox" className="checklist_step" value={v.option2} name={'false_option_'+v.id} id={'false_option_'+v.id} onChange={()=>this.handleCheckboxChangeFalse(v.id,v.option2)} defaultChecked={v.option_result === v.option2} />{v.option2}</span>:''}
                <div className="checklist-file">
				{v.pdf_file_count>0?
				 <Popup trigger={<button className="btn btn-primary btn-sm">PDF Form</button>} position="left center">
					<div className="web-url">
				{v.checklist_total_file.map((value,index) => {
                  return (
                    <p>
		<img src="/assets/images/attachment.png" width="15px" height="15px"></img>&nbsp;<a onClick={()=>this.docViewer(value.filename,value.filepath,v.id)}>{value.filename}</a>
                    </p>
                  );
                })}
					</div>
				  </Popup>
				  :null}
				 
				 &nbsp;{v.weburl!=null && v.weburl!=''?<button className="btn btn-warning btn-sm" onClick={()=>this.redirectWebViewer(v.weburl,v.id)}>Web Link</button>:null}
				</div>
				
                <div className="commentBOX">
				<span>Add Comments</span>
				<span className="showComments" onClick={()=>this.addComments(v.id)}>+</span>
				<div>
			    <textarea className="textareaBOX" name={'comments_'+v.id} id={'comments_'+v.id} defaultValue ={v.comments} />	
				</div>
				</div>
				
				<div className="col-xs-12 align-center">
				{index===0?	
				    <div className="col-12 col-md-6">
                    <input className="form-control rename_report"
					id={'report_name_'+v.id} 
					name={'report_name_'+v.id}
					placeholder='Report Name'
					type="text"
					defaultValue ={this.state.report_name}
					/>
					</div>
					: null}
					</div>
					
				<div className="cardButton">
		        {v.decision_count==0 && v.report_status=='No'?<button type="button" className="next" onClick={()=>this.handleNext(v.next_step,v.id,v.checkbox_view_status,'Next',index)}>Next</button>:v.decision_steps.map((value,index) => {
              return (
			  <button type="button" id={'decision_'+v.id} className="next" onClick={()=>this.handleNext(value.destination,v.id,v.checkbox_view_status,value.content,index)}>{value.content}</button>
              ); 
            })}
				{checklistid!=''?
		        <div className="checklist-file">
				<ul>
				{v.checklist_file.map((value,index) => {
				   if(value.saved==='yes')					   
                  return (
                    <li key={index}>
			<img src="/assets/images/attachment.png" width="18px" height="18px"></img>&nbsp;<a onClick={()=>this.docViewerSaved(value.filename,value.filepath,v.id,value.id)} className="saved_pdf">{value.rename}</a>
                    </li>
                  );
				
                })}
				</ul>
				</div>
				: null}
		{v.report_status=='Yes'?
		<div className="row final-step">
		<div className="col-xs-6">
		<input className="form-control"
        id={'signature_name_'+v.id} 
        name={'signature_name_'+v.id}
		placeholder='Type name...'
		defaultValue ={this.state.signature_name}
        type="text"
        />
		</div>
		<div className="col-xs-6">
		<input className="form-control"
        id={'signature_date_'+v.id} 
        name={'signature_date_'+v.id} 
        type="date"
		defaultValue ={this.state.signature_date}
        />
		</div>
		
		<div className="col-xs-12 text-center">
		<button type="button" className="next" onClick={()=>this.handleLast(v.next_step,v.id)}>Save Signed Report</button>
		</div>
		</div>
		
		:''}
			</div>
			</div>
			</div>
    ));
		let chart_material = this.state.ObjChartsContent.map(v => (
			<h2 key={v.id}>{v.supporting_materials_title}</h2>
		));
		let chart_title = this.state.ObjChartsContent.map(v => (
			<h2 key={v.id}></h2>
		));

		let chart_material_content = this.state.ObjChartsContent.map(v => (
			ReactHtmlParser(v.supporting_materials_content)
		));

		let tab_card_content = this.state.ObjCardContents.map(v => (
			ReactHtmlParser(v.content)
		));

		let res_charts = alaSQLSpace('SELECT * FROM ? WHERE id = ?', [charts, chartid]);

		let begin_block = this.state.ObjCards.map((item, i) => {
			let res = alaSQLSpace('SELECT * FROM ? WHERE id = ? AND chart_id = ? AND card_type = ?', [cards, item, chartid, 'begin']);
			if (res.length == 1) {
				let res_action = alaSQLSpace('SELECT * FROM ? WHERE card_id = ?', [actions, res[0].id]);

				if (res_action.length > 0) {
					return <li id={'ember' + res[0].id} className={'ember-view ' + res[0].id + ' ' + res[0].card_type + ' activecard'} data-type={res[0].card_type} data-card={res[0].id} data-chart-title={res_charts[0].title}>
						{ReactHtmlParser(Base64.decode(res[0].content))}
						<p className="card-content-actions"><button onClick={() => this.destinationAction(res[0].id, res_action[0].destination)}>{res_action[0].content != '' ? res_action[0].content : 'Next'}</button></p>
					</li>
				} else {
				}

			}
		})

		let chartList = this.state.ObjCharts.map((v, index) => (
			<li key={index}>{v.id === checklistid ? <a style={{ 'backgroundColor': v.color_theme === 'yellow' ? '#f5c200' : v.color_theme === 'orange' ? '#db5700' : v.color_theme === 'maroon' ? '#730e12' : v.color_theme === 'olive_green' ? '#649409' : v.color_theme === 'dark_green' ? '#1d3c0e' : v.color_theme === 'royal_blue' ? '#0d2a7a' : '' }} id={v.id} className="ember-view active" onClick={() => this.redirectAction(v.chartid,v.id)}>{v.reportname}</a> : <a style={{ 'backgroundColor': v.color_theme === 'yellow' ? '#f5c200' : v.color_theme === 'orange' ? '#db5700' : v.color_theme === 'maroon' ? '#730e12' : v.color_theme === 'olive_green' ? '#649409' : v.color_theme === 'dark_green' ? '#1d3c0e' : v.color_theme === 'royal_blue' ? '#0d2a7a' : '' }} id={v.id} className="ember-view" onClick={() => this.redirectAction(v.chartid,v.id)}>{v.reportname}</a>}</li>

		));
		let supporting_material_title = this.state.ObjCollection.map(v => (
			<h2>{v.supporting_material_title}</h2>
		));
		let supporting_material_content = this.state.ObjCollection.map(v => (
			ReactHtmlParser(v.supporting_material_content)
		));
		if (localStorage.getItem('session')) {
				return (
					<div className={className}>
					<ToastContainer closeButton={false} /> 
						<div className="menu">
							<ul>
								<li className="homeicon"><a onClick={this.GotoHome}>h</a></li>
								<li className="back"><a onClick={this.gotoBack}>M</a></li>
								<li className="titlebar">{this.state.ObjOrganization}</li>
								<li className="logout"><a onClick={this.logout}>o<span className="text">Logout</span></a></li>
								<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>
								<li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
								<li className="docs" onClick={this.GotoForm}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/form.png" width="22px" height="20px" /> <span>Form</span></span></a></li>
								<li className="docs" onClick={this.GotoReport}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/report.png" width="22px" height="20px" /> <span>Report</span></span></a></li>
								<li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
								{user_level_1 == 1 || user_level_2 == 1 ? <li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li> : ''}
							</ul>
						</div>
<div id="application">
<ul className="book charts">
{chartList}
</ul>
<div className="chart">
<div className="book cards cards-holder checklist-content">
<div className={this.state.loader_class}></div>
{checklist_block}
</div>
{chartType==0?
<ul className="book cards tab-content" id="action_area">
<li className="end activecard" data-chart-title={tab_title}>{tab_card_content}</li>
</ul>
: null}
&nbsp;
{this.state.message}
</div>

						
								<div className={isClassName}>
									<div className="tab">
										<div ref={ref => this.el = ref}>
											<a onClick={() => this.setState({ isPaneOpen: true, isClass: '', isClass2: '' })}></a>
											<SlidingPane
												className='some-custom-class'
												overlayClassName='some-custom-overlay-class'
												isOpen={this.state.isPaneOpen}
												from='right'
												title={chart_material}
												onRequestClose={() => {
													this.setState({ isPaneOpen: false, isClass: 'material', isClass2: 'log' });
												}}>
												<div className="material-content-block">
													<div className="supporting_material_content">
														{chart_material_content}
													</div>
												</div>
												<div className="material_contentICON" onClick={this.modalOnClickClose1}></div>
											</SlidingPane>
										</div>
									</div>
								</div>
						</div>
					</div>
				);
			
		} else {
			window.location.href = '/';
		}
	}
}
export default ChartsAction;