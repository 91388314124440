import React, { Component } from 'react';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import alasql from 'alasql';
import TinyMCE from 'react-tinymce';
import { Editor } from '@tinymce/tinymce-react';
import decode from 'decode-html';
import Base64 from 'base-64';
import $ from 'jquery';
import Dropzone from 'react-dropzone';
import axios from 'axios';
class CreateSteps extends Component {
  constructor(props){
    super(props);
    this.state = {
	  selectedFiles: [],
	  multiUploaderSetArray:[],
	  rows: [],	
	  current_step: '',
	  next_step: '',
	  create_report: '',
      organization_template: {},
      viewtask_template: {},
	  fields: {},
	  collection_template: {},
	  card_template: {},
	  card_order_template: {},
      ObjOrganizations: '',
      ObjViewTasks: [],
	  ObjCharts: [],
	  ObjCollection : [],
	  ObjCard : [],
	  ObjCardOrder : '',
	  ObjDestinationCard : [],
	  content: '',
	  chart_template: {},
	  textareaValue : '',
	  card_order_template: {},
	  create_report_status: 'No'
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.redirectEventAids=this.redirectEventAids.bind(this);
	this.redirectPlan=this.redirectPlan.bind(this);
	this.handleEditorChange = this.handleEditorChange.bind(this);
	this.editProfile=this.editProfile.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
	this.onDrop = this.onDrop.bind(this);
    }
	
  handleChange = idx => e => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value
    };
	
	let step_value = $("#"+idx).val();
	let value_type =  $.isNumeric(step_value);
	if(value_type==true)
	{
	let card_order_template = this.state.card_order_template;
    let cardOrder = this.state.cardOrder;
    card_order_template["action"]="cardOrderAvailability";
	const query = new URLSearchParams(this.props.location.search);
	card_order_template["organizationid"] = query.get('organization');
	card_order_template["chartid"] = query.get('chartid');
	card_order_template["step_order"] = step_value;
	
	fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.card_order_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const description=response.description;
	  $("#step_desc"+idx).text(description);      
   });
	}
	
	
    this.setState({
      rows
    });
  };
  handleChangeDestination = idx => e => {
    const { name, destination, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
	  [name]: '',	
      [destination]: value
    };
    this.setState({
      rows
    });
  };
  handleAddRow = (e) => {
	e.preventDefault();
	$('#step_order_row').hide();
	$('.option_row').show();
	this.setState({
		create_report_status: 'Yes'
	})
    const item = {
      name: "",
      destination: ""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };
  handleRemoveRow = () => {  
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };
  handleRemoveSpecificRow = (idx) => (e) => {
	e.preventDefault();  
    const rows = [...this.state.rows]
    rows.splice(idx, 1)
    this.setState({ rows })
	let rowCount = $('#tab_logic tr').length;
	if(rowCount==2)
	{
	$('#step_order_row').show();
	$('.option_row').hide();
	this.setState({
		create_report_status: 'No'
	})
	}
  }
  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  }

	  redirectOrganization(){
	  this.props.history.push('/Organization');
	  }
	  editProfile(user_id,organizationid)
      {
	 this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	  redirectDashboard(){
	  this.props.history.push('/Collection');
	  }
	  redirectPlan(e){
	  this.props.history.push('/collections/Collections?organization='+ e);
	  }
	  redirectViewTasks(organizationid,collectionid,chartid,moduleid){
	  this.props.history.push('/ViewTasks?chartid='+chartid+'&organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
	  }
	  redirectEventAids(organizationid,collectionid,moduleid) {
	  this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
	  }
  
  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }

componentDidMount() {
  this.GetOrganizationDetail();
  this.GetCollectionDetails();
  this.GetViewTaskList();
  //this.getCardDetails();
  this.getChartList();
  this.getStepOrder();
  $('.option_row').hide();
}

handleEditorChange(e) {
this.setState({
content: e.target.getContent()
})
}
handleChangeName(field, e){
let fields = this.state.fields;
fields[field] = e.target.value;        
this.setState({fields});
let step_value = e.target.value;
let value_type =  $.isNumeric(step_value);
	if(value_type==true)
	{
	let card_order_template = this.state.card_order_template;
    let cardOrder = this.state.cardOrder;
    card_order_template["action"]="cardOrderAvailability";
	const query = new URLSearchParams(this.props.location.search);
	card_order_template["organizationid"] = query.get('organization');
	card_order_template["chartid"] = query.get('chartid');
	card_order_template["step_order"] = step_value;
	
	fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.card_order_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const description=response.description;
	  $("#step_next_message").text(description);      
   });
	}
}
GotoReport(){
this.props.history.push('/Report');
}
handleChangeReport(field, e){
	if(e.target.value==1)
	{
	this.setState({
		create_report_status: 'Other'
	})	
	$('#step_order_row').hide();
	$('#tab_logic td').hide();
	}else{
	this.setState({
		create_report_status: 'Yes'
	})	
	$('#step_order_row').show();
	$('#tab_logic td').show();
	}
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }

getChartList(){
let chart_template = this.state.chart_template;
let ObjCharts = this.state.ObjCharts;
chart_template["action"]="detailChart";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
chart_template["organizationid"]=organizationid;
chart_template["collectionid"]=collectionid;
chart_template["chartid"]=chartid;

fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.chart_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details=[] ;
	  details.push(response.details);
	 if(status === "true")
	 {
	  this.setState({
		ObjCharts: details
	})
	 }       
});
}
getStepOrder(organization_id,chart_id)
{
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
let card_order_template = this.state.card_order_template;
let ObjCardOrder = this.state.ObjCardOrder;
card_order_template["action"]="getStepOrderCreate";
card_order_template["organizationid"]=organizationid;
card_order_template["chartid"]=chartid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.card_order_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const current_step=response.current_step;
	  //const next_step = response.next_step;
	  this.setState({
		ObjCardOrder: current_step
	})      
}); 
}
getCardDetails()
{
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');	
const card_id = query.get('card_id');
const chartid = query.get('chartid');
const moduleid = query.get('moduleid');
let card_template = this.state.card_template;
let ObjCard = this.state.ObjCard;
card_template["action"]="getCardDetails";
card_template["organizationid"]=organizationid;
card_template["collectionid"]=collectionid;
card_template["chartid"]=chartid;
card_template["moduleid"]=moduleid;
card_template["card_id"]=card_id;
console.log(JSON.stringify(this.state.card_template));
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.card_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	  const destination_details = response.destination_details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCard: details,
		ObjDestinationCard: destination_details
	})
	 }       
}); 

}
GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});       
}
GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }else{
	   }
 });
 }
handleValidation(){
let fields = this.state.fields;
let errors = {};
let formIsValid = true;
const query = new URLSearchParams(this.props.location.search);
const organization = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
const moduleid = query.get('moduleid');
const chart_content = this.state.content;
let session= localStorage.getItem("session");
let session_array=JSON.parse(session);
let userid = session_array.id;
let editor_content =  $('.tox-edit-area__iframe').contents().find('#tinymce').html();
if(editor_content=='<p><br data-mce-bogus="1"></p>')
{
editor_content = '';	
}else{
editor_content = editor_content;	
}
editor_content = editor_content.replace(/&quot;/g, '');
editor_content = editor_content.replace(/\\/g, '');
let next_steps;
let create_report = document.getElementById('create_report').value;
let create_report_status = this.state.create_report_status;
fields["action"]="createCardContent";
fields["organizationid"]=organization;
fields["collectionid"]=collectionid;
fields["chartid"]=chartid;
fields["userid"]=userid;
fields["content"]=editor_content;
let your_array = [];
if(create_report_status=='Yes')
{
next_steps = 0;
$('#tab_logic tbody tr').each(function(row, tr) {
    your_array.push({
      name: $(tr).find("input[name=name]").val(),
      destination: $(tr).find("input[name=destination]").val()
    });

  });	
}else if(create_report_status=='No'){
if(!fields["next_step"]){
formIsValid = false;
errors["next_step"] = "Next Step can not be empty";
}	
next_steps = document.getElementById('next_step').value;
}else{
next_steps = 0;
}
fields["inner_steps"] = JSON.stringify(your_array);
fields["next_step"] = next_steps;
	  if (this.refs.reportid) {
       fields["create_report"]=this.refs.reportid.value;
    }
this.setState({errors: errors});
return formIsValid;
}
multiUploaderSet = async (data) => {
   const res = await axios.post("https://expressapi.safeguardses.com/uploadChecklistFile", data, {
   })
   return res;
};

cardSubmit = async (e) => {
e.preventDefault();
if(this.handleValidation()){
let selectedFiles = this.state.selectedFiles;	

let multiUploaderSetList = [];
for (let i = 0; i < selectedFiles.length; i++)
	{
    let data = new FormData();
    data.append('checklist_file', selectedFiles[i])
    const uploadres = await this.multiUploaderSet(data);
	multiUploaderSetList.push(uploadres.data);
    }
	this.setState({
      multiUploaderSetArray: multiUploaderSetList
      })
	let weblink = document.getElementById("weblink").value;
	let fields = this.state.fields;
	fields["file_array"] = this.state.multiUploaderSetArray;
	fields["weburl"] = weblink;
console.log(JSON.stringify(this.state.fields));	
this.updateCardContentApi();
}else{
 alert("Mandatory Field Emplty!");
}
}

handleOnChange(event) {
    this.setState({
      textareaValue: event.target.value
    })
  }
handleEmailOpt(field, e){         
let fields = this.state.fields;
let isChecked = e.target.checked;
fields[field] = isChecked;     
this.setState({fields});
}  

updateCardContentApi()
{
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  const chartid = query.get('chartid');
	  const moduleid = query.get('moduleid');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
		   const code=response.code;
		   console.log(code);
           if(status === "true")
           {
			  this.props.history.push('/ViewTasks?organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid+'&chartid='+chartid);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
 
  GetViewTaskList(){
  let viewtask_template = this.state.viewtask_template;
  let ObjViewTasks = this.state.ObjViewTasks;
  viewtask_template["action"]="getViewTaskList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  const chartid = query.get('chartid');
  viewtask_template["organizationid"]=organizationid;
  viewtask_template["chartid"]=chartid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.viewtask_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const task_list = response.task_list;
         if(status === "true")
       {
        this.setState({
          ObjViewTasks: task_list
      })
       }else{
	   }
 });
}
formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
      render() {
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	  
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization'); 
	const collectionid = query.get('collectionid');
	const chartid = query.get('chartid');
	const moduleid = query.get('moduleid');
	const { selectedFiles} = this.state;
	const files_details = selectedFiles.map(file => (
      <li key={file.name}>
        {file.name} - {this.formatBytes(file.size)}
      </li>
    ));
	let title = this.state.ObjCharts.map(v => (
	  v.title
    ));
	
	let action_title = this.state.ObjCard.action_content;
	
	let content = Base64.encode(this.state.ObjCard.content);
	if (localStorage.getItem('session')) {
	
    return (
	<div className="bodyscroll">		
	<div className="navbar navbar-inverse navbar-static-top" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-left">
              <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			  <li className=""><a onClick={this.GotoReport}>Report</a></li>
              <li><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a rel="nofollow" title="Log Out" onClick= {this.logout}>Log Out</a></li>
              </ul>
            </div>
          </div>
        </div>
	
	<div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a onClick={this.redirectOrganization}>Admin</a> &#187; <a onClick={()=>this.redirectEventAids(organizationid,collectionid,moduleid)}>{this.state.ObjCollection.title}</a> &#187; <a onClick={()=>this.redirectViewTasks(organizationid,collectionid,chartid,moduleid)}>{title}</a>
          </div>
          </div>
          <div className="row">
          <div className="col-xs-12">
          <h1 style={{'paddingLeft' : '10px'}}>Steps</h1>
		<div className="container">
        <form
		acceptCharset="UTF-8"
		className="edit_card"
		id="edit_card"
		onSubmit= {this.cardSubmit.bind(this)}>
        <div className="row">
        <div className="col-md-6">
		<div className="form-group card_content_editor">
		<Editor
		
        init={{
			height: 400,
			menubar:true,
            statusbar: false,
        plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount contextmenu'
           ],
		  contextmenu: "link image inserttable | cell row column deletetable | undo redo | copy | paste | cut",
          toolbar:
             'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | code | help | image'
        }}
        onChange={this.handleEditorChange}
      />
		</div>
        <br/>
        </div>
        <div className="col-md-6">
        <label>Card preview</label>
        <div className="card-preview-container">
        <div className="title begin"></div>
        <div className="card-preview begin">
        {ReactHtmlParser(this.state.ObjCard.content_full)}
        </div>
<div>&nbsp;</div>		

        </div>
        </div>
        </div>
       
	   
	   <div className="row">
		<div className="col-md-6">
		<div className="form-group borderbox">
		<h2>Attachment</h2>
		<br/>
		<div className="form-group">
		<div className="file-container">
		<Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section className="fileDrop floatN">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length ? (
                    <div className="selected-file">
                      {selectedFiles.length > 3
                        ? `${selectedFiles.length} files`
                        : selectedFiles.map((file) => file.name).join(", ")}
                    </div>
                  ) : (
                    `Drag and drop files here, or click to select files`
                  )}
                </div>
                <aside className="selected-file-wrapper">
				 <h4>Files</h4>
				 <ul>{files_details}</ul>
                </aside>
              </section>
            )}
          </Dropzone>
		</div>
		</div>
		<div className="form-group">
		<label htmlFor="template_id">Web URL</label>
		<input
		   id="weblink" 
		   name="weblink" 
		   type="text"
		   placeholder="Web Link"
		    className="form-control"
		   />
		</div>
              </div>
            </div>
          </div>
		<div className="row">
		<div className="col-md-6">
		<div className="form-group borderbox">
		<h2>Steps Ordering</h2>
		<br/>
        <table width="100%" className="table table-striped" id="step_order_header">
		<tbody>
		<tr>
		<td><label>Current Step:</label></td>
		<td>
		 &nbsp;&nbsp;&nbsp;&nbsp;{this.state.ObjCardOrder}
		</td>
		</tr>
		<tr id="step_order_row">
		<td><label>Next Step :</label></td>
		<td>
		<input className="form-control"
        id="next_step" 
        name="next_step" 
        type="number" 
        onChange={this.handleChangeName.bind(this, "next_step")}
        value={this.state.fields["next_step"]}
        />
		<div id="step_next_message" className="order_alert"></div>
		</td>
		</tr>
		<tr>
		<td colSpan="2">
		<div className="inner-steps">
<table className="table table-bordered table-hover table-striped" id="tab_logic">
                <thead>
                  <tr>
                    <th className="text-center"> Button Name </th>
					<th className="text-center"> Destination Step </th>
					<th className="text-center"> Action </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>
                        <input
                          type="text"
                          name="name"
                          value={this.state.rows[idx].name}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
					  <td>
                        <input
                          type="number"
                          name="destination"
						  id={idx}
                          value={this.state.rows[idx].destination}
                          onChange={this.handleChange(idx)}
                          className="form-control step_destination"
                        />
						<div id={'step_desc' + idx} className="order_alert"></div>
                      </td>
                      <td>
                        <button className="btn btn-danger btn-sm" onClick={this.handleRemoveSpecificRow(idx)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={this.handleAddRow} className="btn btn-success">Add Decision Step</button>
</div>
		
		
		
		</td>
		</tr>
		<tr>
		<td><label>Create Report:</label></td>
		<td>
		 <select className="form-control"
		  ref="reportid"
		  id="create_report"
		  name="create_report"
		  value={this.state.fields["create_report"]}
		  onChange={this.handleChangeReport.bind(this, "create_report")}>
		  <option value="1">Yes</option>
		  <option value="2" selected="selected">No</option>
		  </select>
		</td>
		</tr>
		<tr className="option_row">
		<td><label>Option not opt:</label></td>
		<td>
		   <input
		   id="enable_option" 
		   name="enable_option" 
		   type="checkbox" 
		   value={this.state.fields["enable_option"]}
		   onChange={this.handleEmailOpt.bind(this, "enable_option")}
		   />
		</td>
		</tr>
		
		</tbody>
		</table>
		
              </div>
            </div>
          </div>
            <div className="form-group">
            <input className="btn btn-warning" name="apply" type="submit" value="Apply" />&nbsp;
            <input className="btn btn-success" name="save" type="submit" value="Save Tasks" />&nbsp;
		    <a className="btn btn-default" onClick={()=>this.redirectViewTasks(organizationid,collectionid,chartid,moduleid)}>Cancel</a>
            </div>
            </form>
		    </div>
            </div>
          </div>
    </div>
	</div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}

export default CreateSteps;