import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
class AddChecklist extends Component {
    constructor(props){
        super(props);
        this.state = {
        chart_title: '',
		option_false: '',
		option_true: '',
		chart_priority: 0,
        chart_loggable: 'false',
        ObjOrganizations: [],
        ObjCollections: [],
		ObjCollection: [],
		color_theme: '',
		items: [],
        fields: {},
        organization_template: {},
        collection_template: {},
        error: {}
        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectPlan=this.redirectPlan.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.redirectToEventAids=this.redirectToEventAids.bind(this);
		
     }
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 redirectEventAids(collectionid,organizationid,moduleid)
     {
	  this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
     }
	 redirectPlan(organization_id,moduleid)
     {
	  this.props.history.push('/collections/Collections?organization='+organization_id+'&moduleid='+moduleid);
     }
	 redirectToEventAids(collectionid,organizationid,moduleid)
     {
	  this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
      }
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  let chart_loggable_isChecked;
	  const plan_title = this.state.ObjCollection.title;
	  if(plan_title=='Emergency Response Plan')
	  {
	   chart_loggable_isChecked = document.getElementById('chart_loggable').checked;	  
	  }else{
	   chart_loggable_isChecked = false;
	  }
	  
	  
	  let chart_colour_isChecked = this.state.color_theme;
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let userid = session_array.id;
     //action 
     fields["action"]="createChecklist";
     fields["organizationid"]=organizationid;
	 fields["collectionid"]=collectionid;
	 fields["chart_loggable"]=chart_loggable_isChecked;
	 fields["userid"]=userid;
	 fields["color_theme"]=chart_colour_isChecked;
      //chart_title
      if(!fields["chart_title"]){
         formIsValid = false;
         errors["chart_title"] = "Cannot be empty";
      }
	  if(!fields["option_true"]){
         formIsValid = false;
         errors["option_true"] = "Cannot be empty";
      }
	  if(!fields["option_false"]){
         formIsValid = false;
         errors["option_false"] = "Cannot be empty";
      }
     this.setState({errors: errors});
     return formIsValid;
    }
    
    chartSubmit(e){
      e.preventDefault();
      if(this.handleValidation()){ 
        this.createChartApi();
      }else{
         alert("Mandatory Field Emplty!");
      }
    
    }
    handleChangeLoggable(field, e){         
      let fields = this.state.fields;
      let isChecked = e.target.checked;
      fields[field] = isChecked;     
      this.setState({fields});
    }
    handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
    redirectOrganization(){
      this.props.history.push('/Organization');
      }
      GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {
            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }

GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});
 console.log(this.ObjCollection);       
    }
    
    createChartApi()
    {
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  const moduleid = query.get('moduleid');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {
           const status=response.status;
		   const query=response.query;
           if(status === "true")
           { 
			  this.props.history.push('/EventAids?action=create&organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  handleWeekdayChange = (event) => {
    this.setState({color_theme: event.target.value})
  }
  componentDidMount() {
    this.GetOrganizationDetail();
    this.GetCollectionDetails();
  }
  GotoReport()
	{
		this.props.history.push('/Report');
	}
  render() {
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization'); 
	const collectionid = query.get('collectionid');
	const moduleid = query.get('moduleid');
	const plan_title = this.state.ObjCollection.title;
	let loggable_div;
	if(plan_title=='Emergency Response Plan')
	{
		loggable_div = <div className="form-group">
						<input 
						onChange={this.handleChangeLoggable.bind(this,'chart_loggable')}
						type="checkbox"
						value={this.state.fields["chart_loggable"]}
						id="chart_loggable" 
						name="chart_loggable" />
                        <label htmlFor="chart_loggable">&nbsp;Loggable</label>
                        </div>;
	}else{
		loggable_div = '';
	}
    let collectionList = this.state.ObjCollections.map(v => (
        <div>
          <input id="{v.id}"
           name="group_collection_id"
           type="checkbox"
           value={v.id}
           className="group_collection"
		   onChange={this.onToggle.bind(this)}
           />
          <label for="{v.id}">&nbsp;{v.title}</label>
        </div>
    ));
	if (localStorage.getItem('session')) {
    return (
        <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			  <li className=""><a onClick={this.GotoReport}>Report</a></li>
            <li className=""><a>Edit Profile</a></li>
            <li><a data-confirm="Are you sure you want to log out?" onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
           <a onClick={this.redirectOrganization}>Admin</a> &#187; <a onClick={()=>this.redirectPlan(organizationid,moduleid)}>Plans</a> &#187; <a onClick={()=>this.redirectToEventAids(collectionid,organizationid,moduleid)}>{this.state.ObjCollection.title}</a> &#187; New
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>New Checklist</h2>
  
  <form acceptCharset="UTF-8"
  onSubmit= {this.chartSubmit.bind(this)}
  className="new_chart"
  id="new_chart">
<div>
	<input name="utf8" type="hidden" value="?"/>
	<input name="authenticity_token" type="hidden"/>
	</div> 
  <div className="form-group">
      <label htmlFor="chart_title">Title</label>:
      <input className="form-control"
     id="chart_title" 
     name="chart_title" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "chart_title")}
     value={this.state.fields["chart_title"]}
     />
    </div>
    <div className="form-group">
	<label htmlFor="chart_priority">Priority</label>
    <input className="form-control"
     id="chart_priority" 
     name="chart_priority" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "chart_priority")}
     value={this.state.fields["chart_priority"]}
     />
    </div>
    {loggable_div}
	<hr/>
	<h4>Tab Color</h4>
	<div className="form-group">
	<table>
	<tbody>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} value="yellow" /></td>
	<td><label>&nbsp;Chrome Yellow</label></td>
	<td>&nbsp;<span className="chart_chrome_yellow"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} value="orange" /></td>
	<td><label>&nbsp;Orange</label></td>
	<td>&nbsp;<span className="chart_orange"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} value="olive_green" /></td>
	<td><label>&nbsp;Olive Green</label></td>
	<td>&nbsp;<span className="chart_olive_green"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} value="maroon" /></td>
	<td><label>&nbsp;Maroon</label></td>
	<td>&nbsp;<span className="chart_maroon"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} value="dark_green" /></td>
	<td><label>&nbsp;Dark Green</label></td>
	<td>&nbsp;<span className="chart_dark_green"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} value="royal_blue" /></td>
	<td><label>&nbsp;Royal Blue</label></td>
	<td>&nbsp;<span className="chart_royal_blue"></span></td>
	</tr>
	</tbody>
	</table>
	</div>
	<hr />
	<div className="form-group">
	<table>
	
	<tbody>
	<tr><td colSpan="3"><label>Description</label></td></tr>
	<tr>
	
	<td>
	 <input className="form-control"
     id="option_true" 
     name="option_true" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "option_true")}
     value={this.state.fields["option_true"]}
	 placeholder="Condition if True"
     />
	</td>
	<td width="20px">&nbsp;</td>
	<td>
	<input className="form-control"
     id="option_false" 
     name="option_false" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "option_false")}
     value={this.state.fields["option_false"]}
	 placeholder="Condition if False"
     />
	</td>
	</tr>
	</tbody>
	</table>
    </div>
	
    <div className="form-group">
      <input className="btn btn-success" name="commit" type="submit" value="Save"/>&nbsp;
	  <a className="btn btn-default" onClick={()=>this.redirectEventAids(collectionid,organizationid,moduleid)}>Cancel</a>
    </div>
  </form>
          </div>
        </div>
      </div>
  </div>
    );
	}else{
	window.location.href = '/';		
	}
  }
}
export default AddChecklist;