import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import Home from './Home';
import Login from './Login';
import Organization from './admin/Organization'
import EditProfile from './admin/EditProfile'
import compose from './compose'
import replymessages from './replymessages'
import emergency from './emergency'
import shooter from './shooter'
import messages from './messages'
import Report from './Report'
import Form from './Form'
import Addorganization from './admin/organization/Addorganization'
import Edit from './admin/organization/Edit'
import Variables from './admin/organization/Variables'
import Logs from './admin/organization/Logs'
import ViewLog from './admin/organization/ViewLog'
import Groups from './admin/organization/groups/Groups'
import Attachment from './admin/organization/file_attachments/Attachment'
import AddFileFolder from './admin/organization/file_attachments/AddFileFolder'
import EditFileFolder from './admin/organization/file_attachments/EditFileFolder'
import AddFile from './admin/organization/file_attachments/AddFile'
import EditFile from './admin/organization/file_attachments/EditFile'
import Collections from './admin/organization/collections/Collections'
import Modules from './admin/organization/modules/Modules'
import User from './admin/organization/users/User'
import AddUser from './admin/organization/users/AddUser'
import EditUser from './admin/organization/users/EditUser'
import ImportAutoCSV from './admin/organization/users/ImportAutoCSV'
import ImportCSV from './admin/organization/users/ImportCSV'
import AddGroup from './admin/organization/groups/AddGroup'
import EditGroup from './admin/organization/groups/EditGroup'
import AddCollections from './admin/organization/collections/AddCollections'
import AddModules from './admin/organization/modules/AddModules'
import EventAids from './admin/organization/collections/EventAids'
import ViewTasks from './admin/organization/collections/ViewTasks'
import CreateSteps from './admin/organization/collections/CreateSteps'
import EditTasks from './admin/organization/collections/EditTasks'
import NewTasks from './admin/organization/collections/NewTasks'
import AddEventAids from './admin/organization/collections/AddEventAids'
import AddChecklist from './admin/organization/collections/AddChecklist'
import EditChecklist from './admin/organization/collections/EditChecklist'
import EditEventAids from './admin/organization/collections/EditEventAids'
import SupportingMaterials from './admin/organization/collections/SupportingMaterials'
import CollectionSupportingMaterials from './admin/organization/collections/CollectionSupportingMaterials'
import ModuleSupportingMaterials from './admin/organization/modules/ModuleSupportingMaterials'
import EditCollections from './admin/organization/collections/EditCollections'
import EditModules from './admin/organization/modules/EditModules'
import Profile from './Profile';
import Collection from './Collection';
import help from './help';
import pdfTron from './pdfTron';
import FlowChart from './flowchart';
import Doc from './Doc';
import forgotpassword from './forgotpassword';
import slide from './slide';
import Charts from './Charts';
import CollectionList from './CollectionList';
import HtmlToPdf from './HtmlToPdf';
import Page from './components/Page';
/*import MultiPage from './components/MultiPage';*/
import Popup from './components/Popup';
import EmailPopup from './components/EmailPopup';
import PrintButton from './components/PrintButton';
import ChartsAction from './ChartsAction';
import PDFViewer from './components/PDFViewer/PDFViewer';
import incident_management_system from './Charts/incident_management_system';
import lockdown_secured_perimeter from './Charts/lockdown_secured_perimeter';
import hazardous_materials from './Charts/hazardous_materials';
import severe_weather_thunderstorm_and_tornado from './Charts/severe_weather_thunderstorm_and_tornado';
import evacuation from './Charts/evacuation';
import reverse_evacuation from './Charts/reverse_evacuation';
import shelter_in_place from './Charts/shelter_in_place';
import fire from './Charts/fire';
import reunification from './Charts/reunification';

import medical_emergency from './Charts/medical_emergency';
import earthquake from './Charts/earthquake';
import winter_storm from './Charts/winter_storm';
import violence_on_school_grounds from './Charts/violence_on_school_grounds';

import bomb_threat from './Charts/bomb_threat';
import civil_disorder from './Charts/civil_disorder';
import missing_person from './Charts/missing_person';
import sexual_assault from './Charts/sexual_assault';
import suicide_attempt_threat from './Charts/suicide_attempt_threat';

import power_outage from './Charts/power_outage';
import natural_gas_leak_loss_of_service from './Charts/natural_gas_leak_loss_of_service';
import flooding_sewer_incident from './Charts/flooding_sewer_incident';
import communications_loss from './Charts/communications_loss';
import food_and_water_contamination from './Charts/food_and_water_contamination';
import pandemic_incident from './Charts/pandemic_incident';

import transportation_incident from './Charts/transportation_incident';
import special_rescue from './Charts/special_rescue';
import structural_collapse from './Charts/structural_collapse';
import death_of_a_student_staff_member from './Charts/death_of_a_student_staff_member';
import chemical_attack_incident from './Charts/chemical_attack_incident';
import biological_attack_incident from './Charts/biological_attack_incident';

import explosion from './Charts/explosion';
import nuclear_attack_release from './Charts/nuclear_attack_release';
import radiological_attack_release from './Charts/radiological_attack_release';

import lock_down from './Charts/lock_down';

import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
class App extends Component {
constructor(){
    super();
    // Creating Global Variable.
    global.api_url = 'https://safeguardapi.safeguardses.com/api.php';
	global.flowchart_api_url = 'https://safeguardapi.safeguardses.com/flowchart_api.php';
	global.email_pdf_api_url = 'https://safeguardapi.safeguardses.com/email_pdf.php';
	global.pdf_url = 'https://safeguardapi.safeguardses.com/tcpdf/examples/collection_pdf.php';
  }

  render() {
	  let session= localStorage.getItem("session");
    return (
       <Router>
	   <Fragment>
       <Route exact path="/" component={Login}></Route>
       <Route   path="/Home" component={Home}></Route>
       <Route   path="/Collection" component={Collection}></Route>
	   <Route   path="/help" component={help}></Route>
	   <Route   path="/pdfTron" component={pdfTron}></Route>
	   <Route   path="/FlowChart" component={FlowChart}></Route> 
	   <Route   path="/Doc" component={Doc}></Route>
	   <Route   path="/forgotpassword" component={forgotpassword}></Route>
	   <Route   path="/Charts" component={Charts}></Route>
	   <Route   path="/Report" component={Report}></Route>
	   <Route   path="/Form" component={Form}></Route>
	   <Route   path="/CollectionList" component={CollectionList}></Route>
	   <Route   path="/HtmlToPdf" component={HtmlToPdf}></Route>
	   <Route   path="/ChartsAction" component={ChartsAction}></Route>
	   <Route   path="/Page" component={Page}></Route>
	   <Route   path="/Popup" component={Popup}></Route>
	   <Route   path="/EmailPopup" component={EmailPopup}></Route>
	   <Route   path="/PrintButton" component={PrintButton}></Route>
       <Route   path="/Login" component={Login}></Route>
       <Route   path="/Organization" component={Organization}></Route>
	   <Route   path="/EditProfile" component={EditProfile}></Route>
	   <Route   path="/compose" component={compose}></Route>
	   <Route   path="/replymessages" component={replymessages}></Route>
	   <Route   path="/emergency" component={emergency}></Route>
	   <Route   path="/shooter" component={shooter}></Route>
	   <Route   path="/messages" component={messages}></Route>
       <Route   path="/Addorganization" component={Addorganization}></Route>
       <Route   path="/Edit" component={Edit}></Route>
       <Route   path="/groups/Groups" component={Groups}></Route>
       <Route   path="/AddGroup" component={AddGroup}></Route>
       <Route   path="/EditGroup" component={EditGroup}></Route>
       <Route   path="/users/User" component={User}></Route>
       <Route   path="/AddUser" component={AddUser}></Route>
       <Route   path="/EditUser" component={EditUser}></Route>
	   <Route   path="/ImportCSV" component={ImportCSV}></Route>
	   <Route   path="/ImportAutoCSV" component={ImportAutoCSV}></Route>
       <Route   path="/collections/Collections" component={Collections}></Route>
	   <Route   path="/modules/Modules" component={Modules}></Route>
	   <Route   path="/AddCollections" component={AddCollections}></Route>
	   <Route   path="/AddModules" component={AddModules}></Route>
       <Route   path="/EventAids" component={EventAids}></Route>
	   <Route   path="/AddEventAids" component={AddEventAids}></Route>
	   <Route   path="/AddChecklist" component={AddChecklist}></Route>
	   <Route   path="/ViewTasks" component={ViewTasks}></Route>
	   <Route   path="/CreateSteps" component={CreateSteps}></Route>
	   <Route   path="/EditTasks" component={EditTasks}></Route>
	   <Route   path="/PDFViewer" component={PDFViewer}></Route>
	   <Route   path="/EditEventAids" component={EditEventAids}></Route>
	   <Route   path="/EditChecklist" component={EditChecklist}></Route>
	   <Route   path="/NewTasks" component={NewTasks}></Route>
	   <Route   path="/SupportingMaterials" component={SupportingMaterials}></Route>
	   <Route   path="/CollectionSupportingMaterials" component={CollectionSupportingMaterials}></Route>
	   <Route   path="/ModuleSupportingMaterials" component={ModuleSupportingMaterials}></Route>
	   <Route   path="/EditCollections" component={EditCollections}></Route>
	   <Route   path="/EditModules" component={EditModules}></Route>
	   <Route   path="/Variables" component={Variables}></Route>
	   <Route   path="/Logs" component={Logs}></Route>
	   <Route   path="/ViewLog" component={ViewLog}></Route>
	   <Route   path="/Attachment" component={Attachment}></Route>
	   <Route   path="/AddFileFolder" component={AddFileFolder}></Route>
	   <Route   path="/EditFileFolder" component={EditFileFolder}></Route>
	   <Route   path="/AddFile" component={AddFile}></Route>
	   <Route   path="/EditFile" component={EditFile}></Route>
       <Route   path="/Profile" component={Profile}></Route>
	   <Route   path="/slide" component={slide}></Route>
	   <Route   path="/incident_management_system" component={incident_management_system}></Route>
	   <Route   path="/lockdown_secured_perimeter" component={lockdown_secured_perimeter}></Route>
	   <Route   path="/hazardous_materials" component={hazardous_materials}></Route>
	   <Route   path="/severe_weather_thunderstorm_and_tornado" component={severe_weather_thunderstorm_and_tornado}></Route>
	   <Route   path="/evacuation" component={evacuation}></Route>
	   <Route   path="/reverse_evacuation" component={reverse_evacuation}></Route>
	   <Route   path="/shelter_in_place" component={shelter_in_place}></Route>
	   <Route   path="/fire" component={fire}></Route>
	   <Route   path="/reunification" component={reunification}></Route>
	   <Route   path="/medical_emergency" component={medical_emergency}></Route>
	   <Route   path="/earthquake" component={earthquake}></Route>
	   <Route   path="/winter_storm" component={winter_storm}></Route>
	   <Route   path="/violence_on_school_grounds" component={violence_on_school_grounds}></Route>
	   
	   <Route   path="/bomb_threat" component={bomb_threat}></Route>
	   <Route   path="/civil_disorder" component={civil_disorder}></Route>
	   <Route   path="/missing_person" component={missing_person}></Route>
	   <Route   path="/sexual_assault" component={sexual_assault}></Route>
	   <Route   path="/suicide_attempt_threat" component={suicide_attempt_threat}></Route>
	   
	   <Route   path="/power_outage" component={power_outage}></Route>
	   <Route   path="/natural_gas_leak_loss_of_service" component={natural_gas_leak_loss_of_service}></Route>
	   <Route   path="/flooding_sewer_incident" component={flooding_sewer_incident}></Route>
	   <Route   path="/communications_loss" component={communications_loss}></Route>
	   <Route   path="/food_and_water_contamination" component={food_and_water_contamination}></Route>
	   <Route   path="/pandemic_incident" component={pandemic_incident}></Route>
	   
	   <Route   path="/transportation_incident" component={transportation_incident}></Route>
	   <Route   path="/special_rescue" component={special_rescue}></Route>
	   <Route   path="/structural_collapse" component={structural_collapse}></Route>
	   <Route   path="/death_of_a_student_staff_member" component={death_of_a_student_staff_member}></Route>
	   <Route   path="/chemical_attack_incident" component={chemical_attack_incident}></Route>
	   <Route   path="/biological_attack_incident" component={biological_attack_incident}></Route>
	   
	   <Route   path="/explosion" component={explosion}></Route>
	   <Route   path="/nuclear_attack_release" component={nuclear_attack_release}></Route>
	   <Route   path="/radiological_attack_release" component={radiological_attack_release}></Route>
	   
	   <Route   path="/lock_down" component={lock_down}></Route>
	   </Fragment>
       </Router>
	   
	   
	   
    );
  }
}

export default App;