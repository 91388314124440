import React, { Component } from 'react';
import './comments_style.css';
import axios from 'axios';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Comments extends Component{
	constructor(props){
        super(props);
        this.state = {
        variable_full_name: '',
		ObjComments : [],
        variable_description: '',
        variable_lookup_code: '',
        variable_original_name: '',
		comments_template : {},
        fields: {},
        error: {},
		chartid : '',
		edit_template : {},
		success : '',
		notification : ''
        }
     }
    componentWillMount() {
		this.getChartTotalComments();
	}
    handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
     //action 
     fields["action"]="createVariable";
	 fields["organization_id"]=organization_id;
      //organization_name
      if(!fields["variable_full_name"]){
         formIsValid = false;
         errors["variable_full_name"] = "Cannot be empty";
      }
     this.setState({errors: errors});

     return formIsValid;
    }
	handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
	variableSubmit(e){
	 e.preventDefault();
	 if(this.handleValidation()){
	 //console.log(this.state.fields);
	 this.createVariableApi();	 
	 }else{
	 alert("Mandatory Field Emplty!");	 
	 }
	}
	createVariableApi()
    {
	  let session_element = localStorage.getItem("session");
	  let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;	
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           { 
              this.props.history.push('/Variables?organization='+organization_id);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
	getChartTotalComments()
	{
	  let chartid =  localStorage.getItem("report_chartid");
	  this.setState({
          chartid: chartid
      })
	  let comments_template = this.state.comments_template;
      let ObjComments = this.state.ObjComments;
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	    let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
      comments_template["action"]="getTotalCommnets";
	  comments_template["organizationid"]=organizationid;
	  comments_template["chartid"]=chartid;
	  comments_template["userid"]=userid;
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.comments_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const code=response.code;
			const comments_list=response.comments_list;
           if(status === "true")
           {  
            this.setState({
              ObjComments : comments_list
            }) 
           }       
     });
	}
	handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
	saveComments(cardid,card_comments,multichecklistid)
	{
	  let edit_template = this.state.edit_template;
	  let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
	  let comments = this.state.fields.comments;
	  let final_comments;
	  if (typeof comments === "undefined") {
			  final_comments = card_comments;
			} else {
			  final_comments = comments;
			}
      edit_template["action"]="editComments";
	  edit_template["cardid"]=cardid;
	  edit_template["userid"]=userid;
	  edit_template["comments"]=final_comments;
	  edit_template["multichecklistid"]=multichecklistid;
	  console.log(JSON.stringify(this.state.edit_template));
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.edit_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			
			const code=response.code;
			let sucess_message;
           if(status === "true")
           {
			   this.setState({
              notification : 'Comment saved successfully!'
            }) 
           }else{
		   }
     });
	}
	
  render() {
	  //console.log(JSON.stringify(this.state.ObjComments));
	  
	  let search_table;
	  search_table = this.state.ObjComments.map(v => (
      <tr>
	  <td>
	  <div className="header_step">Checklist - Step : {v.current_step}</div>
      {ReactHtmlParser(v.card_content)}
	  <div>
	  <textarea className="textareaBOX" name="comments" id={'comments_'+v.id} defaultValue={ReactHtmlParser(v.comments)} onChange={this.handleChangeName.bind(this,"comments")} />
	  <button type="button" id={'save_'+v.id} name={'save_'+v.id} className="btn btn-success" onClick={()=>this.saveComments(v.id,v.comments,v.multichecklistid)}>Edit</button>
	  </div>
	  </td>
	  </tr>
    ));
	  
    return (
      <div className='popup'>
        <div className='popup_inner'>
		<div className="modal-header">
          <h4>Comments</h4>
		  <span>{this.state.notification}</span>
		  <button onClick={this.props.closePopup} className="btn btn-default">close</button>
		  </div>
		        <div className="modal-body">
				<table className="table table-striped table-bordered table wice-grid">
				<tbody>
				{search_table}
				</tbody>
				</table>
				
                </div>
				<div className="modal-footer">
				
				</div>
        </div>
      </div>
    );
  }
}
export default Comments;