import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import alaSQLSpace from 'alasql';
import Select from 'react-select';
//import { MDBDataTableV5 } from 'mdb-react';
//import { DatePicker } from 'react-bootstrap-date-picker';
import Comments from './components/Comments';
import Rename from './components/Rename';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import $ from 'jquery';
//import SelectSearch from 'react-select-search';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class Report extends Component {
    constructor(props){
        super(props);
		
        this.state = {
		   storage_template: {},
		   ObjStorage: [],
		   ObjCollection: [],
		   ObjCharts: [],
		   fields: {},
		   userid : '',
		   groupid : '',
		   checklistid : '',
		   reportnameid : '',
		   ObjOrganization:'',
		   isLoggedIn: false,
		   messages_template : {},
		   group_template : {},
		   reportname_template : {},
		   checklist_template : {},
		   ObjMessages : [],
		   user_template : {},
		   ObjUsers : [],
		   ObjGroup : [],
		   ObjReportName : [],
		   ObjSearch : [],
		   ObjNoCharts : [],
		   ObjPDF : [],
		   checklistCount : '',
		   loader: 'spinner',
		   filepath: '',
		   delete_template : {},
		   report_column_template : {},
		   report_name_template : {},
		   org_description:'',
		   render: false,
		   pdf_template : {},
		   button_class : 'button-hide',
		   showPopup: false,
		   showRenamePopup: false,
		   report_name : 'Incident',
		   lockStatus_template : {},
		   loader_class: '',
		   checkboxArray : []
        }
    this.Gotoorg=this.Gotoorg.bind(this);
	this.GotoHelp=this.GotoHelp.bind(this);
    this.GotoDoc=this.GotoDoc.bind(this);
    this.GotoProfile=this.GotoProfile.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
	this.GotoSendMessage=this.GotoSendMessage.bind(this);
	this.GotoEmergency=this.GotoEmergency.bind(this);
	this.GotoShooter=this.GotoShooter.bind(this);
	this.GotoViewMessage=this.GotoViewMessage.bind(this);
	this.redirectChart=this.redirectChart.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.GotoForm=this.GotoForm.bind(this);
     }

    redirectDashboard(){
		this.props.history.push('/Collection');
	}
    Gotoorg(){
        this.props.history.push('/Organization');
    }
	GotoHelp(){
		this.props.history.push('/help');
	}
	
	GotoDoc(){
		this.props.history.push('/Doc');
    }
	GotoReport()
	{
		this.props.history.push('/Report');
	}
	GotoForm()
	{
		this.props.history.push('/Form');
	}
	
	togglePopup(chartid,lock_status) {
		if(lock_status==1){
		alert("You do not have the access to perform this action!");
		}else{
			localStorage.setItem("report_chartid","");
		    localStorage.setItem("report_chartid",chartid);
			   this.setState({
				 showPopup: !this.state.showPopup
			   });
			   if (Object.keys(this.state.fields).length == 0) {
					 this.getChecklist();
					}else{
						this.searchFilter();
					}
		
		}
 }
 toggleRenameClosePopup(checklist_array)
 {
	 this.setState({
     showRenamePopup: !this.state.showRenamePopup
   });
	this.getReportNameList();			
	if (Object.keys(this.state.fields).length == 0) {
		 this.getChecklist();
		}else{
			this.searchFilter();
		}
		
 }
 toggleRenamePopup(checklist_array) {
	 localStorage.setItem("selected_checklist","");
	 localStorage.setItem("selected_checklist",checklist_array);
   this.setState({
     showRenamePopup: !this.state.showRenamePopup
   });
 }
 deleteChecklist()
 {
	if (window.confirm("Are you sure you want to delete this Checklist?"))
	{
      this.deleteSelected();
    }
 }
editColumn(organizationid,userid)
 {
	var report_name = window.prompt("Report Name", "");
	if(report_name!='' && report_name!=null)
	{
	let report_name_template = this.state.report_name_template;
	report_name_template["action"]="editReportName";
	report_name_template["organizationid"]=organizationid;
	report_name_template["userid"]=userid;
	report_name_template["report_name"]=report_name;
	fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.report_name_template)
  })
   .then((response) => response.json())
   .then((response) => {
		  const status=response.status;
		  if(status === "true")
			{
			    this.setState({
                report_name: report_name
                })
			} 
  });
	}else{
	}
	
	
	
 } 
deleteSelected()
{
let delete_template = this.state.delete_template;
      let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
delete_template["organizationid"]=organizationid;
delete_template["action"]="deleteCheklist";
delete_template["checklist_array"]=this.state.checkboxArray;
//console.log(JSON.stringify(this.state.delete_template));
fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
	 const code=response.code;
    if(status === "true")
    {
		if (Object.keys(this.state.fields).length == 0) {
		 this.getChecklist();
		}else{
			this.searchFilter();
		}
		$("#rename_report").hide();
		$("#del_checklist").hide();
	   //window.location.reload();
	   //$('.checkrow').removeAttr('checked');
	    $(".checkrow").prop('checked', false);
    }       
});
 }
 handleCheckboxAll()
 {
	  var ischecked= $("#checkAll").is(':checked');
	  var card_array = this.state.checkboxArray;
	  card_array = [];
	  if(!ischecked)
	  {
	  $(".checkrow").prop('checked', false);	  
	  }else{
	  $(".checkrow").prop('checked', true);
	  $(".checkrow:checked").each(function(){
		card_array.push($(this).val());
		});
	  }
	  
	  if($('.checkrow:checked').length > 0)
    {
        this.setState({
          button_class: ''
      })
    }else{
		this.setState({
          button_class: 'button-hide'
      })
	}
	
 }
 
 handleCheckboxChange(id)
 {
	 let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let login_userid=session_array.id;
	  let admin = session_array.admin;
	  let organization_admin = session_array.organization_admin;
	  let super_admin = session_array.super_admin;
	  
	 var ischecked= $('#check_'+id).is(':checked');
	  var card_array = this.state.checkboxArray;
	  
	 if(ischecked)
	  {
	  card_array.push(id);	  
	  }else{
	 card_array.splice($.inArray(id, card_array), 1);
	  
	  }
	
	 
	 if($('.checkrow:checked').length > 0)
    {
        this.setState({
          button_class: ''
      })
    }else{
		this.setState({
          button_class: 'button-hide'
      })
	}
	
 }
 redirectChecklist(chartid,collectionid,checklist_status,level1,level2,lock_status,chart_id){
	 
	 if($('#row_'+chartid).prop('checked') == true || lock_status==1){
		 alert("You do not have the access to perform this action!");
	 }else{
	 
	 if(checklist_status=='Complete')
	 {
	 if (window.confirm("Do you want to make correction?"))
	{
	  localStorage.setItem('current_multichecklistid', '');
	  localStorage.setItem('current_step',1);
      this.props.history.push('/ChartsAction?collectionid='+collectionid+'&chartid='+chart_id+'&checklistid='+chartid);
	  
    }
	}else{
		localStorage.setItem('current_multichecklistid', '');
		localStorage.setItem('current_step',1);
		this.props.history.push('/ChartsAction?collectionid='+collectionid+'&chartid='+chart_id+'&checklistid='+chartid);
		
	}
	 
	 }
	 
 }
	redirectChart(moduleid){
		this.props.history.push('/CollectionList?moduleid='+moduleid);
		localStorage.setItem('moduleid', '');
		localStorage.setItem('moduleid', moduleid);
    }

  GotoProfile(){
    this.props.history.push('/Profile');
  }
 
 GotoSendMessage()
  {
	  this.props.history.push('compose');
  }
  
  GotoEmergency()
  {
	  this.props.history.push('emergency');
  }
  
  GotoShooter()
  {
	  this.props.history.push('shooter');
  }
  
  GotoViewMessage()
  {
	this.props.history.push('messages');
	 
  }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  componentWillMount() {
	this.getOrganizationList();  
	this.getGroupList();
	this.getReportNameList();
	this.getColumnName();
	//this.getUserList();
	
	this.getSearchChecklist();
	if (Object.keys(this.state.fields).length == 0) {
		 this.getChecklist();
		}else{
			this.searchFilter();
		}
  }
  getOrganizationList()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }
  getColumnName()
  {
	  let report_column_template = this.state.report_column_template;
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  
	  report_column_template["action"]="getReportName";
	  report_column_template["organizationid"]=organizationid;
	  
	   fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.report_column_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const report_name=response.report_name;
           if(status === "true")
           {  
            this.setState({
              report_name : report_name
            }) 
			
           }       
     });
  }
  getGroupList()
  {
	  let group_template = this.state.group_template;
      let ObjGroup = this.state.ObjGroup;
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  
	  let user_details = localStorage.getItem("session");
      let user_array=JSON.parse(user_details);
	  let userid = user_array.id;
	  let groupid = user_array.group_id;
	  
      group_template["action"]="getSearchGroupList";
	  group_template["organizationid"]=organizationid;
	  group_template["groupid"]=groupid;
	  
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.group_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const grouplist=response.grouplist;
           if(status === "true")
           {  
            this.setState({
              ObjGroup : grouplist
            }) 
			
           }       
     });
  }
  
  getReportNameList()
  {
	  let reportname_template = this.state.reportname_template;
      let ObjReportName = this.state.ObjReportName;
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  
	  let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let current_user = session_array.id;
	  
      reportname_template["action"]="getReportNameList";
	  reportname_template["organizationid"]=organizationid;
	  reportname_template["current_user"]=current_user;
	  
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.reportname_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const list=response.list;
           if(status === "true")
           {  
            this.setState({
              ObjReportName : list
            }) 
			
           }       
     });
  }
  
  getUserList(groupid)
  {
	  let user_template = this.state.user_template;
      let ObjUsers = this.state.ObjUsers;
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
      user_template["action"]="getSearchUserList";
	  user_template["organizationid"]=organizationid;
	  user_template["groupid"]=groupid;
	  this.setState({
              ObjUsers : []
            });
	  if(groupid!="" && groupid!=undefined && groupid!=null && organizationid!="" && organizationid!=undefined && organizationid!=null){
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.user_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const userlist=response.userlist;
           if(status === "true")
           {  
            this.setState({
              ObjUsers : userlist
            }) 
           }else{
			   this.setState({
              ObjUsers : []
            })
		   }
     });
  	}
  }
  getChecklist()
  {
	        this.setState({
			loader_class: 'checklist_loader'
			})
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  
	  let user_details = localStorage.getItem("session");
      let user_array=JSON.parse(user_details);
	  let userid = user_array.id;
	  let groupid = user_array.group_id;
	  let checklist_template = this.state.checklist_template;
	  let ObjCharts = this.state.ObjCharts;
	  let ObjSearch = this.state.ObjSearch;
	  checklist_template["action"]="getSearchChecklist";
	  checklist_template["organizationid"]=organizationid;
	  checklist_template["userid"]=userid;
	  checklist_template["groupid"]=groupid;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.checklist_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const checklist=response.checklist;
			const count = response.count;
			const code = response.code;
			this.setState({
			loader_class: ''
			})
           if(status === "true")
           {  
            this.setState({
			  ObjCharts : [],			  
              ObjCharts : checklist,
			  checklistCount : count
            }) 
           }else{
			this.setState({
			  ObjCharts : [],				 
              ObjCharts : checklist,
			  checklistCount : count
            }) 
		   }
     });
  }
  
  getSearchChecklist()
  {
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  
	  let user_details = localStorage.getItem("session");
      let user_array=JSON.parse(user_details);
	  let userid = user_array.id;
	  let groupid = user_array.group_id;
	  let checklist_template = this.state.checklist_template;
	  let ObjCharts = this.state.ObjCharts;
	  let ObjSearch = this.state.ObjSearch;
	  checklist_template["action"]="getAllSearchChecklist";
	  checklist_template["organizationid"]=organizationid;
	  checklist_template["userid"]=userid;
	  checklist_template["groupid"]=groupid;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.checklist_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const checklist=response.checklist;
           if(status === "true")
           {  
            this.setState({
			  ObjSearch : checklist
            }) 
           }       
     });
	  
	  /*let safeguard=localStorage.getItem("safeguard");
      let safeguard_array=JSON.parse(safeguard);
	  let charts = safeguard_array.charts;
	  let res = alaSQLSpace('SELECT * FROM ? where organization_id = ? and user_id = ? and checklist = ?', [charts,organizationid,userid,'1']);
	  this.setState({
          ObjCharts : res,
		  ObjSearch : res
      })*/
  
  }
  handleValidation(){
	  let organization_details = localStorage.getItem("organization_details");
      let organization_array=JSON.parse(organization_details);
	  let organizationid = organization_array.id;
	  let user_details = localStorage.getItem("session");
      let user_array=JSON.parse(user_details);
	  let login_userid = user_array.id;
	  let login_groupid = user_array.group_id;
	  let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  //let groupid = document.getElementById('search_group').value;
	  let groupid = this.state.groupid;
	  let userid = this.state.userid;
	  let status = document.getElementById('search_status').value;
	  let checklist = this.state.checklistid;
	  let reportname = this.state.reportnameid;
	  let created_from = document.getElementById('created_from').value;
	  let created_to = document.getElementById('created_to').value;
	  let updated_from = document.getElementById('updated_from').value;
	  let updated_to = document.getElementById('updated_to').value;
	  //alert('searching ....');
	  //alert(groupid+"-"+status+"-"+checklist+"-"+created_from+"-"+created_to+"-"+updated_from+"-"+updated_to+"-"+userid);
	  fields["action"] = "searchFilter";
	  fields["groupid"] = groupid;
	  fields["userid"] = userid;
	  fields["login_userid"] = login_userid;
	  fields["login_groupid"] = login_groupid;
	  fields["status"] = status;
	  fields["chartid"] = checklist;
	  fields["reportname"] = reportname;
	  fields["created_from"] = created_from;
	  fields["created_to"] = created_to;
	  fields["updated_from"] = updated_from;
	  fields["updated_to"] = updated_to;
	  fields["organizationid"] = organizationid;
	  return formIsValid;
  }
  searchSubmit(e)
  {
	  e.preventDefault();
	  if(this.handleValidation()){
		  this.searchFilter();
	  }else{
		  alert('Mandetory fields missing!');
	  }
  }
  handleChangeName(field,e){         
      let reportnameid = this.state.reportnameid;
      reportnameid = e.target.value;        
      this.setState({reportnameid});
    }
	handleChangeChecklist(field,e){         
      let checklistid = this.state.checklistid;
      checklistid = e.target.value;        
      this.setState({checklistid});
    }	
  handleChangeGroup(field,e){         
  let groupid = this.state.groupid;
  groupid = e.target.value;
  this.setState({groupid});
  this.getUserList(e.target.value);
    }
  handleChangeUser(field,e){         
      let userid = this.state.userid;
      userid = e.target.value;        
      this.setState({userid});
    }
  clearChecklist(e)
  {
	  e.preventDefault();
	  this.setState({
			 groupid : '',
			 userid : '',
			 checklistid : '',
			 reportnameid : '',
			 fields : {}
             })
	   $('#search_status').prop('selectedIndex',0);
	   document.getElementById("created_from").value = "";
	   document.getElementById("created_to").value = "";
	   document.getElementById("updated_from").value = "";
	   document.getElementById("updated_to").value = "";
	   this.getChecklist();
  }
  
  searchFilter()
  {
	  this.setState({
	  loader_class: 'checklist_loader'
	  })
      let ObjSearch = this.state.ObjSearch; 
	  console.log(JSON.stringify(this.state.fields));
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.fields)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const code=response.code;
			const list=response.list;
			const count=response.count;
			this.setState({
			  loader_class: ''
			  })
           if(status === "true")
          { 
			 this.setState({
			 ObjCharts : [],			   
             ObjCharts : list,
			 checklistCount : count
             })
           }else{
			 this.setState({
			 ObjCharts : [],			   
             ObjCharts : list,
			 checklistCount : count
            })
		   }
     });
  }
  handleCheckboxChangeLock(cardid)
  {
	  if($('#row_'+cardid).prop('checked') == true){
	  let lockStatus_template = this.state.lockStatus_template;
      lockStatus_template["action"]="updateLockStatus";
	  lockStatus_template["id"]=cardid;
	  lockStatus_template["lock_status"]=1;
	  //console.log(JSON.stringify(this.state.lockStatus_template));
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.lockStatus_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const code=response.code;
           if(status === "true")
           { 
		      if (Object.keys(this.state.fields).length == 0) {
						 this.getChecklist();
						}else{
							this.searchFilter();
						}
           }else{
		   }
     });
		}else{
	  let lockStatus_template = this.state.lockStatus_template;
      lockStatus_template["action"]="updateLockStatus";
	  lockStatus_template["id"]=cardid;
	  lockStatus_template["lock_status"]=0;
	  fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.lockStatus_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const code=response.code;
           if(status === "true")
           {
		if (Object.keys(this.state.fields).length == 0) {
		 this.getChecklist();
		}else{
			this.searchFilter();
		}
           }else{
		   }
     });
			
	   }
	}
 openPDF(chartid,userid,lock_status,chart_id)
 {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid=session_array.organization_id;
	  let filename = 'checklist_'+chartid+'.pdf';
	  let pdf_template = this.state.pdf_template;
      let ObjPDF = this.state.ObjPDF;
	  pdf_template["organizationid"]=organizationid;
	  pdf_template["chartid"]=chartid;
	  pdf_template["chart_id"]=chart_id;
	  pdf_template["userid"]=userid;
	  pdf_template["action"]='showPDF';
	  $('#classPrint_'+chartid).text('');
	  $('#classLoading_'+chartid).addClass('loadingButton');
	  $('#classLoading_'+chartid).text('Loading');
	 fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.pdf_template)
     })
      .then((response) => response.json())
      .then((response) => {
			 const status=response.status;
			 if(status === "true")
             {
				 
				 window.open('https://safeguardapi.safeguardses.com/download/'+filename, '_blank');
				  $('#classPrint_'+chartid).text('Print');
				  $('#classLoading_'+chartid).removeClass('loadingButton');
				  $('#classLoading_'+chartid).text('');
			 }
      });
 
	 
 }
 
 handleGroupChange = (e,value) => {
 }
    render() {
	let organization_details= localStorage.getItem("organization_details");
    let organization_details_array=JSON.parse(organization_details);
	let organization_messaging_enabled = organization_details_array.messaging_enabled;
	let organization_id = organization_details_array.id;
	let token = localStorage.getItem("session");
	let token_array=JSON.parse(token);
	let current_user = token_array.id;
	let user_level_2 = token_array.admin;
	let user_level_1 = token_array.organization_admin;
	let view_message_button = token_array.view_message_button;
	let user_super_admin = token_array.super_admin;
	let organization_token = localStorage.getItem("organization_details");
	let organization_token_array=JSON.parse(organization_token);
	let user_logo = this.state.filepath;
	let org_description = this.state.org_description;
	let filepath = <img className="orgLogo" src="/assets/images/ses-logo.png" /> ;
	let selected_checklist = this.state.checkboxArray;
	//console.log(JSON.stringify(this.state.ObjCharts));
	
	let collectionList_count = this.state.ObjCollection.length;
	let userList;
	let groupList;
	let reportnameList;
	let checklist_all;
	let search_table;
	let collections_header;
	let checklistCount = this.state.checklistCount;

	checklist_all = this.state.ObjSearch.map(v => (
      <option value={v.id}>{v.name}</option>
    ));
	userList = this.state.ObjUsers.map(v => (
      <option value={v.id}>{v.name}</option>
    ));
	groupList = this.state.ObjGroup.map(v => (
      <option value={v.id}>{v.name}</option>
    ));
	reportnameList = this.state.ObjReportName.map(v => (
      <option value={v.id}>{v.name}</option>
    ));
	
	if(checklistCount > 0)
	{
	search_table = this.state.ObjCharts.map(v => (
      <tr>
      <td><input id={'check_'+v.id} name={'check_'+v.id} className="checkrow" type="checkbox" value={v.lock_status_value} onChange={()=>this.handleCheckboxChange(v.id)} disabled={v.lock_status_value} /></td>
	  <td>
	  {user_level_1==1 || user_level_2==1 || user_super_admin==1?<label className="switch"><input type="checkbox" value={v.lock_status_value} className="tablerow" name={'row_'+v.id} id={'row_'+v.id} onChange={()=>this.handleCheckboxChangeLock(v.id)} defaultChecked={v.lock_status_value} /><span className="slider round"></span></label>:''}
	  </td>
	  <td><a onClick={this.redirectChecklist.bind(this,v.id,v.collection,v.checklist_status,user_level_1,user_level_2,v.lock_status,v.chartid)}>{v.title}</a></td>
	  <td>{v.report_name}</td>
	  <td>{v.username}</td>
	  <td>{v.checklist_status=='Complete'?<button type="button" id={'statusButton_'+v.id} className="btn btn-success btn-sm" onClick={this.redirectChecklist.bind(this,v.id,v.collection,v.checklist_status,user_level_1,user_level_2,v.lock_status,v.chartid)}>{v.checklist_status}</button>:<button type="button" className="btn btn-danger btn-sm" onClick={this.redirectChecklist.bind(this,v.id,v.collection,v.checklist_status,user_level_1,user_level_2,v.lock_status,v.chartid)}>{v.checklist_status}</button>}</td>
	  <td>{v.created_at}</td>
	  <td>{v.updated_at}</td>
	  <td>
	  <button type="button" className="btn btn-primary comments_button" onClick={this.togglePopup.bind(this,v.id,v.lock_status)}>Comments</button>
	  {this.state.showPopup ?
			 <Comments
			  text='Click "Close Button" to hide popup'
			  closePopup={this.togglePopup.bind(this,v.id)}
			 />
         : null
       }
	  </td>
	  <td>
	  <button className="btn btn-primary btn-sm afterloading" type="button" onClick={this.openPDF.bind(this,v.id,v.user_id,v.lock_status,v.chartid)}>
		<span id={'classLoading_'+v.id}></span>
		<span id={'classPrint_'+v.id}>PRINT</span>
	  </button>
	  </td>
	  </tr>
    ));
	}else{
	search_table = this.state.ObjNoCharts.map((v, key) => (
      <tr id={key}>
      <td colSpan="8">No records found!</td>
	  </tr>
    ));
	}
	
if (localStorage.getItem('session')) {	  
 return (
    <div id="ember361" className="ember-view ember-app"><div className="menu">
        <ul>
          <li className="homeicon"><a onClick={this.redirectDashboard}>h</a></li>
          <li className="back"><a>M</a></li>
          <li className="titlebar">{this.state.ObjOrganization}</li>
          <li className="logout"><a onClick= {this.logout}>o<span className="text">Logout</span></a></li>
          <li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>
          <li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
		  <li className="docs" onClick={this.GotoForm}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/form.png" width="22px" height="20px" /> <span>Form</span></span></a></li>
		  <li className="docs" onClick={this.GotoReport}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/report.png" width="22px" height="20px" /> <span>Report</span></span></a></li>
          <li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
          {user_level_1==1 || user_super_admin==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
        </ul>
      </div>
      <div id="application">
	    <form onSubmit= {this.searchSubmit.bind(this)}>
		<div className="collections formBOX">
		<div className="formwhiteBG">
        <ul>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
        <label>{this.state.report_name}</label>
		<select
		className="form-control"
		placeholder="Select Report Names"
		ref = {(input)=> this.menu = input}
		name="report_name"
		id="report_name"
		value={this.state.reportnameid}
		onChange={this.handleChangeName.bind(this, "report_name")}
		>
		<option value="">Select an option</option>
		{reportnameList}
		</select>
        </div>
		</li>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
        <label>Group</label>
		<select
		className="form-control"
		name="group_name"
		id="group_name"
		value={this.state.groupid}
		onChange={this.handleChangeGroup.bind(this, "group_name")}
		>
		<option value="">Select an option</option>
		{groupList}
		</select>
        </div>
		</li>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
		<label>Users</label>
		<select
		className="form-control"
		name="user_name"
		id="user_name"
		value={this.state.userid}
		onChange={this.handleChangeUser.bind(this, "user_name")}
		>
		<option value="">Select user</option>
		{userList}
		</select>
		</div>
		</li>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
        <label htmlFor="template_id">Status</label>
		<select
		className="form-control"
		name="search_status"
		id="search_status">
		<option>Select an option</option>
		<option value="complete">Complete</option>
		<option value="incomplete">Incomplete</option>
		</select>
        </div>
		</li>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
        <label htmlFor="template_id">Created</label>
		<div className="datepickerCONT">
			<div className="datepkrbox">
			<label>From</label>
			<input
			type="date"
			className="form-control fromdatepicker"
			name="created_from"
			id="created_from" />
			</div>
			<div className="datepkrbox">
			<label>To</label>
			<input
			type="date"
			className="form-control fromdatepicker"
			name="created_to"
			id="created_to" />
			</div>
        </div>
		</div>
		</li>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
        <label htmlFor="template_id">Updated</label>
		<div className="datepickerCONT">
			<div className="datepkrbox">
			<label>From</label>
			<input
			type="date"
			className="form-control fromdatepicker"
			name="updated_from"
			id="updated_from" />
			</div>
			<div className="datepkrbox">
			<label>To</label>
			<input
			type="date"
			className="form-control fromdatepicker"
			name="updated_to"
			id="updated_to" />
			</div>
		 </div>
        </div>
		</li>
		<li>
		<div className="form-group" style={{marginBottom:13}}>
        <label htmlFor="template_id">Checklist</label>
		<select
		className="form-control"
		name="checklist"
		id="checklist"
		value={this.state.checklistid}
		onChange={this.handleChangeChecklist.bind(this, "checklist")}
		>
		<option value="">Select an option</option>
		{checklist_all}
		</select>
		
        </div>
		</li>
		<li>
		<div className="form-group flexBTN" style={{marginBottom:13}}>
		<input className="btn btn-success btn-block" name="checklist_filter" type="submit" value="Filter"/>
		<input className="btn btn-primary btn-block" name="checklist_clear" onClick={this.clearChecklist.bind(this)} type="submit" value="Clear"/>
        </div>
		</li>
        </ul>
		</div>
		</div>
		</form>
      <div id="welcome">
        <div className="">
          <div className="content tableBox">
            <div className={this.state.loader_class}></div>
			<div className="headingButton">
			<div className="orgLogo-container">Report</div>
			<div className="actionButton">
			<input className={'btn btn-primary ' + this.state.button_class} name="rename_report" id="rename_report" type="submit" value="Rename Reports" onClick={this.toggleRenamePopup.bind(this,selected_checklist)} />&nbsp;
			{user_level_1==1 || user_super_admin==1 || user_level_2==1?<input className={'btn btn-danger ' + this.state.button_class} name="del_checklist" id="del_checklist" type="submit" value="Delete Selected" onClick={this.deleteChecklist.bind(this)} />:''}
			{this.state.showRenamePopup ?
			 <Rename
			  text='Click "Close Button" to hide popup'
			  closeRenamePopup={this.toggleRenameClosePopup.bind(this,selected_checklist)}
			 />
         : null
       }
			</div>
			</div>
			<div>
	<div>Total records: {this.state.checklistCount}</div>		
  <table className="table table-striped table-bordered table wice-grid report_table">
  <thead>
    <tr className="">
	  <th className=""></th>
	  <th className="">Lock</th>
      <th className="">Checklist</th>
	  <th className="">{this.state.report_name} 
	  {user_super_admin==1?<a onClick={()=>this.editColumn(organization_id,current_user)}><span className="text no_marginL"><img className="report_edit" src="/assets/images/pencil.png" width="12px" height="12px" /></span></a>:''}</th>
      <th className="">Owner</th>
      <th className="">Status</th>
	  <th className="">Date Created</th>
	  <th className="">Last Updated</th>
	  <th className="">Comments</th>
	  <th className="">Layout</th>
    </tr>
  </thead>
<tbody>
{search_table}
</tbody>
</table>

			</div>
          </div>
        </div>
      </div>
      </div>
      </div>
    );
} else {
 window.location.href = '/';
}	
}
}
export default Report;