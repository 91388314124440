import React, { Component } from 'react';
import './popup_style.css';
import axios from 'axios';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';

class Popup extends Component{
	constructor(props){
        super(props);
        this.state = {
        variable_full_name: '',
        variable_description: '',
        variable_lookup_code: '',
        variable_original_name: '',
        fields: {},
        error: {}
        }
     }
  
  
      handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
     //action 
     fields["action"]="createVariable";
	 fields["organization_id"]=organization_id;
      //organization_name
      if(!fields["variable_full_name"]){
         formIsValid = false;
         errors["variable_full_name"] = "Cannot be empty";
      }
     this.setState({errors: errors});

     return formIsValid;
    }
	
	handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
	variableSubmit(e){
	 e.preventDefault();
	 if(this.handleValidation()){
	 console.log(this.state.fields);
	 this.createVariableApi();	 
	 }else{
	 alert("Mandatory Field Emplty!");	 
	 }
	}
	
	createVariableApi()
    {
	  let session_element = localStorage.getItem("session");
	  let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;	
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           { 
              this.props.history.push('/Variables?organization='+organization_id);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
	
  render() {
    return (
      <div className='popup'>
        <div className='popup_inner'>
		<div class="modal-header">
          <h4>Add new Variable</h4>
		  </div>
		        <div class="modal-body">
				<form class="new_variable"
				 id="new_variable"
				 onSubmit= {this.variableSubmit.bind(this)}
                 acceptCharset="UTF-8" >
				<div></div>
				<div class="row">
				<label>Content Variable Name</label>
				<input 
				class="form-control"
				id="variable_full_name"
				name="variable_full_name"
				type="text"
				onChange={this.handleChangeName.bind(this, "variable_full_name")}
                value={this.state.fields["variable_full_name"]}
				required />
				</div>
				<br/>
				<div class="row">
				<label>Content Variable Code</label>
				<input
				class="form-control"
				id="variable_lookup_code"
				name="variable_lookup_code"
				type="text"
				onChange={this.handleChangeName.bind(this, "variable_lookup_code")}
                value={this.state.fields["variable_lookup_code"]}
				required />
				</div>
				<br/>
				<div class="row">
				<label>Variable Description</label>
				<input
				class="form-control"
				id="variable_description"
				name="variable_description"
				type="text"
				onChange={this.handleChangeName.bind(this, "variable_description")}
                value={this.state.fields["variable_description"]}
				required />
				</div>
				<br/>
				<div class="row">
				<label>Variable Value</label>
				<input
				class="form-control"
				id="variable_original_name"
				name="variable_original_name"
				type="text"
				onChange={this.handleChangeName.bind(this, "variable_original_name")}
                value={this.state.fields["variable_original_name"]}
				required />
				</div>
				<br/>
				<div class="row">
			    <input class="btn btn-primary" name="commit" type="submit" value="Add" />
				</div>
			    </form>
                </div>
				<div class="modal-footer">
				<button onClick={this.props.closePopup} class="btn btn-default">close</button>
				</div>
        </div>
      </div>
    );
  }
}
export default Popup;