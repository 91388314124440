import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import alasql from 'alasql';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import $ from 'jquery';
class replymessages extends Component {
    constructor(props){
        super(props);
        this.state = {
		   isLoggedIn: false,
		   messages_template: {},
		   ObjMessages: [],
		   storage_template: {},
		   messages_template: {},
		   ObjStorage: [],
		   ObjMessages: [],
		   ObjMessagesList: [],
		   ObjEachMessagesList: [],
		   ObjNotificationList: [],
		   fields: {},
		   sender_group: '',
		   sender_firstname: '',
		   sender_lastname: '',
		   render: false
        }
		this.redirectDashboard=this.redirectDashboard.bind(this);
        this.redirectPageLoad=this.redirectPageLoad.bind(this);
		this.replyMessage=this.replyMessage.bind(this);
		this.redirectComposeMessage=this.redirectComposeMessage.bind(this);
     }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  
  componentWillMount() {
  	  
  this.getMessageList();
  }
  componentDidMount(){
  
  }
  redirectDashboard(){
  this.props.history.push('/Collection');
  }
  replyMessage(messageid)
  {
	  this.props.history.push('/messages?id=' + messageid);
  }
  
  getMessageList(){
  let messages_template = this.state.messages_template;
  let ObjMessages = this.state.ObjMessages;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organization_id = session_array.organization_id;
  //localStorage.setItem('safeguard_messages', '');
  messages_template["action"]="getMessageList";
  messages_template["organization_id"]=organization_id;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.messages_template)
     })
      .then((response) => response.json())
      .then((response) => {
        const status=response.status;
        const message_list = response.message_list;
       if(status === "true")
       {
		localStorage.setItem('safeguard_messages', JSON.stringify(message_list));
		this.viewEachMessageList();
       }else{
		   localStorage.setItem('safeguard_messages', '');
	   }
 });
  }
  
  
  
  
  handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let userid = session_array.id;
	  let organizationid = session_array.organization_id;
	  const query = new URLSearchParams(this.props.location.search);
      const id = query.get('id');
     //param 
     fields["action"]="replyMessage";
	 fields["userid"]=userid;
	 fields["organizationid"]=organizationid;
	 fields["conversation_id"]=id;
      //message
      if(!fields["message"]){
         formIsValid = false;
         errors["message"] = "Messages can not be blank!";
      }
     this.setState({errors: errors});
     return formIsValid;
    }
	
  handleChangeName(field, e){         
  let fields = this.state.fields;
  fields[field] = e.target.value;        
  this.setState({fields});
  }
  messageSubmit(e){
      e.preventDefault();
      if(this.handleValidation()){
        this.replyMessageApi();
        console.log(this.state.fields);
      }else{
         alert("failed to send message, try again!");
      }
    }
  redirectComposeMessage()
  {
	  this.props.history.push('compose');
  }

redirectPageLoad()
  {
      window.location.reload();
  }
  replyMessageApi()
    {
	const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id');	
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
              this.props.history.push('/replymessages?id=' + id);
			  window.location.reload();
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
	
  
  
  
  viewEachMessageList()
  {
	  const query = new URLSearchParams(this.props.location.search);
      const id = query.get('id');
	  let safeguard_messages = localStorage.getItem("safeguard_messages");
      let safeguard_messages_array=JSON.parse(safeguard_messages);
	  let res = alasql('SELECT * FROM ? WHERE id = ?', [safeguard_messages_array.conversation,id]);
	  let metadata_group = res[0].metadata.group;
	  let sender_firstname = res[0].sender.first_name;
	  let sender_lastname = res[0].sender.last_name;
	  let notification = res[0].notification;
	  this.setState({
      ObjEachMessagesList: notification,
	  sender_group : metadata_group,
	  sender_firstname : sender_firstname,
	  sender_lastname : sender_lastname
      })
  }
 
render() {
if (localStorage.getItem('session')) {
const query = new URLSearchParams(this.props.location.search);
const id = query.get('id');
let messageList;

messageList = this.state.ObjEachMessagesList.map(v => (
        <li className={v.unread!='false' ? "byUser" : ""}>
              <div className="msg-inner">
                  <div className="msg-header">
                    <div className="msg-sender">
                        <h4>{v.sender_fullname}</h4>
                      <strong>{this.state.sender_group}</strong>
                    </div>
                    <div className="msg-time">{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(new Date(v.last_message_at).getTime())}</div>
                  </div>
                  <div className="msg-body">{v.snippet}</div>
                  <div className="clear"></div>
                </div>
          </li>
    ));

 return (
    <div id="ember361" className="ember-view ember-app">
	<div className="menu">
        <ul>
          <li className="home"><a onClick= {this.redirectDashboard}>h</a></li>
          <li className="back"><a>M</a></li>
          <li className="titlebar">Messages</li>
          <li className="refresh"><a onClick= {this.redirectPageLoad}>⟳</a></li>
          <li className="edit">
            <a>Edit</a>
          </li>
        </ul>
      </div>

	<div id="application">
	<div class="conversation">
        <ul className="messages">
		{messageList}
        <li class="replymessage">
      <form acceptCharset="UTF-8"
	   name="messageForm"
	   onSubmit= {this.messageSubmit.bind(this)}>
        <span>Reply</span>
        <input id="ember" class="ember-view ember-text-field" type="hidden" name="conversation_id" value={id} />
        <textarea id="ember"
		class="ember-view ember-text-area js-auto-size"
		name="message"
		onChange={this.handleChangeName.bind(this, "message")}
        value={this.state.fields["message"]}>
		</textarea><br/>
        <button class="message-button" data-ember-action="19887">Send Message</button>
      </form>
    </li>
        </ul>
      </div>
	  </div>
	
      </div>
    );
} else {
 window.location.href = '/';
}	
}
}
export default replymessages;