import React, { Component } from 'react';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import alasql from 'alasql';
class ViewTasks extends Component {
  constructor(props){
    super(props);
    this.state = {
	  steps_delete_template: {},	
      organization_template: {},
      viewtask_template: {},
	  collection_template: {},
	  chart_template: {},
      ObjOrganizations: '',
      ObjViewTasks: [],
	  ObjCharts: [],
	  taskCount:0,
	  createReportCount:0,
	  ObjCollection : []
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.redirectEventAids=this.redirectEventAids.bind(this);
	this.redirectPlan=this.redirectPlan.bind(this);
	this.redirectEditTasks=this.redirectEditTasks.bind(this);
	this.redirectAddTasks=this.redirectAddTasks.bind(this);
	this.editProfile=this.editProfile.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
 }

  redirectOrganization(){
  this.props.history.push('/Organization');
  }
  editProfile(user_id,organizationid)
  {
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }
  redirectDashboard(){
  this.props.history.push('/Collection');
  }
  redirectEditTasks(card_id,chart_id,organizationid,collectionid,moduleid)
  {
	  this.props.history.push('/EditTasks?organization='+organizationid+'&collectionid='+collectionid+'&card_id='+card_id+'&chartid='+chart_id+'&moduleid='+moduleid);
  }
  redirectAddTasks(organizationid,collectionid,moduleid,chartid)
  {
	  this.props.history.push('/CreateSteps?organization='+organizationid+'&collectionid='+collectionid+'&chartid='+chartid+'&moduleid='+moduleid);
  }
  redirectPlan(organizationid,moduleid){
  this.props.history.push('/collections/Collections?organization='+organizationid+'&moduleid='+moduleid);
  }
  
  redirectEventAids(organizationid,collectionid,moduleid)
  {
	this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
  }
  GotoReport()
	{
		this.props.history.push('/Report');
	}
  logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
componentWillMount(){
this.getChartList();
}  
componentDidMount() {
  this.GetOrganizationDetail();
  this.GetCollectionDetails();
  this.GetViewTaskList();
  this.getChartList();
}

getChartList(){
let chart_template = this.state.chart_template;
let ObjCharts = this.state.ObjCharts;
chart_template["action"]="detailChart";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
chart_template["organizationid"]=organizationid;
chart_template["collectionid"]=collectionid;
chart_template["chartid"]=chartid;

fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.chart_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details=[] ;
	  details.push(response.details);
	 if(status === "true")
	 {
	  this.setState({
		ObjCharts: details
	})
	 }       
});

}

GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});       
}
GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }else{
	   }
 });
 }
 
  GetViewTaskList(){
  let viewtask_template = this.state.viewtask_template;
  let ObjViewTasks = this.state.ObjViewTasks;
  viewtask_template["action"]="getViewTaskList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  const chartid = query.get('chartid');
  viewtask_template["organizationid"]=organizationid;
  viewtask_template["chartid"]=chartid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.viewtask_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const task_list = response.task_list;
		const count = response.count;
		const create_report_yes_count = response.create_report_yes_count;
         if(status === "true")
       {
        this.setState({
          ObjViewTasks: task_list,
		  taskCount: count,
		  createReportCount: create_report_yes_count
      })
       }else{
	   }
 });
 
}
 redirectDeleteSteps(cardid,chartid,organizationid,collectionid,moduleid)
{	
  if (window.confirm("Are you sure you want to delete this step?")) {
  this.deleteSteps(cardid,chartid,organizationid,collectionid,moduleid);
}
}
deleteSteps(cardid,chartid,organizationid,collectionid,moduleid)
{
let steps_delete_template = this.state.steps_delete_template;
steps_delete_template["organizationid"]=organizationid;
steps_delete_template["chartid"]=chartid;
steps_delete_template["cardid"]=cardid;
steps_delete_template["action"]="deleteChecklistSteps";
let step_array = this.state.ObjViewTasks;
delete step_array[1];
var step_final_array = step_array.filter(function (el) {
  return el != null;
});
steps_delete_template["step_array"]=step_final_array;
//console.log(JSON.stringify(step_final_array));

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.steps_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {	
	   this.GetViewTaskList();
	   this.props.history.push('/ViewTasks?action=delete&organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid+'&chartid='+chartid);
    }else{
		alert('something went wrong,please try again!');
	}
});

}
  render() {
	//console.log(this.state.ObjViewTasks);
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization'); 
	const collectionid = query.get('collectionid');
	const moduleid = query.get('moduleid');
	const chartid = query.get('chartid');
	const action = query.get('action');
	let step_count = this.state.taskCount;
	let action_message;
	if(action=='delete')
	  {
		  action_message = <div data-alert="" className="alert alert-success">Step Successfully Deleted!</div>;
	  }else if(action=='edit'){
		  action_message = <div data-alert="" className="alert alert-success">Step Successfully Edited!</div>;
	  }else{
	  }
	//console.log(JSON.stringify(this.state.ObjViewTasks));
	
	let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	
	let supporting_material_title = this.state.ObjCharts.map(v => (
	  v.supporting_materials_title
    ));
	
	let title = this.state.ObjCharts.map(v => (
	  v.title
    ));
	
	
	
	if (localStorage.getItem('session')) {
	let taskList = this.state.ObjViewTasks.map((v,index) => (
      <tr key={index}>
          <td>
            <div className="card-type">
			{ReactHtmlParser(v.content)}
			</div>
          </td>
		  <td>{v.current_step}</td>
		  <td>{v.next_step}</td>
		  <td>{v.decision_count > 0 ?<table className="table"><thead><tr><th>Decision</th><th>Destination</th></tr></thead><tbody>{v.decision_steps.map((value,index) => {
              return (
			  <tr><td><a className="btn btn-sm btn-default" key={index}>{value.content}</a></td><td>{value.destination}</td></tr>		  
              ); 
            })}</tbody></table>:''}</td>
		  <td>{v.report_status}</td>
          <td className="text-right">
            <a className="btn btn-sm btn-default" onClick={()=>this.redirectEditTasks(v.id,chartid,organizationid,collectionid,moduleid)}>Edit</a>&nbsp;
			{step_count > 1 ?<a className="btn btn-sm btn-default" onClick={()=>this.redirectDeleteSteps(v.id,chartid,organizationid,collectionid,moduleid)}>Delete</a> : ''}
          </td>
        </tr>
    ));	
    return (
	<div className="bodyscroll">		
	<div className="navbar navbar-inverse navbar-static-top" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-left">
                <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
                  <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
				  <li className=""><a onClick={this.GotoReport}>Report</a></li>
                  <li><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
               <li><a rel="nofollow" title="Log Out" onClick= {this.logout}>Log Out</a></li>
              </ul>
            </div>
          </div>
        </div>
	
	<div className="container">
          <div className="row">
            <div className="col-xs-12">
         <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={()=>this.redirectPlan(organizationid,moduleid)}>Plans</a> » <a onClick={()=>this.redirectEventAids(organizationid,collectionid,moduleid)}>{this.state.ObjCollection.title}</a> » {title}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
			{action_message}
              <h2>
{this.state.createReportCount == 0 ?<span className="pull-right"><a className="btn btn-success" onClick={()=>this.redirectAddTasks(organizationid,collectionid,moduleid,chartid)}>Add Steps</a></span>:''}
			  {title}
			  </h2>
        <table className="table table-striped card-list" id="cssTable">
        <tbody><tr>
        <th width="40%">Steps</th>
		<th width="10%" className="text-center">Current Step</th>
		<th width="10%" className="text-center">Next Step</th>
		<th width="15%" className="text-center">Decision Step</th>
		<th width="10%" className="text-center">Report Status</th>
        <th width="15%" className="text-right">Actions</th>
      </tr>
	  {taskList}
    </tbody>
  </table>
            </div>
          </div>
   
    </div>
	</div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}

export default ViewTasks;