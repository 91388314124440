import React, { Component } from 'react';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class ViewLog extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
      collection_template: {},
	  collection_delete_template: {},
	  log_template: {},
	  item_template: {},
      ObjOrganizations: '',
	  ObjOrganization: '',
      ObjCollections: [],
	  ObjLog: [],
	  ObjItem: [],
	  ObjItemNew: []
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectLogs=this.redirectLogs.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.editProfile=this.editProfile.bind(this);
 }
  editProfile(user_id,organizationid){
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }
  redirectOrganization(){
  this.props.history.push('/Organization');
  }
  redirectLogs()
  {   const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organizationid');
	  this.props.history.push('/Logs?organization='+organizationid+'');
  }
  
  redirectDashboard(){
  this.props.history.push('/Collection');
  window.location.reload();
  }

  logout() {
    if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
componentDidMount() {
  this.GetOrganizationDetail();
  this.GetLogDetails();
  this.GetItemList();
}
GetLogDetails(){
let log_template = this.state.log_template;
let ObjLog = this.state.ObjLog;
log_template["action"]="detailLog";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organizationid');
const logid = query.get('logid');
log_template["organizationid"]=organizationid;
log_template["logid"]=logid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.log_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjLog: details
	})
	 }       
});       
}
GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }       
 });
 }
 printPreiview(e)
 {
	 let date = new Date().getDate();
	 let month = new Date().getMonth() + 1;
	 let year = new Date().getFullYear();
	 let current_datetime = month+'-'+date+'-'+year;
	 
	let hours = new Date().getHours();
	hours = hours % 12;
	hours = hours ? hours : 12;
	let minute = new Date().getMinutes();
	minute = minute < 10 ? '0'+minute : minute;
	let second = new Date().getSeconds();
	let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	let now = new Date();
	let thisMonth = months[now.getMonth()];
	let dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()]
	let getCurrentAmPm = new Date().getHours() >= 12 ? 'PM' : 'AM';
	let current_datetime_full = dayName +'. '+ thisMonth +' '+ date +', '+ year +' '+ hours +':'+minute+':'+second+ ' '+getCurrentAmPm;
	 
	 
	 let divContents = document.getElementById("print_preview").innerHTML;
	 let printWindow = window.open('', '', 'height=700,width=1300');
            printWindow.document.write('<html><head><title>PREP – Printed Log – '+current_datetime+'</title>');
            printWindow.document.write('</head><body>');
			printWindow.document.write('<p>Admin(/admin) >> Organizations(/admin/organizations) >> Logs >> '+current_datetime_full+' GMT-0500(Eastern Standard Time)</p>');
            printWindow.document.write(divContents);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
 }
 
 GetItemList(){
  let item_template = this.state.item_template;
  let ObjItem = this.state.ObjItem;
  
  item_template["action"]="getItemList";
  const query = new URLSearchParams(this.props.location.search);
  const logid = query.get('logid');
  const organizationid = query.get('organizationid');
  item_template["logid"]=logid;
  item_template["organizationid"]=organizationid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.item_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const details=[] ;
	  details.push(response.item_details);
       if(status === "true")
       {
        this.setState({
          ObjItem: details
      })
       }       
 });
 
}
      render() {
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	const query = new URLSearchParams(this.props.location.search);
    const organization_id = query.get('organizationid');
	var first = this.state.ObjItem[0];
		var arr = [];
	for (var key in first) {
	  arr.push(first[key]);
	}

    let itemList = arr.map((v,index) => (
      <tr key={index}>
      <td style={{'borderBottom': '1px #dddddd solid', 'textAlign': 'left'}}>{v.time}</td>
	  <td style={{'borderBottom': '1px #dddddd solid'}}>{v.source_type}</td>
	  <td style={{'borderBottom': '1px #dddddd solid', 'width': '10px'}}></td>
	  {v.source_type=='note'?<td style={{'borderBottom': '1px #dddddd solid'}}></td>: <td style={{'borderBottom': '1px #dddddd solid'}}>{v.content}</td>}
	  <td style={{'borderBottom': '1px #dddddd solid', 'width': '10px'}}></td>
	  {v.source_type=='note'?<td style={{'borderBottom': '1px #dddddd solid'}}>{ReactHtmlParser(v.content)}</td> : <td style={{'borderBottom': '1px #dddddd solid'}}>{ReactHtmlParser(v.card_content)}</td>}
    </tr>
	   
    ));
	if (localStorage.getItem('session')) {
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard} data-original-title="">{this.state.ObjOrganizations}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
                <li className="active"><a onClick={this.redirectOrganization} data-original-title="" title="">Admin</a></li>
              <li className=""><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a onClick= {this.logout} data-method="delete"  rel="nofollow" data-original-title="" title="">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a className="" onClick={this.redirectOrganization}  data-original-title="">Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » <a onClick={this.redirectLogs}>Logs</a> » {this.state.ObjLog.time}
            </div>
          </div>
          <div className="row" id="print_preview">
            <div className="col-xs-12">
			<div className="pull-right print-button">
  <a onClick={this.printPreiview.bind(this)}><span className="glyphicon glyphicon-print"></span>Print Log</a></div>
              <h1>{this.state.ObjLog.chart_title}</h1>
              <h3>Incident Type: {this.state.ObjLog.log_type}</h3>
  <table className="table table-striped">
  <thead>
  <tr>
    <th style={{'textAlign': 'left'}}>Time</th>
	<th style={{'textAlign': 'left'}}>Source</th>
	<th style={{'textAlign': 'left', 'width': '10px'}}></th>
	<th style={{'textAlign': 'left'}}>Source Type</th>
	<th style={{'textAlign': 'left', 'width': '10px'}}></th>
	<th style={{'textAlign': 'left'}}>&nbsp;Destination</th>
  </tr>
  </thead>
  <tbody>
  {itemList}
</tbody>
</table>
</div>
</div>  
</div>
</div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default ViewLog;