import React,{Component} from 'react';
import Home from './Home';
import { BrowserRouter as Router, NavLink, Switch, Link, Route } from 'react-router-dom';
import Collection from './Collection';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Login extends Component{

    constructor(){
        super();
        this.state = {
          useremail:'',
          userpassword:'',
          isUser:'abc',
          isDialogVisible: false,
		  messages_template : {},
		  email_template : {},
		  ObjEmail : [],
          collections:'',
          email:'',
          id:'',
          school:'',
		  loader: '',
		  loader_class: '',
          fields: {},
          errors: {}
        }
     }


     handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
      
       fields["action"]="userLogin";
      
        //Email
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Cannot be empty";
        }
      
        if(typeof fields["email"] !== "undefined"){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');
      
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Email is not valid";
            }
       }  


        //password
        if(!fields["password"]){
            formIsValid = false;
            errors["password"] = "Cannot be empty";
         }
		 fields["device_type"]='';
	     fields["device_token"]='';
      
       this.setState({errors: errors});

       return formIsValid;
      }
      
      contactSubmit(e){
        e.preventDefault();
		this.setState({
        loader_class: 'loader'
        })
        if(this.handleValidation()){
         this.LoginApi();
        }else{
		   this.setState({
           loader_class: ''
           })	
           alert("Sorry, Wrong Username or Password");
        }
      }
      
      handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
      }
	  forgotPassword()
	  {
		  let email = prompt("Enter your email to have your password reset and instructions on setting a new one sent to your email:", "");
		  if (email != null) {
		var data={
                "action": "passwordEmail",
                "email": email     
                }
		  fetch(global.api_url,
         {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(data)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
		const text=response.text;
		const code=response.code;
       if(code === "200")
       {
		alert('Password reset link has been sent to your email address');
		window.location.reload();
       }else if(code === "400"){
		 alert('Something went wrong, please try again!');
	   }else{
		   alert(text);
	   }
 });
			
			
		  }
	  }
  getMessageList(){
  let messages_template = this.state.messages_template;
  let ObjMessages = this.state.ObjMessages;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organization_id = session_array.organization_id;

  messages_template["action"]="getMessageList";
  messages_template["organization_id"]=organization_id;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.messages_template)
     })
      .then((response) => response.json())
      .then((response) => {
        const status=response.status;
        const message_list = response.message_list;
       if(status === "true")
       {
		localStorage.setItem('safeguard_messages', JSON.stringify(message_list));  
       }else{
		   localStorage.setItem('safeguard_messages', '');
	   }
 });
  }
      LoginApi()
      {
        fetch(global.api_url,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'                 
            },
            body: JSON.stringify(this.state.fields)
        })
        .then((response) => response.json())
        .then((response) => {
             const status=response.status;
			 const status_message = response.message;
             if(status === "true")
             {
				this.setState({
                loader_class: ''
                }) 
				localStorage.setItem('session', JSON.stringify(response.user_details));
				localStorage.setItem('safeguard', JSON.stringify(response.safeguard));
				localStorage.setItem('organization_details', JSON.stringify(response.organization_details));
				localStorage.setItem('drill_log', '');
				localStorage.setItem('lastIndex', '');
				localStorage.setItem('preIndex', '');
				localStorage.setItem('organization_name', JSON.stringify(response.organization_name));
                this.getMessageList();
                this.props.history.push('/Collection');
             }
             else{
				this.setState({
                loader_class: ''
                }) 
                alert(status_message);
             }
        });
      }

    render(){
		if (localStorage.getItem('session')) {
        
		window.location.href = '/Collection';
		}else {
        return(
            <div id="ember361"  className="ember-view ember-app"><div  className="login">
            <h2>Safeguard Enterprise Solution</h2>
            <form name="contactform" onSubmit= {this.contactSubmit.bind(this)}>
              <input 
               id="email" 
                className="ember-view ember-text-field logtxt" 
               placeholder="Email Address"
               autoCapitalize="off"
               autoCorrect="off"
               type="text"
               onChange={this.handleChange.bind(this, "email")}
               value={this.state.fields["email"]}
              />
              <input
                id="password" 
                 className="ember-view ember-text-field passwordtxt"
                placeholder="Password" 
                type="password"
		
                onChange={this.handleChange.bind(this, "password")}
                value={this.state.fields["password"]}
                />
              <button id="login">Sign In</button>
			  <div className={this.state.loader_class}></div>
            </form>          
            <a  className="forgot" onClick= {this.forgotPassword} data-ember-action="1538">Forgot your password?</a>
          </div>
          <div className="watermark"></div>
          
          </div>  
        );
}	
    }
}

export default Login;