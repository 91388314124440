import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'

class EditModules extends Component {
    constructor(props){
        super(props);
        this.state = {
        collection_title:'',
        collection_active: 'false',
        collection_locked: 'false',  
        ObjOrganizations: [],
		ObjCollection: [],
        fields: {},
		color_theme: 0,
		showAlert: false,
		alertDescription: '',
        organization_template: {},
		collection_template: {},
        error: {}

        }
		this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.editProfile=this.editProfile.bind(this);
		this.redirectPlan=this.redirectPlan.bind(this);
		this.redirectCancelPlan=this.redirectCancelPlan.bind(this);
		this.GotoReport=this.GotoReport.bind(this);
     }
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
     editProfile(user_id,organizationid)
     {
     this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	 redirectPlan(e)
     {
	  this.props.history.push('/modules/Modules?organization='+ e);
     }
	 GotoReport()
	{
		this.props.history.push('/Report');
	}
	 redirectCancelPlan()
     {
	      const query = new URLSearchParams(this.props.location.search);
          const organizationid = query.get('organization');
		  this.props.history.push('/collections/Collections?organization='+ organizationid);
     }
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  const collectionid = query.get('module');
	  
     //action 
     fields["action"]="editModule";
     fields["organizationid"]=token;
	 fields["collectionid"]=collectionid;
	 
	 let collection_title = document.getElementById('collection_title').value;
     let collection_active_isChecked = document.getElementById('collection_active').checked;
     //let collection_locked_isChecked = document.getElementById('collection_locked').checked;
     fields["collection_active"] = collection_active_isChecked;
     //fields["collection_locked"] = 'false';
	 fields["logo_id"]= this.state.color_theme;
	 
      //organization_name
      if(collection_title==''){
         formIsValid = false;
         errors["collection_title"] = "Cannot be empty";
      }else{
     fields["collection_title"]=collection_title;
      }
     this.setState({errors: errors});

     return formIsValid;
    }
    
    collectionSubmit(e){
      e.preventDefault();
    
      if(this.handleValidation()){
		  console.log(this.state.fields);
        this.editCollectionApi();
        
      }else{
         alert("Mandatory Field Emplty!");
      }
    
    }
	handleWeekdayChange = (event) => {
    this.setState({color_theme: event.target.value})
  }
    handleCollectionActive(field, e){         
      let fields = this.state.fields;
      let isChecked = e.target.checked;
      fields[field] = isChecked;     
      this.setState({fields});
    }
    handleCollectionLocked(field, e){         
      let fields = this.state.fields;
      let isChecked = e.target.checked;
      fields[field] = isChecked;     
      this.setState({fields});
    }
    handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
	redirectOrganization(){
    this.props.history.push('/Organization');
    }
    
	    GetModuleDetails(){
        let collection_template = this.state.collection_template;
        let ObjCollection = this.state.ObjCollection;
        collection_template["action"]="detailModule";
        const query = new URLSearchParams(this.props.location.search);
        const organizationid = query.get('organization');
        const collectionid = query.get('module');
        collection_template["organizationid"]=organizationid;
        collection_template["collectionid"]=collectionid;
        fetch(global.api_url,
           {
               method: 'POST',
               headers: {
                   Accept: 'application/json',
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify(this.state.collection_template)
           })
            .then((response) => response.json())
            .then((response) => { 
              const status=response.status;
              const details = response.details;
             if(status === "true")
             {
              this.setState({
                ObjCollection: response.details,
				color_theme: details.logo_id
            })
             }       
       });
       
   } 
	
    GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {
            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }
    
    editCollectionApi()
    {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  const collection = query.get('plan');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {
           const status=response.status;
		   const code=response.code;
		   const description=response.description;
           if(status === "true" && code ==="200")
           {
              this.props.history.push('/modules/Modules?organization='+ token +'&action=edit');
           }
           else{
              this.setState({
			  showAlert : true,
			  alertDescription : description
			  })
           }
      });
    }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  componentDidMount() {
    this.GetOrganizationDetail();
	this.GetModuleDetails();
  }

  render() {
	let {color_theme} = this.state;  
	let collection_active_checkbox;
    let collection_locked_checkbox;
    let collection_active_checkbox_val = this.state.ObjCollection.active;
    let collection_locked_checkbox_val = this.state.ObjCollection.locked;
	
	  let sessiondata= localStorage.getItem("session");
	  let sdata=JSON.parse(sessiondata);
	  let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	  
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	
	if(collection_active_checkbox_val == 1){
      collection_active_checkbox = (
  <input
  type="checkbox"
  defaultChecked="true"
  id="collection_active"
  name="collection_active" />
       );
    }else if(collection_active_checkbox_val == 0){
      collection_active_checkbox = (
        <input
        type="checkbox"
        id="collection_active"
        name="collection_active" />
             );
    }else{}
	
	if(collection_locked_checkbox_val == 1){
      collection_locked_checkbox = (
  <input
  type="checkbox"
  defaultChecked="true"
  id="collection_locked"
  name="collection_locked" />
       );
    }else if(collection_locked_checkbox_val == 0){
      collection_locked_checkbox = (
        <input
        type="checkbox"
        id="collection_locked"
        name="collection_locked" />
             );
    }else{
  
    }
	
	
	if (localStorage.getItem('session')) { 
    return (
    <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
            <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			<li className=""><a onClick={this.GotoReport}>Report</a></li>
            <li><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
            <li><a data-confirm="Are you sure you want to log out?" onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » <a onClick={()=>this.redirectPlan(organizationid)}>Module</a> » {this.state.ObjCollection.title}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Edit Module</h2>
		  {this.state.showAlert?
		  <div class="alert alert-danger">{this.state.alertDescription}</div>
		  :null}
  
  <form acceptCharset="UTF-8"
  onSubmit= {this.collectionSubmit.bind(this)}
  className="new_collection"
  id="new_collection">
  <div className="form-group">
  <label htmlFor="collection_title">Title</label>:
  <input className="form-control"
    placeholder="Title"
     id="collection_title" 
     name="collection_title" 
     type="text"
     onChange={this.handleChangeName.bind(this, "collection_title")}
     defaultValue ={this.state.ObjCollection.title} 
     />
    </div>
    <div className="form-group">
    {collection_active_checkbox}
      <label htmlFor="collection_active">&nbsp;Active</label>
    </div>
	
	<hr/>
	
	<h4>Module Icons</h4>
	
	<div className="form-group">
	<div className="tablePage">
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="13" checked={color_theme === '13'} />
	<img src="/assets/images/module_icons/office.png" alt="office" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="10" checked={color_theme === '10'} />
	<img src="/assets/images/module_icons/legal.png" alt="legal" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="15" checked={color_theme === '15'} />
	<img src="/assets/images/module_icons/travel.png" alt="travel" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="17" checked={color_theme === '17'} />
	<img src="/assets/images/module_icons/bar.png" alt="bar" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="6" checked={color_theme === '6'} />
	<img src="/assets/images/module_icons/education.png" alt="education" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="11" checked={color_theme === '11'} />
	<img src="/assets/images/module_icons/manufacturing.png" alt="manufacturing" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="16" checked={color_theme === '16'} />
	<img src="/assets/images/module_icons/violation.png" alt="violation" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="8" checked={color_theme === '8'} />
	<img src="/assets/images/module_icons/inventory.png" alt="inventory" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="12" checked={color_theme === '12'} />
	<img src="/assets/images/module_icons/medical.png" alt="medical" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="14" checked={color_theme === '14'} />
	<img src="/assets/images/module_icons/safety.png" alt="safety" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="9" checked={color_theme === '9'} />
	<img src="/assets/images/module_icons/it.png" alt="it" />
	</div>
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="18" checked={color_theme === '18'} />
	<img src="/assets/images/module_icons/auto.png" alt="auto" />
	</div>
	
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="19" checked={color_theme === '19'} />
	<img src="/assets/images/module_icons/video.png" alt="video" />
	</div>
	
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="20" checked={color_theme === '20'} />
	<img src="/assets/images/module_icons/open.png" alt="open" />
	</div>
	
	<div>
	<input id="chart_theme" name="chart_theme" type="radio" className="group_collection" onChange={this.handleWeekdayChange.bind(this)} value="21" checked={color_theme === '21'} />
	<img src="/assets/images/module_icons/rules.png" alt="rules" />
	</div>
	
	
	
	
	
	</div>
	</div>
	
  <hr/>
    <div className="form-group">
      <input className="btn btn-success" name="commit" type="submit" value="Save"/>&nbsp;
      <a className="btn btn-default" onClick={()=>this.redirectPlan(organizationid)}>Cancel</a>
    </div>
  <hr/>
  </form>
          </div>
        </div>
      
      </div>
    
  
  </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}

export default EditModules;
