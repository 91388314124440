import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import axios from 'axios';
import importcsv from '../../../download/sample_import.csv';
import Papa from 'papaparse';
import $ from 'jquery';

class ImportAutoCSV extends Component {
constructor(props){
super(props);
this.state = {
name: '',
ObjOrganizations: [],
ObjFetchFileList: [],
ObjOrganizationsList: [],
csvfile: undefined,
fields: {},
file_delete_template: {},
organization_template: {},
upload_autoimport: null,
file_attachment_file_folder_id: '',
upload_filename: '',
upload_date: '',
folder_template: {},
error: {},
selectedFile: null,
pathname:null,
}
this.redirectOrganization=this.redirectOrganization.bind(this);
this.redirectDashboard=this.redirectDashboard.bind(this);
this.redirectUser=this.redirectUser.bind(this);
this.editProfile=this.editProfile.bind(this);
this.GotoReport=this.GotoReport.bind(this);
}
     
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 editProfile(user_id,organizationid)
     {
     this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	 GotoReport()
	  {
	  this.props.history.push('/Report');
	  }
	 redirectUser(){
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
      this.props.history.push('/users/User?organization='+ token); 
    }
	 handleChange = event => {
    this.setState({
      csvfile: event.target.files[0]
    });
  };
    redirectOrganization(){
    this.props.history.push('/Organization');
    }
    
GetOrganizationDetail(){
let organization_template = this.state.organization_template;
let ObjOrganizations = this.state.ObjOrganizations;
organization_template["action"]="getOrganizationDetails"; 
let session = localStorage.getItem("session");
let session_array=JSON.parse(session);
let organizationid = session_array.organization_id;
organization_template["organizationid"]=organizationid; 
fetch(global.api_url,
 {
	 method: 'POST',
	 headers: {
	 'Accept': 'application/json',
	 'Content-Type': 'application/json'
	 },
	 body: JSON.stringify(this.state.organization_template)
 })
  .then((response) => response.json())
  .then((response) => { 
	const status=response.status;
	const name = response.name;
   if(status === "true")
   {

	this.setState({
	  ObjOrganizations: response.name
  })
   }       
});
}
	
GetOrganizationList(){
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');	
let organization_template = this.state.organization_template;
let ObjOrganizations = this.state.ObjOrganizations;
organization_template["action"]="getOrganizationTemplate1";
organization_template["id"]=organizationid; 
fetch(global.api_url,
 {
	 method: 'POST',
	 headers: {
		 Accept: 'application/json',
		 'Content-Type': 'application/json'
	 },
	 body: JSON.stringify(this.state.organization_template)
 })
  .then((response) => response.json())
  .then((response) => { 
	const status=response.status;
	const template = response.template;
   if(status === "true")
   {
	this.setState({
	  ObjOrganizationsList: response.template
  })
   }       
});
}
handleValidation(){
let fields = this.state.fields;
let errors = {};
let formIsValid = true;
const query = new URLSearchParams(this.props.location.search);
const token = query.get('organization');
let selected_organizationid = document.getElementById("ddlorganization");

this.setState({errors: errors});
return formIsValid;
}
fileSubmit(e){
e.preventDefault();
const query = new URLSearchParams(this.props.location.search);
const token = query.get('organization');
let selected_organizationid = $("select.organization").children("option:selected").val();
const data = new FormData();
data.append('upload_autoimport', this.state.selectedFile);
console.log(data);
axios.post("https://expressapi.safeguardses.com/uploadautoimportdata", data, {
})
.then(res => {
 let fields = this.state.fields;
  fields["action"] = 'saveAutoImportFile';
  fields["filepath"] = res.data.path;
  fields["organization_id"] = selected_organizationid;
  console.log(this.state.fields);
  this.saveAutoImportFile();
})
}

saveAutoImportFile()
    {
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {
           const status=response.status;
		   const upload_filename=response.filename;
		   const upload_date=response.date;
		   const organization_id=response.organization_id;
           if(status === "true")
           {
             let fields = this.state.fields;
	         fields["action"] = 'fetchAutoImportFile';
             fields["organization_id"] = organization_id;
			 
			 fetch(global.api_url,
				  {
					  method: 'POST',
					  headers: {
						  Accept: 'application/json',
						  'Content-Type': 'application/json'                 
					  },
					  body: JSON.stringify(this.state.fields)
				  })
				  .then((response) => response.json())
				  .then((response) => {
					   const status=response.status;
					   const file_details=response.file_details;
					   if(status === "true")
					   { 
					     $('#upload_autoimport').val('');
						 this.setState({
						  ObjFetchFileList: file_details
						 })
					   }
					   else{
						  alert("Something went wrong, please try again");
					   }
				  });
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }

handleChangeName(field, e){ 
	  let fields = this.state.fields;
	  fields["action"] = 'fetchAutoImportFile';
      fields["organization_id"] = e.target.value;
	  
	  fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {
           const status=response.status;
		   const file_details=response.file_details;
           if(status === "true")
           { 
			 this.setState({
              ObjFetchFileList: file_details
             })
           }
           else{
              alert("Something went wrong, please try again");
           }
      });

    }
logout() {
if (window.confirm("Are you sure you want to log out?")) {
localStorage.clear();
window.location.href = '/';
}
}
componentDidMount() {
this.GetOrganizationDetail();
this.GetOrganizationList();
}

deleteAutoImportFile(id,organization_id)
{
  if (window.confirm("Are you sure you want to delete this file?")) {
  this.deleteFinalAutoImportFile(id,organization_id);
}
}

deleteFinalAutoImportFile(id,organization_id)
{ 
let file_delete_template = this.state.file_delete_template;
file_delete_template["id"]=id;
file_delete_template["action"]="deleteAutoImportFile";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.file_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	         let fields = this.state.fields;
	         fields["action"] = 'fetchAutoImportFile';
             fields["organization_id"] = organization_id;
			 
			 fetch(global.api_url,
				  {
					  method: 'POST',
					  headers: {
						  Accept: 'application/json',
						  'Content-Type': 'application/json'                 
					  },
					  body: JSON.stringify(this.state.fields)
				  })
				  .then((response) => response.json())
				  .then((response) => {
					   const status=response.status;
					   const file_details=response.file_details;
					   if(status === "true")
					   { 
						 this.setState({
						  ObjFetchFileList: file_details
						 })
					   }
					   else{
						  alert("Something went wrong, please try again");
					   }
				  });
    }else{
		alert("Something went wrong, please try again");
	}
});
}


uploadFiles(field, e){
this.setState({
selectedFile: e.target.files[0],
loaded: 0,
})
let fields = this.state.fields;
switch (e.target.name) {
case 'upload_autoimport':
if(e.target.files.length > 0) {
this.setState({ fileName: e.target.files[0].name });
fields[field] = e.target.files[0].name;
this.setState({fields});
}
break;
default:
this.setState({ [e.target.name]: e.target.value });
} 
}
    render() {
      let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;		
	let optionTemplate = this.state.ObjOrganizationsList.map(v => (
      <option value={v.id}>{v.name}</option>
    ));
	
	let fetchFile = this.state.ObjFetchFileList.map(v => (
	  <tr><td>{v.file_name}</td><td>{v.created_date}</td><td>{v.status==1? 'Read' : 'Unread'}</td><td><a onClick={()=>this.deleteAutoImportFile(v.id,v.organization_id)} data-method="DELETE" rel="nofollow">Delete</a></td></tr>
    ));
	
	if (localStorage.getItem('session')) {
    return (
    <div className="bodyscroll">
	
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div  className="navbar-collapse collapse">
        <ul  className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard} data-original-title="" title="">{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul  className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			  <li className=""><a onClick={this.GotoReport}>Report</a></li>
            <li><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a className="" onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectUser}>Users</a>
          </div>
        </div>
          <div className="row">
          <div className="col-xs-12">
          <h1>File Upload</h1>
		  <p>Download sample csv file <a href={importcsv}>Click here</a></p>
		  <span className="shortText">(If you are using Safari as your web browser, RIGHT click on the link above and click 'Download Linked File')</span>
	        <div className="csvForm">
			<form acceptCharset="UTF-8" 
			className="auto_import"
			id="auto_import"
			onSubmit= {this.fileSubmit.bind(this)}>
			<table className="auto_import_table" border="1" cellPadding="3" cellSpacing="0">
			<tbody>
			<tr>
			<td> Organization Name: </td>
			<td><select
			name="ddlorganization"
			id="ddlorganization"
			className="organization"
			ref = {(input)=> this.menu = input}
            value={this.state.fields["ddlorganization"]}
            onChange={this.handleChangeName.bind(this, "ddlorganization")}
			>
			{optionTemplate}
			</select></td>
			</tr>
			<tr>
			<td><label htmlFor="upload_file">Select File</label></td>
			<td>
			<input 
			
			id="upload_autoimport" 
			name="upload_autoimport" 
			type="file" 
            onChange={this.uploadFiles.bind(this, "upload_autoimport")} />
			</td>
			</tr>
			<tr>
			<td></td>
			<td><button >Upload</button></td>
			</tr>
			</tbody>
			</table>
			</form>
	        </div>
		    <div>

  <br/>
  <h2>Uploaded CSV File List</h2>
  <table border="1" className="tabledoc">
  <thead>
  <tr>
  <th>&nbsp;Document Name&nbsp;</th>
  <th>&nbsp;Date Time&nbsp;</th>
  <th>&nbsp;Status&nbsp;</th>
  <th>&nbsp;Action&nbsp;</th>
  </tr>
  </thead>
  <tbody>{fetchFile}</tbody>
  </table>
  </div>
		
		
          </div>
        </div>
      </div>
    </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default ImportAutoCSV;