import React, { Component } from 'react';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import alaSQLSpace from 'alasql';
class earthquake extends Component {
    constructor(props){
        super(props);
        this.state = {
		   fields: {},
		   flowchart_template: {},
		   title: '',
		   flowchart_status: '',
		   $flowchart_content: '',
		   ObjOrganization: ''
        }
		this.Gotoorg = this.Gotoorg.bind(this);
		this.GotoHelp = this.GotoHelp.bind(this);
		this.GotoHome = this.GotoHome.bind(this);
		this.gotoBack = this.gotoBack.bind(this);
		this.GotoDoc = this.GotoDoc.bind(this);
		this.GotoProfile = this.GotoProfile.bind(this);
		this.redirectChart = this.redirectChart.bind(this);
     }
componentDidMount(){
this.getFlowChart();
this.getOrganizationList();
}
Gotoorg() {
		this.props.history.push('/Organization');
	}
	GotoHelp() {
		this.props.history.push('/help');
	}

	GotoHome() {
		this.props.history.push('/Collection');
	}
	gotoBack() {   
		this.props.closeFlowChartView();
	}
	GotoDoc() {
		this.props.history.push('/Doc');
	}

	GotoProfile() {
		this.props.history.push('/Profile');
	}
	logout() {
		if (window.confirm("Are you sure you want to log out?")) {
			localStorage.clear();
			window.location.href = '/';
		}
	}
	getOrganizationList()
     {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
      }
redirectChart(e){
  e.preventDefault();
  document.getElementById('chart_application').parentNode.style.overflow = 'visible';
  const input = document.getElementById('chart_application');
	input.style.top = window.innerHeight + 'px';
        html2canvas(input, {
        logging: true, letterRendering: 1, allowTaint: false, useCORS: true, dpi: 200, height: 1000})
      .then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
        input.style.top = 0;
		/*const u8Image  = this.b64ToUint8Array(imgData);*/
		const imgageData = imgData.replace(/^data:image\/(png|jpg);base64,/, "");
		/*const output = encodeURIComponent(imgData);*/
		/*const newData = imgData.replace(/^data:image\/png/, "data:application/octet-stream");*/
		const chartid = localStorage.getItem("chartid");
		
		let safeguard = localStorage.getItem("safeguard");
		let safeguarddata = JSON.parse(safeguard);
		let charts = safeguarddata.charts;
		let res = alaSQLSpace('SELECT * FROM ? where id = ?', [charts, chartid]);
		let chart_name = res[0].title;
		chart_name = chart_name.toLowerCase();
		chart_name = chart_name.replace(/:\s*/g, " ");
		chart_name=chart_name.replace(/\//g,"");
		chart_name=chart_name.replace(/\s+/g,"_");
		/*canvas.setAttribute('width',900);
        canvas.setAttribute('height',1000);*/
		var link = document.createElement("a");
        /*link.download = 'chart_'+chartid+'.png';*/
		link.download = 'chart_'+chart_name+'.png';
        link.href = imgData;
        link.click();
	
		
        /*const data = new FormData();
		data.append("chartid", chartid);
		data.append("data", imgageData);
		var xhr = new XMLHttpRequest();
        xhr.open( 'post', 'http://3.89.110.194/pdf.php', true );
		xhr.send(data);*/
        /*window.location.reload();*/
      });
  }	  
getFlowChart()
{ 
	const collectionid = this.props.collectionid;
	const chartid = this.props.chartid;
	const organizationid = this.props.organizationid;
let flowchart_template = this.state.flowchart_template;
flowchart_template["action"]="earthquake";
flowchart_template["collectionid"]=collectionid;
flowchart_template["chartid"]=chartid;
flowchart_template["organizationid"]=organizationid;
fetch(global.flowchart_api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.flowchart_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
	 const html=response.html;
	 const title=response.title;
    if(status === "true")
    {
	   this.setState({
		  flowchart_content: html,
		  title: title,
		  flowchart_status: status
      })
    }       
});
}
 render() {
	 let token = localStorage.getItem("session");
		let token_array=JSON.parse(token);
		let user_level_2 = token_array.admin;
		let user_level_1 = token_array.organization_admin;
		let user_super_admin = token_array.super_admin;
		
		const flowstatus = this.state.flowchart_status;
		let flowcontent;
		if(flowstatus=='true')
		{
		flowcontent = <ul class="cards">{ReactHtmlParser(this.state.flowchart_content)}
		<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '147px', 'top': '4.5%', 'width': '4px', 'height': '23%'}}>
    <div class="rightarrow"></div></div>
	<div class="nub-lines lines-left" style={{'position': 'absolute', 'left': '296px', 'top': '16.1%', 'width': '4px', 'height': '8%'}}>
	<div class="rightbottomarrow"></div></div>
	<div class="nub-lines lines-left" style={{'position': 'absolute', 'left': '296px', 'top': '16.1%', 'width': '4px', 'height': '175.81px'}}>
	<div class="rightbottomarrow"></div></div>
	<div class="nub-lines lines-right" style={{'position': 'absolute', 'left': '447px', 'top': '4.3%', 'width': '4px', 'height': '4%'}}>
	<div class="rightarrow"></div></div>
	<div class="nub-lines lines-bottom" style={{'position': 'absolute', 'left': '373px', 'top': '19.5%', 'width': '4px', 'height': '27px'}}></div>
	<div class="nub-lines lines-top" style={{'position': 'absolute', 'left': '373px', 'top': '33.8%', 'width': '4px', 'height': '27px'}}></div>
	<div class="nub-lines lines-left-bottom" style={{'position': 'absolute', 'left': '447px', 'top': '38%', 'width': '4px', 'height': '110.672px'}}>
	<div class="leftbottomarrow"></div></div></ul>;
		}else{
		flowcontent = '';	
		}
		
 return (
    <div id="ember361" class="ember-view ember-app">
	<div className="menu">
	<ul>
		<li className="homeicon"><a onClick={this.GotoHome}>h</a></li>
		<li className="back"><a onClick={this.gotoBack}>M</a></li>
		<li className="titlebar">{this.state.ObjOrganization}</li>
		<li className="logout"><a onClick={this.logout}>o<span className="text">Logout</span></a></li>
		<li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>
		<li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
		<li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
		{user_level_1==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
	</ul>
</div>
<div id="application">
  <div class="overlay-container" id="chart_application">
  <div class="overview-controls">
    <h2 class="overview-title">{this.state.title}</h2>
    <a class="close" data-ember-action="1447" onClick={this.gotoBack}>x</a>
  </div>
  <div class="overview-scroller">
    <div id="ember1885" class="ember-view overview">
      {flowcontent}
	</div>
  </div>
  <div class="clear"></div>
</div>
</div>
</div>
);
}
}
export default earthquake;