import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import axios from 'axios';

class EditFile extends Component {
    constructor(props){
        super(props);
        this.state = {
        name: '',
        ObjOrganizations: [],
        ObjFolder: [],
		ObjFile: [],
        fields: {},
		optionsState: 0,
        organization_template: {},
		file_attachment_attachment: null,
		file_attachment_file_folder_id: '',
        folder_template: {},
		file_template: {},
        error: {},
        selectedFile: null,
        pathname:null,

        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.redirectAttachment=this.redirectAttachment.bind(this);
		this.GotoReport=this.GotoReport.bind(this);
     }
     
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 redirectAttachment()
	  {
		  const query = new URLSearchParams(this.props.location.search);
          const organizationid = query.get('organization');
		  this.props.history.push('/Attachment?organization='+organizationid);
	  }
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
	  const fileid = query.get('folderid');
	  //let file_attachment_cache_on_app_isChecked = document.getElementById('file_attachment_cache_on_app').checked;
	  let selected_folder = document.getElementById("file_attachment_file_folder_id");
	  //let selected_folderid = selected_folder.options[selected_folder.selectedIndex].value;
	  fields["file_attachment_cache_on_app"] = 'false';
	  //fields["file_attachment_file_folder_id"] = selected_folderid;
	  
	  if (this.refs.file_folderid) {
       fields["file_attachment_file_folder_id"]=this.refs.file_folderid.value;
    }
	  if(this.refs.file_folderid.value=='')
	  {
	   fields["file_attachment_file_folder_id"] = 'false'
	  }else{
		  fields["file_attachment_file_folder_id"]=this.refs.file_folderid.value;
	  }
	  
     //action 
     fields["action"]="editFile";
     fields["organizationid"]=token;
	 fields["fileid"]=fileid;
    this.setState({errors: errors});
     return formIsValid;
    }
    redirectOrganization(){
      this.props.history.push('/Organization');
      }

    GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {

            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }
      GotoReport()
	  {
		this.props.history.push('/Report');
	  }
      GetFolderList(){
      let folder_template = this.state.folder_template;
      let ObjFolder = this.state.ObjFolder;
      folder_template["action"]="getAddedFolderList";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
      folder_template["organizationid"]=organizationid;
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.folder_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const folder_details = response.list;
           if(status === "true")
           {
            this.setState({
              ObjFolder: folder_details
          })
           }       
     });
    }
	
	  GetFileDetails(){
      let file_template = this.state.file_template;
      let ObjFile = this.state.ObjFile;
      file_template["action"]="detailFile";
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const folderid = query.get('folderid');
      file_template["organizationid"]=organizationid; 
	  file_template["folderid"]=folderid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.file_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const file_details = response.file_details;
           if(status === "true")
           {
            this.setState({
              ObjFile: response.file_details,
			  optionsState: response.file_details.file_folder_id
          })
           }       
     });
 }
 
    fileSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
		console.log(this.state.fields);
		this.editFileApi();
	}
	}
    
    editFileApi()
    {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('organization');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
              this.props.history.push('/Attachment?success=true&organization='+ token);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }

logout() {
if (window.confirm("Are you sure you want to log out?")) {
localStorage.clear();
window.location.href = '/';
}
}
  componentDidMount() {
    this.GetOrganizationDetail();
    this.GetFolderList();
	this.GetFileDetails();
  }
  handleChangeName(field, e){
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
  render() {
	let selected_file_folderid = this.state.optionsState;
	let folderList = this.state.ObjFolder.map(v => (
	 <option value={v.id} selected={selected_file_folderid == v.id}>{v.name}</option>
    ));
	//let file_attachment_cache_on_app;
	//let file_attachment_cache_on_app_val = this.state.ObjFile.cache_on_app;
	
	
	if (localStorage.getItem('session')) {
    return (
        <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span  className="sr-only">Toggle navigation</span>
          <span  className="icon-bar"></span>
          <span  className="icon-bar"></span>
          <span  className="icon-bar"></span>
        </button>
      </div>
      <div  className="navbar-collapse collapse">
        <ul  className="nav navbar-nav navbar-left">
          <li><a  className="home1" onClick={this.redirectDashboard} data-original-title="" title="">{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul  className="nav navbar-nav navbar-right">
              <li  className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			  <li className=""><a onClick={this.GotoReport}>Report</a></li>
            <li  className=""><a data-original-title="" title="">Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div  className="container">
        <div  className="row">
          <div  className="col-xs-12">
          <a  className="" onClick={this.redirectOrganization} data-original-title="" title="">Admin</a> » <a  className="" onClick={this.redirectOrganization} data-original-title="" title="">Organization</a> » <a onClick={this.redirectAttachment}>Supporting Files</a>
          </div>
        </div>
        <div  className="row">
          <div  className="col-xs-12">
          <h2>Edit Supporting File</h2>
  
  <form acceptCharset="UTF-8"
  onSubmit= {this.fileSubmit.bind(this)}
   className="new_file_folder"
  id="new_file_folder">
    <p><strong>File name:</strong>&nbsp;{this.state.ObjFile.attachment}</p>
    <div  className="form-group">
      <label for="file_attachment_file_folder_id">File folder</label>&nbsp;
      <select
	  id="file_attachment_file_folder_id"
	  name="file_attachment_file_folder_id"
	  ref="file_folderid"
	  onChange={this.handleChangeName.bind(this, "file_attachment_file_folder_id")}
	  >
	  <option value="">None</option>
	  {folderList}
	  </select>
    </div>
    
    <div  className="form-group">
      <input  className="btn btn-success" name="commit" type="submit" value="Save" />&nbsp;
	  <a class="btn btn-default" onClick={this.redirectAttachment}>Cancel</a>
    </div>
    
  </form>
          </div>
        </div>
      </div>
    </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default EditFile;