import React, { Component } from 'react';
import Select from 'react-select';
import Popup from '../../components/Popup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FilePond, registerPlugin } from 'react-filepond';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';

class Variables extends Component {
    constructor(props){
        super(props);
        this.state = {
        ObjOrganizations: [],
		ObjVariables: [],
        fields: {},
        organization_template: {},
		variable_template: {},
		variable_delete_template: {},
		ObjOrganization: '',
		showPopup: false,
        error: {}

        }
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.redirectOrganization=this.redirectOrganization.bind(this);
		this.editProfile=this.editProfile.bind(this);
     }
     
	 editProfile(user_id,organizationid){
	 this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
     redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 redirectOrganization(){
     this.props.history.push('/Organization');
     }
	 
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  
	  const query = new URLSearchParams(this.props.location.search);
      const organization_id = query.get('organization');
	  
	  
	  //let organization_id = session_array.organization_id;
    
	 //action 
	 fields["action"]="updateVariable";
	 fields["organization_id"]=organization_id;
	 
	 let variable_rows = [];
	 var divs = document.getElementsByClassName("variable-row");
	 Array.prototype.forEach.call(divs, function(e) {
	 var divid = e.id;
	 var original_name = document.getElementById('original_name_'+divid).value;
	 var lookup_code = document.getElementById('lookup_code_'+divid).value;
	 var description = document.getElementById('description_'+divid).value;
	 var full_name = document.getElementById('full_name_'+divid).value;
	 variable_rows.push({id:divid,original_name:original_name,lookup_code:lookup_code,description:description,full_name:full_name});
	 });
	 fields["variable_details"]=variable_rows;
	 if(variable_rows.length==0)
	 {
	  formIsValid = false;
	  errors["description"] = "no record found";
	 }
	 this.setState({errors: errors});
     return formIsValid;
    }

	  variableUpdate = (e) => {	  
      e.preventDefault();
      if(this.handleValidation()){
	  console.log(this.state.fields);	  
	  this.updateVariableApi();	  
      }else{
      alert("Mandatory Field Emplty!");
      }
    }
	
	updateVariableApi()
    {
	  const query = new URLSearchParams(this.props.location.search);
      const organization_id = query.get('organization');	
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
		   /*const count=response.count;*/
           if(status === "true")
           {
              this.props.history.push('/Variables?organization='+organization_id);
			  window.location.reload();
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
	togglePopup() {
   this.setState({
     showPopup: !this.state.showPopup
   });
 }
	
    
    handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  
  getOrganizationName()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }  
    
    componentDidMount() {
      this.GetOrganizationList();
	  this.GetVariableList();
	  this.getOrganizationName();
  }
  
  deleteVariableSubmit(variableid,organizationid)
{
	  confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete this Content Variable',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteVariable(variableid,organizationid)
        },
        {
          label: 'No',
        }
      ]
    });
}

deleteVariable(variableid,organizationid)
{ 
let variable_delete_template = this.state.variable_delete_template;
variable_delete_template["variableid"]=variableid;
variable_delete_template["organizationid"]=organizationid;
variable_delete_template["action"]="deleteVariable";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.variable_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   window.location.reload();
    }       
});
}	
      GetOrganizationList(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationTemplate"; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const template = response.template;
           if(status === "true")
           {
            this.setState({
              ObjOrganizations: response.template
          })
           }       
     });
     
 }
 
    GetVariableList(){
      let variable_template = this.state.variable_template;
      let ObjVariables = this.state.ObjVariables;
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
      variable_template["action"]="getVariableList"; 
	  variable_template["organizationid"]=organizationid;
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.variable_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const variable_list = response.list;
           if(status === "true")
           {
            this.setState({
              ObjVariables: variable_list
          })
           }       
     });
     
 }
    
      GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {
            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }
      render() {
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;	  
		  
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  let variableList = this.state.ObjVariables.map((v,index) => (													   
      <div className="row variable-row" key={index} id={v.id}>
                  <div className="col-xs-3">
                    <input type="text"
					name="original_name"
					id={'original_name_'+v.id}
					defaultValue={v.original_name}
					className="form-control" />
                  </div>
                  <div className="col-xs-2">
                    <input type="text"
					name="lookup_code"
					id={'lookup_code_'+v.id}
					defaultValue={v.lookup_code}
					className="form-control" />
                  </div>
                  <div className="col-xs-3">
                    <textarea name="description"
					className="form-control"
					id={'description_'+v.id} >{v.description}</textarea>
                  </div>
                  <div className="col-xs-3">
                    <input type="text"
					name="full_name"
					id={'full_name_'+v.id}
					defaultValue={v.full_name}
					className="form-control" />
                  </div>
                  <div className="col-xs-1">
                  <a className="btn btn-danger" onClick={()=>this.deleteVariableSubmit(v.id,organizationid)} data-method="DELETE" data-remote="true" rel="nofollow" data-original-title="" title="">Delete</a>
                  </div>
                  </div>
    ));
	if (localStorage.getItem('session')) {  
    return (
        <div className="bodyscroll">
        <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard}>{this.state.ObjOrganization}</a></li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization} data-original-title="" title="">Admin</a></li>
            <li><a onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
            <li><a onClick= {this.logout} rel="nofollow">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
     <div className="container">
        <div className="row">
          <div className="col-xs-12">
          <a onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organizations</a> » Variables
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Edit Content Variables</h2>

	  <hr/>
          <form acceptCharset="UTF-8"
		  className="edit_organization"
		  id="edit_organization"
		  onSubmit={this.variableUpdate.bind(this)}>
            <div>
            </div>
            <div className="row">
              <div className="col-xs-3">
                <h4>Content Variable Name</h4>
              </div>
              <div className="col-xs-2">
                <h4>Content Variable Code</h4>
              </div>
              <div className="col-xs-3">
                <h4>Variable Description</h4>
              </div>
              <div className="col-xs-3">
                <h4>Variable Value</h4>
              </div>
            </div>
            <div className="row">
              <br />
            </div>
            <div className="variables-container">
              {variableList}
			  <br />
            </div>
            
              <div className="form-group">
              <input className="btn btn-success" name="commit" type="submit" value="Save" />&nbsp;
              <a className="btn btn-default" onClick={this.redirectOrganization}>Cancel</a>
              <a className="btn btn-primary pull-right" onClick={this.togglePopup.bind(this)} data-remote="true">Add Variable</a>
			  
			  {this.state.showPopup ?
			 <Popup
			  text='Click "Close Button" to hide popup'
			  closePopup={this.togglePopup.bind(this)}
			 />
         : null
       }
	      </div>
          </form>
          </div>
        </div>
  </div>
  </div>
    );
	}else{
	window.location.href = '/';	
	}
  }
}
export default Variables;