import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import * as jsPDF  from 'jspdf'

const pxToMm = (px) => {
  return Math.floor(px/document.getElementById('ab').offsetHeight);
};

const mmToPx = (mm) => {
  return document.getElementById('ab').offsetHeight*mm;
};

const range = (start, end) => {
    return Array(end-start).join(0).split(0).map(function(val, id) {return id+start});
};


const PrintButton = ({id, label}) => (<div className="tc mb4 mt2">
  {/*
    Getting pixel height in milimeters:
    https://stackoverflow.com/questions/7650413/pixel-to-mm-equation/27111621#27111621
  */}
  <div id="myMm" style={{height: "1mm"}} />


  <div
    className="pa2 ba bw1 b--black bg-yellow black-90 br2 dib pointer dim shadow-1"
    onClick={() => {
     // let input = document.getElementById(id);
      
	 // console.log(input);
	  const input = document.getElementById("ab");
	  const inputHeightMm = pxToMm(input.offsetHeight);
      const a4WidthMm = 210;
      const a4HeightMm = 297;
	  
	  
      const a4HeightPx = mmToPx(a4HeightMm); 
      const numPages = inputHeightMm <= a4HeightMm ? 1 : Math.floor(inputHeightMm/a4HeightMm) + 1;
     	console.log({
        input, inputHeightMm, a4HeightMm, a4HeightPx, numPages, range: range(0, numPages), 
        comp: inputHeightMm <= a4HeightMm, inputHeightPx: input.offsetHeight
      });
	  html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
		  
		  let imgWidth = 310; 
          let pageHeight = 295;  
          let imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
		  
		  
		  let pdf;
  
          pdf = new jsPDF('p', 'mm');
          let position = 0;
          
         // console.log(imgData);
		 //pdf.addImage(imgData, 'PNG', 0, 0);
		 pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
         heightLeft -= pageHeight;
		 
		 
		 while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
		
         // console.log(input);
		// pdf.fromHTML(input,1,1);
		 // pdf.output("dataurlnewwindow");
		 
		  pdf.save(`${id}.pdf`);
		  
		   /*pdf.addHTML(input[0], function () {
           pdf.save(`${id}.pdf`);
           });*/
		  
		  /*pdf.addHTML(input, 10, 10);
		  pdf.autoPrint();
		  pdf.output("dataurlnewwindow");*/
		  
		  
		  
         /**/
        });
	  
	  
	  
      ////////////////////////////////////////////////////////
      // System to manually handle page breaks
      // Wasn't able to get it working !
      // The idea is to break html2canvas screenshots into multiple chunks and stich them together as a pdf
      // If you get this working, please email me a khuranashivek@outlook.com and I'll update the article
      ////////////////////////////////////////////////////////
      // range(0, numPages).forEach((page) => {
      //   console.log(`Rendering page ${page}. Capturing height: ${a4HeightPx} at yOffset: ${page*a4HeightPx}`);
      //   html2canvas(input, {height: a4HeightPx, y: page*a4HeightPx})
      //     .then((canvas) => {
      //       const imgData = canvas.toDataURL('image/png');
      //       console.log(imgData)
      //       if (page > 0) {
      //         pdf.addPage();
      //       }
      //       pdf.addImage(imgData, 'PNG', 0, 0);
      //     });
      //   ;
      // });

      // setTimeout(() => {
      //   pdf.save(`${id}.pdf`);
      // }, 5000);

      
    }}
  >
    {label}
  </div>
</div>);

export default PrintButton;