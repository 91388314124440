import React, {Component, PropTypes} from 'react';
import html2canvas from 'html2canvas';
import * as jsPDF  from 'jspdf';
import Base64 from 'base-64';
// download html2canvas and jsPDF and save the files in app/ext, or somewhere else
// the built versions are directly consumable
// import {html2canvas, jsPDF} from 'app/ext';


export default class Export extends Component {
  constructor(props) {
    super(props);
  }

  printDocument = (event) => {	  
    const input = document.getElementById('divToPrint');
	html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
		  
		  let imgWidth = 210; 
          let pageHeight = 295;  
          let imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
		  
		  
		  let pdf;
  
          pdf = new jsPDF('p', 'mm');
          let position = 0;
		 pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
         heightLeft -= pageHeight;
		 
		 
		 while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
		
         // console.log(input);
		// pdf.fromHTML(input,1,1);
		 // pdf.output("dataurlnewwindow");
		 
		  pdf.save(`sample.pdf`);
		  
		   /*pdf.addHTML(input[0], function () {
           pdf.save(`${id}.pdf`);
           });*/
		  
		  /*pdf.addHTML(input, 10, 10);
		  pdf.autoPrint();
		  pdf.output("dataurlnewwindow");*/
		  
		  
		  
         /**/
        });
  }

  render() {
    return (<div>
      <div className="mb5">
        <button onClick={this.printDocument}>Print</button>
      </div>
<div id="divToPrint" className="mt4">
<div className="supporting_material_content">
<h2>MERP EMERGENCY PHONE NUMBERS CONTACT LIST</h2>
<div className="left-col">
<h5><strong>FOR ALL EMERGENCIES CALL 911</strong></h5>
<table border="0">
<tbody>
<tr><th>Contact</th><th>Number</th></tr>
<tr>
<td>Police/Fire/EMS</td>
<td>911</td>
</tr>
</tbody>
</table>
<h5><strong>NON-EMERGENCY NUMBERS</strong></h5>
<table border="0">
<tbody>
<tr><th>Contact</th><th>Number</th></tr>
<tr>
<td>Law Enforcement 1</td>
<td><a href="tel:5555555555">555-555-</a>4444</td>
</tr>
<tr>
<td><span>Law Enforcement 2</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Law Enforcement 3</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Law Enforcement 4</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td>Fire Department 1</td>
<td><span>555-555-4321</span></td>
</tr>
<tr>
<td><span>Fire Department 2</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Fire Department 3</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Fire Department 4</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Fire Department 5</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Electric Utility</span></td>
<td><span>555-555-1234</span></td>
</tr>
<tr>
<td><span>Gas Utility</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
<tr>
<td><span>Water Utility</span></td>
<td>xxx-xxx-xxxx</td>
</tr>
</tbody>
</table>
<table border="0">
<tbody>
<tr><th>Abbreviation</th><th>Term</th></tr>
<tr>
<td>CCT&nbsp;</td>
<td>Crisis Communications Team</td>
</tr>
<tr>
<td>CRT</td>
<td>Crisis Response Team</td>
</tr>
<tr>
<td>ERP</td>
<td>Emergency Response Plan</td>
</tr>
<tr>
<td>HAZMAT</td>
<td>Hazardous Materials</td>
</tr>
<tr>
<td>IC</td>
<td>Incident Commander</td>
</tr>
<tr>
<td>CP</td>
<td>&nbsp;Command Post</td>
</tr>
<tr>
<td>ICS</td>
<td>Incident Command System</td>
</tr>
<tr>
<td>MSDS</td>
<td>Material Safety Data Sheets</td>
</tr>
<tr>
<td>NIMS</td>
<td>National Incident Management System</td>
</tr>
<tr>
<td>COM</td>
<td>Public Information Officer/Executive Director of Communication</td>
</tr>
<tr>
<td>SRO</td>
<td>School Resource Officer</td>
</tr>
</tbody>
</table>
</div>
<div className="right-col">
<h3>MOBILE EMERGENCY RESPONSE PLAN</h3>
<p><strong>Purpose and Scope:</strong> The Mobile Emergency Response Plan (MERP) provides guidance and direction to principals, faculty and staff who have emergency management responsibilities. The MERP should be used during an emergency incident. This information is not a complete list of all factors to be considered.</p>
<p><strong>Key Emergency Contact:</strong> The key emergency phone number is 911. It is imperative during an emergency to contact your school�s Crisis Response Team as quickly as possible after calling 911.&nbsp;</p>
<p><strong>Crisis Response Team (CRT):</strong> Each school facility will have a Crisis Response Team (CRT) to take charge of the emergency, respond effectively, protect the occupants of the facility and reduce the risk of physical injury, property damage and business interruption.</p>
<p><strong>Incident Command System (ICS):</strong> The Crisis Response Team will follow the basic principles of ICS where one team member (Incident Commander, IC) is in charge of the emergency until emergency responders arrive on the scene. At that point the school IC, will update the emergency response IC on the incident and turn over the duties to the emergency response IC. The school IC should remain in close contact with the emergency response IC until the incident is terminated. The IC is responsible for command, control, communications and coordination of resources during an emergency event.</p>
<p><strong>Flow Charts:</strong> Each incident has a flow chart for the IC and the CRT to follow in order to effectively stabilize and terminate the incident.</p>
<p><strong>Event Aids:</strong> The event aids provide valuable information to assist the IC and the CRT in responding to an incident.</p>
<p><strong>Emergency Phone Lists:</strong> Should be provided to ensure the emergency responders are quickly notified of an incident.</p>
<p><strong>Evacuation Drawings:</strong> Should be uploaded to provide emergency responders and employees with vital evacuation, sheltering and lockdown information.</p>
<p><strong>Roles &amp; Responsibilities:</strong> Key responsibilities of the CRT should be uploaded to give team members a general idea of their roles during an emergency incident.</p>
<p><strong>Crisis Communications:</strong> The flow of information is critical during an emergency and this section will provide proactive communication measures.</p>
</div>
  </div>
		
		
		
		
      </div>
    </div>);
  }
}