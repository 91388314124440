import React, { Component, createRef, Fragment} from 'react';
import { browserHistory, withRouter } from 'react-router';
import './App.css';
import axios from 'axios';
import RenamePDF from './components/RenamePDF';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import WebViewer from '@pdftron/webviewer';
import Base64 from 'base-64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class pdfTron extends Component {
    constructor(props){
        super(props);
		this.state = {
			viewer : createRef(null),
			pdf_save_template: {},
			showRenamePopup: false,
			upload_filename:'',
			upload_filepath:'',
			loader_class: '',
			};
			this.toggleRenameClosePopup = this.toggleRenameClosePopup.bind(this);
     }
	 toggleRenameCancelPopup()
	 {
		 this.setState({
     showRenamePopup: !this.state.showRenamePopup
     });
	 }
	 toggleRenameClosePopup()
     {
	 this.setState({
     showRenamePopup: !this.state.showRenamePopup
     });
	    let filepath = Base64.decode(this.props.filepath);
	    let filename = this.props.filename;
		
		let cardid = this.props.cardid;
		let organizationid = this.props.organizationid;
		let checklistid = this.props.checklistid;
		let report_name = this.props.report_name;
		let chartid = this.props.chartid;
		let userid = this.props.userid;
		let new_pdf_name = localStorage.getItem("pdf_name");
		if(new_pdf_name!='' && new_pdf_name!=null)
		{
		let pdf_save_template = this.state.pdf_save_template;
		    pdf_save_template["action"] = "saveChecklistPDFForm";
			pdf_save_template["chartid"] = chartid;
			pdf_save_template["multichecklist_id"] = checklistid;
			pdf_save_template["cardid"] = cardid;
			pdf_save_template["userid"] = userid;
			pdf_save_template["organizationid"] = organizationid;
			pdf_save_template["filename"] = this.state.upload_filename;
			pdf_save_template["filepath"] = this.state.upload_filepath;
			pdf_save_template["pdfid"] = localStorage.getItem("pdfid");
			pdf_save_template["change_filename"] = localStorage.getItem("pdf_name");
			pdf_save_template["report_name"] = report_name;
			/*console.log(JSON.stringify(pdf_save_template));*/
			
			fetch(global.api_url,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.state.pdf_save_template)
			})
			.then((response) => response.json())
			.then((response) => {
				const status = response.status;
				const code = response.code;
				if(status=='true')
				{
				toast.success('PDF saved successfully!!', {
				  position: toast.POSITION.TOP_RIGHT,
				  autoClose: 4000
				});	
				
				//this.props.history.push('/ChartsAction?collectionid=2799&chartid='+chartid+'&checklistid='+checklistid);
				//window.location.replace('/ChartsAction?collectionid=2799&chartid='+chartid+'&checklistid='+checklistid);
				this.props.closeFileView(checklistid);
				}else{
				
				toast.error('something went wrong,please try again !', {
				  position: toast.POSITION.TOP_RIGHT,
				  autoClose: 4000
				});
				}
			});	
		}else{
			toast.error('required field can not be empty!', {
				  position: toast.POSITION.TOP_RIGHT,
				  autoClose: 4000
				});
		}
		
	        
	 
	 
	 
     }
	 componentDidMount() {
		let filepath = Base64.decode(this.props.filepath);
		let server_filepath = filepath.replace("public/","");
		let web_filepath = "https://safeguardses.com/"+server_filepath;
		let filename = this.props.filename;
		let pdfid = this.props.pdfid;
		let cardid = this.props.cardid;
		let organizationid = this.props.organizationid;
		let checklistid = this.props.checklistid;
		let chartid = this.props.chartid;
		let userid = this.props.userid; 
		const { viewer } = this.state;
	    WebViewer(
      {
		licenseKey: 'Safeguard Risk Solutions, LLC (safeguardrisksolutions.com):OEM:Safeguard Enterprise Solutions::B+:AMS(20230821):28A5553D04A7780AF360B13AC9A2727860612FBDBF706A65F570458AFD720ED652F231F5C7',  
        path: './webviewer/lib',
		disabledElements: [
    'viewControlsButton',
    'viewControlsOverlay'
  ],
        initialDoc: './'+server_filepath,
      },
      viewer.current,
    ).then((instance) => {
		
       var FitMode = instance.FitMode;
	   instance.closeElements([ 'menuOverlay', 'leftPanel' ]);
	   instance.disableElements(['downloadButton']);
	   instance.disableElements(['printButton']);
	   
	   instance.setFitMode(FitMode.FitPage);
	   instance.setFitMode(FitMode.FitWidth);
	   
	  const docViewer = instance.docViewer;
      const annotManager = instance.annotManager;
	  const Tools = instance.Tools;
      const Annotations = instance.Annotations;
	  
	   const rectangle = new Annotations.RectangleAnnotation();
	  rectangle.PageNumber = 1;
	  rectangle.X = 100;
	  rectangle.Y = 150;
	  rectangle.Width = 200;
	  rectangle.Height = 50;
	  rectangle.Author = annotManager.getCurrentUser();
	  //annotManager.addAnnotation(rectangle);
	  annotManager.drawAnnotations(rectangle.PageNumber);
	  
	  


    docViewer.setWatermark({
      // Draw diagonal watermark in middle of the document
      diagonal: {
        fontSize: 25, // or even smaller size
        fontFamily: 'sans-serif',
        color: 'red',
        opacity: 50, // from 0 to 100
        text: 'Watermark'
      },

      // Draw header watermark
      header: {
        fontSize: 10,
        fontFamily: 'sans-serif',
        color: 'red',
        opacity: 70,
        left: 'left watermark',
        center: 'center watermark',
        right: ''
      }
    });
	  
	  
	  //annotManager.redrawAnnotation(rectangle);	  
	  
	  instance.setHeaderItems(header => {
      header.push({
        type: 'actionButton',
        img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
		onClick: async () => {
		    this.setState({
			loader_class: 'checklist_loader'
			})
		  const doc = docViewer.getDocument();
		  const xfdfString = await annotManager.exportAnnotations();
		  const data = await doc.getFileData({
                  xfdfString
                });
		  const arr = new Uint8Array(data);
		  const blob = new Blob([arr], { type: 'application/pdf' });
		  var file = new File([blob], filename, {lastModified: new Date()});
		  //console.log({ file })
		  let dataset = new FormData();
		  dataset.append('pdftron_upload', file)
		  const uploadres = await this.multiUploaderSet(dataset);
		  //console.log(uploadres.data);
		  
		  this.setState({
		  upload_filename : uploadres.data.filename,
		  upload_filepath : uploadres.data.path,
		  loader_class: '',
          showRenamePopup: !this.state.showRenamePopup
          });
        }
      });
	   header.push({
        type: 'actionButton',
        img: '/assets/images/close.svg',
        onClick: () => {
          this.props.closeFileView(checklistid);
        }
      });
    });
	  docViewer.on('documentLoaded', () => {
    });

    instance.loadDocument(web_filepath);
	return instance;
    });
		
	 }
	 multiUploaderSet = async (data) => {
     const res = await axios.post("https://expressapi.safeguardses.com/pdftronUpload", data, {
   })
   return res;
};
  
  render() { 
  const { viewer } = this.state;
    return (
        <div className="App">
		<ToastContainer closeButton={false} /> 
      <div className="webviewer" style={{ height: "100vh"}} id="viewer" ref={viewer}></div>
	  <div className={this.state.loader_class}></div>
	  {this.state.showRenamePopup ?
			 <RenamePDF
			  text='Popup'
			  closeRenamePopup={this.toggleRenameClosePopup.bind(this)}
			  cancelRenamePopup={this.toggleRenameCancelPopup.bind(this)}
			 />
         : null
       }
    </div>
    );
}
}

export default pdfTron;
