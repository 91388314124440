import React, { Component } from 'react';
import queryString from 'query-string'
import AddModules from './AddModules'
import EditModules from './EditModules'
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
class Modules extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
	  saveorder_template: {},
      collection_template: {},
	  collection_delete_template: {},
      ObjOrganizations: '',
	  ObjOrganization: '',
      ObjCollections: [],
	  saveOrderClass: 'btn btn-success planOrderHide'
    }
    this.addModules=this.addModules.bind(this);
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.editProfile=this.editProfile.bind(this);
	this.redirectToEditCollections=this.redirectToEditCollections.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
 }
  componentDidMount()
  {
  this.GetOrganizationDetail();
  this.GetModuleList();
  }
  addModules(){
  const query = new URLSearchParams(this.props.location.search);
  const token = query.get('organization');
  this.props.history.push('/AddModules?organization='+ token);
  }
  editProfile(user_id,organizationid){
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }
  redirectOrganization(){
  this.props.history.push('/Organization');
  }
  redirectToEditCollections(collectionid,organizationid){
    this.props.history.push('/EditModules?organization='+organizationid+'&module='+collectionid);
  }
  redirectToEventAids(collectionid,organizationid)
  {
	  this.props.history.push('/collections/Collections?organization='+organizationid+'&moduleid='+collectionid);
  }
  redirectToEventIntroduction(collectionid,organizationid)
  {
	  this.props.history.push('/ModuleSupportingMaterials?organization='+organizationid+'&moduleid='+collectionid);
  }
  GotoReport()
	{
		this.props.history.push('/Report');
	}
  redirectDashboard(){
  this.props.history.push('/Collection');
  }

  logout() {
    if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }

GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }       
 });
 }

saveModuleOrder(organizationid)
  {
  let saveorder_template = this.state.saveorder_template;
  saveorder_template["action"] = "saveModuleOrder";
  saveorder_template["organization_id"] = organizationid;
  saveorder_template["module_list"] = JSON.stringify(this.state.ObjCollections);
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.saveorder_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const code = response.code;
       if(status === "true")
       {  
         this.GetModuleList();
		 this.props.history.push('/modules/Modules?action=order&organization='+organizationid);
       }       
 });
  
  } 
GetModuleList(){
  let collection_template = this.state.collection_template;
  let ObjCollections = this.state.ObjCollections;
  collection_template["action"]="getModuleList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  collection_template["organizationid"]=organizationid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.collection_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const collection_details = response.module_details;
       if(status === "true")
       {
        this.setState({
          ObjCollections: response.module_details
      })
       }       
 });
 
}
onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({ObjCollections}) => ({
      ObjCollections: arrayMove(ObjCollections, oldIndex, newIndex),
    }));
	this.setState({
          saveOrderClass: 'btn btn-success'
      })
  };
 deleteCollectionSubmit(collectionid,organizationid)
{
  if (window.confirm("Are you sure you want to delete this Module")) {
  this.deleteModule(collectionid,organizationid);
}
}

deleteModule(collectionid,organizationid)
{
const query = new URLSearchParams(this.props.location.search);
const token = query.get('organization');  
let collection_delete_template = this.state.collection_delete_template;
collection_delete_template["organizationid"]=organizationid;
collection_delete_template["collectionid"]=collectionid;
collection_delete_template["action"]="deleteModule";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.collection_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   this.GetModuleList();	
	   this.props.history.push('/modules/Modules?action=delete&organization='+ token);
    }       
});
}


      render() {
	  const {ObjCollections} = this.state;	  
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let super_admin = sdata.super_admin;
	  let user_level_2 = sdata.admin;
	  let user_level_1 = sdata.organization_admin;
	  let id = sdata.id;
	  let organization_id = sdata.organization_id;
	  
	  const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const action = query.get('action');
	  let action_message
	  if(action=='delete')
	  {
		  action_message = <div data-alert="" className="alert alert-success">Module successfully deleted!</div>;
	  }else if(action=='edit'){
		  action_message = <div data-alert="" className="alert alert-success">Module successfully updated!</div>;
	  }else if(action=='create'){
		  action_message = <div data-alert="" className="alert alert-success">Module successfully created!</div>;
	  }else if(action=='order'){
		  action_message = <div data-alert="" className="alert alert-success">Module order saved successfully!</div>;
	  }else{
		  action_message = '';
	  }
	  
	  
	  const SortableItem = SortableElement(({value,id}) => (
  <li className="moveROW" tabIndex={0}><div className="leftDIV">{value}</div> 
  <div className="rightDIV">
  {user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEventAids(id,organizationid)}>Plans</a>:''}&nbsp;
  {user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEventIntroduction(id,organizationid)}>Edit Module Introduction</a>:''}&nbsp;
  {user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEditCollections(id,organizationid)}>Edit</a>:''}&nbsp;
  {user_level_1==1 ?<a className="btn btn-sm btn-default" onClick={()=>this.deleteCollectionSubmit(id,organizationid)} data-method="delete" rel="nofollow">Delete</a>:''}&nbsp;
  </div></li>
));
	  
	  const SortableList = SortableContainer(({items}) => {
  return (
    <ul className="alternativeROW">
	<li><div className="leftDIV"><strong>Plan Name</strong></div><div className="rightDIV"><strong>Actions</strong></div></li>
      {ObjCollections.map((value,index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value.title} id={value.id} />
      ))}
    </ul>
  );
});
	  
let collectionList = this.state.ObjCollections.map((v,index) => (
<tr key={index}>
<td>{v.title}</td>
<td className="text-right btnRight">
{user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEventAids(v.id,organizationid)}>Plans</a>:''}
{user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEventIntroduction(v.id,organizationid)}>Edit Module Introduction</a>:''}
{user_level_1==1?<a className="btn btn-sm btn-default" onClick={()=>this.redirectToEditCollections(v.id,v.organization_id)}>Edit</a>:''}
{user_level_1==1 ?<a className="btn btn-sm btn-default" onClick={()=>this.deleteCollectionSubmit(v.id,v.organization_id)} data-method="delete" rel="nofollow">Delete</a>:''}
      </td>
    </tr>
    ));
	if (localStorage.getItem('session')) {
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard} data-original-title="">{this.state.ObjOrganizations}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization}>Admin</a></li>
			  <li className=""><a onClick={this.GotoReport}>Report</a></li>
              <li><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a onClick= {this.logout} data-method="delete"  rel="nofollow">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a className="" onClick={this.redirectOrganization}>Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » Modules
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {action_message}
              <h2>
      <span className="pull-right">
	  <a className={this.state.saveOrderClass} onClick={()=>this.saveModuleOrder(organizationid)}>Save Module Order</a>&nbsp;
          {super_admin==1 || user_level_1==1?<a className="btn btn-success" onClick={this.addModules}>Add Module</a>:''}
      </span>
      Modules
    </h2>
  <SortableList items={this.state.ObjCollections} onSortEnd={this.onSortEnd} distance={1} />
            </div>
          </div>  
    </div>
</div>

     
    );
	}else{
	window.location.href = '/';	
	}
  }
}

export default Modules;
