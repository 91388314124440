import React, { Component } from 'react';
import Select from 'react-select';
import queryString from 'query-string'


class EditChecklist extends Component {
    constructor(props){
        super(props);
        this.state = {
        chart_title: '',
		option_false: '',
		option_true: '',
		chart_priority: 0,
        chart_loggable: 'false',
        ObjOrganizations: [],
        ObjCollections: [],
		ObjCollection: [],
		ObjChart: [],
		items: [],
        fields: {},
		color_theme: '',
        organization_template: {},
        collection_template: {},
		chart_template: {},
        error: {}
        }
        this.redirectOrganization=this.redirectOrganization.bind(this);
		this.redirectPlan=this.redirectPlan.bind(this);
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.editProfile=this.editProfile.bind(this);
		this.redirectEventAids=this.redirectEventAids.bind(this);
     }
	   componentDidMount() {
			this.GetOrganizationDetail();
			this.GetCollectionDetails();
			this.GetChartDetails();
		  }
	 redirectDashboard(){
	 this.props.history.push('/Collection');
	 }
	 editProfile(user_id,organizationid)
     {
     this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
     }
	 redirectEventAids(organizationid,collectionid,moduleid)
	 {
		 this.props.history.push('/EventAids?organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
	 }
	 redirectPlan(organizationid,moduleid)
     {
	  this.props.history.push('/collections/Collections?organization='+organizationid+'&moduleid='+moduleid);
     }
     handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  const chartid = query.get('chartid');
	  let chart_loggable_isChecked;
	  const plan_title = this.state.ObjCollection.title;
	  if(plan_title=='Emergency Response Plan')
	  {
	   chart_loggable_isChecked = document.getElementById('chart_loggable').checked;	  
	  }else{
	   chart_loggable_isChecked = false;
	  }
	  let chart_colour_isChecked = this.state.color_theme;
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let userid = session_array.id;
     //action 
     fields["action"]="editChecklist";
     fields["organizationid"]=organizationid;
	 fields["collectionid"]=collectionid;
	 fields["chart_loggable"]=chart_loggable_isChecked;
	 fields["userid"]=userid;
	 fields["chartid"]=chartid;
	 fields["color_theme"]=chart_colour_isChecked;
	 let chart_title = document.getElementById('chart_title').value;
	 let option_true = document.getElementById('option_true').value;
	 let option_false = document.getElementById('option_false').value;
	 let chart_priority = document.getElementById('chart_priority').value;
      //chart_title
      if(chart_title==''){
         formIsValid = false;
         errors["chart_title"] = "Cannot be empty";
      }else{
		  fields["chart_title"]=chart_title;
	  }
	  if(chart_priority==''){
         formIsValid = false;
         errors["chart_priority"] = "Cannot be empty";
      }else{
		  fields["chart_priority"]=chart_priority;
	  }
	  
	  if(option_true==''){
         formIsValid = false;
         errors["option_true"] = "Cannot be empty";
      }else{
		  fields["option_true"]=option_true;
	  }
	  
	  if(option_false==''){
         formIsValid = false;
         errors["option_false"] = "Cannot be empty";
      }else{
		  fields["option_false"]=option_false;
	  }
     this.setState({errors: errors});
     return formIsValid;
    }
    
    chartSubmit(e){
      e.preventDefault();
      if(this.handleValidation()){
          this.editChartApi();
      }else{
         alert("Mandatory Field Emplty!");
      }
    
    }
    handleChangeLoggable(field, e){         
      let fields = this.state.fields;
      let isChecked = e.target.checked;
      fields[field] = isChecked;     
      this.setState({fields});
    }
    handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
    redirectOrganization(){
      this.props.history.push('/Organization');
      }
      GetOrganizationDetail(){
      let organization_template = this.state.organization_template;
      let ObjOrganizations = this.state.ObjOrganizations;
      organization_template["action"]="getOrganizationDetails"; 
      let session = localStorage.getItem("session");
      let session_array=JSON.parse(session);
      let organizationid = session_array.organization_id;
      organization_template["organizationid"]=organizationid; 
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.organization_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
            const name = response.name;
           if(status === "true")
           {
            this.setState({
              ObjOrganizations: response.name
          })
           }       
     });
     
    }


GetChartDetails(){
let chart_template = this.state.chart_template;
let ObjChart = this.state.ObjChart;
chart_template["action"]="detailChart";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
const chartid = query.get('chartid');
chart_template["organizationid"]=organizationid;
chart_template["collectionid"]=collectionid;
chart_template["chartid"]=chartid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.chart_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjChart: details,
		color_theme: details.color_theme
	})
	 }       
});
} 


GetCollectionDetails(){
let collection_template = this.state.collection_template;
let ObjCollection = this.state.ObjCollection;
collection_template["action"]="detailCollection";
const query = new URLSearchParams(this.props.location.search);
const organizationid = query.get('organization');
const collectionid = query.get('collectionid');
collection_template["organizationid"]=organizationid;
collection_template["collectionid"]=collectionid;
fetch(global.api_url,
   {
	   method: 'POST',
	   headers: {
		   Accept: 'application/json',
		   'Content-Type': 'application/json'
	   },
	   body: JSON.stringify(this.state.collection_template)
   })
	.then((response) => response.json())
	.then((response) => { 
	  const status=response.status;
	  const details = response.details;
	 if(status === "true")
	 {
	  this.setState({
		ObjCollection: details
	})
	 }       
});
 console.log(this.ObjCollection);       
    }
    
    editChartApi()
    {
      const query = new URLSearchParams(this.props.location.search);
      const organizationid = query.get('organization');
	  const collectionid = query.get('collectionid');
	  const moduleid = query.get('moduleid');
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
			  this.props.history.push('/EventAids?action=edit&organization='+organizationid+'&collectionid='+collectionid+'&moduleid='+moduleid);
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  handleWeekdayChange = (event) => {
    this.setState({color_theme: event.target.value})
  }

  render() {
	let {color_theme} = this.state  
	const query = new URLSearchParams(this.props.location.search);
    const organizationid = query.get('organization');
	const collectionid = query.get('collectionid');
	const moduleid = query.get('moduleid');
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  let organization_id = sdata.organization_id;
	  const plan_title = this.state.ObjCollection.title;
	  let loggable_div;
	
	let chart_loggable_checkbox;
    let chart_loggable_checkbox_val = this.state.ObjChart.loggable;
    if(chart_loggable_checkbox_val == 1){
      chart_loggable_checkbox = (
  <input
  type="checkbox"
  defaultChecked="true"
  id="chart_loggable"
  name="chart_loggable" />
       );
    }else if(chart_loggable_checkbox_val == 0){
      chart_loggable_checkbox = (
        <input
        type="checkbox"
        id="chart_loggable"
        name="chart_loggable" />
             );
    }else{
    }
	
	if(plan_title=='Emergency Response Plan')
	{
		loggable_div = <div className="form-group">
    {chart_loggable_checkbox}
      <label htmlFor="chart_loggable">&nbsp;Loggable</label>
    </div>;
	}else{
		loggable_div = '';
	}
	
	if (localStorage.getItem('session')) {
    return (
    <div className="bodyscroll">
    <div className="navbar navbar-inverse navbar-static-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-left">
          <li><a className="home1" onClick={this.redirectDashboard} data-original-title="" title="">{this.state.ObjOrganizations}</a></li>
        </ul>
        <ul className="nav navbar-nav navbar-right">
              <li className="active"><a onClick={this.redirectOrganization} data-original-title="" title="">Admin</a></li>
            <li className=""><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
            <li><a data-confirm="Are you sure you want to log out?" onClick= {this.logout} rel="nofollow" data-original-title="" title="">Log Out</a></li>
        </ul>
      </div>
    </div>
  </div>
  
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
           <a onClick={this.redirectOrganization}>Admin</a> &#187; <a onClick={()=>this.redirectPlan(organizationid,moduleid)}>Plans</a> &#187; <a onClick={()=>this.redirectEventAids(organizationid,collectionid,moduleid)}>{this.state.ObjCollection.title}</a> &#187; {this.state.ObjChart.title}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Edit Tab</h2>
  
  <form acceptCharset="UTF-8"
  onSubmit= {this.chartSubmit.bind(this)}
  className="new_chart"
  id="new_chart">
<div>
	</div> 
  <div className="form-group">
      <label htmlFor="chart_title">Title</label>:
      <input className="form-control"
     id="chart_title" 
     name="chart_title" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "chart_title")}
	 defaultValue ={this.state.ObjChart.title} 
     />
    </div>
    <div className="form-group">
	<label htmlFor="chart_priority">Priority</label>
    <input className="form-control"
     id="chart_priority" 
     name="chart_priority" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "chart_priority")}
	 defaultValue ={this.state.ObjChart.priority}
     />
    </div>
    {loggable_div}
	<hr/>
	<h4>Tab Color</h4>
	<div className="form-group">
	<table>
	<tbody>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="yellow" onChange={this.handleWeekdayChange.bind(this)} checked={color_theme === 'yellow'} /></td>
	<td><label>&nbsp;Chrome Yellow</label></td> 
	<td>&nbsp;<span className="chart_chrome_yellow"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="orange" onChange={this.handleWeekdayChange.bind(this)} checked={color_theme === 'orange'} /></td>
	<td><label>&nbsp;Chrome Orange</label></td>
	<td>&nbsp;<span className="chart_orange"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="olive_green" onChange={this.handleWeekdayChange.bind(this)} checked={color_theme === 'olive_green'} /></td>
	<td><label>&nbsp;Olive Green</label></td>
	<td>&nbsp;<span className="chart_olive_green"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="maroon" onChange={this.handleWeekdayChange.bind(this)} checked={color_theme === 'maroon'} /></td>
	<td><label>&nbsp;Maroon</label></td>
	<td>&nbsp;<span className="chart_maroon"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="dark_green" onChange={this.handleWeekdayChange} checked={color_theme === 'dark_green'} /></td>
	<td><label>&nbsp;Dark Green</label></td>
	<td>&nbsp;<span className="chart_dark_green"></span></td>
	</tr>
	<tr>
	<td><input id="chart_theme" name="chart_theme" type="radio" className="group_collection" value="royal_blue" onChange={this.handleWeekdayChange.bind(this)} checked={color_theme === 'royal_blue'} /></td>
	<td><label>&nbsp;Royal Blue</label></td>
	<td>&nbsp;<span className="chart_royal_blue"></span></td>
	</tr>
	</tbody>
	</table>
	</div>
	
	<hr />
	<div className="form-group">
	<table>
	<tbody>
	<tr><td colspan="3"><label>Description</label></td></tr>
	<tr>
	<td>
	 <input className="form-control"
     id="option_true" 
     name="option_true" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "option_true")}
     defaultValue ={this.state.ObjChart.true_condition}
	 placeholder="Condition if True"
     />
	</td>
	<td width="20px">&nbsp;</td>
	<td>
	<input className="form-control"
     id="option_false" 
     name="option_false" 
     type="text" 
     onChange={this.handleChangeName.bind(this, "option_false")}
     defaultValue ={this.state.ObjChart.false_condition}
	 placeholder="Condition if False"
     />
	</td>
	</tr>
	</tbody>
	</table>
    </div>
	
    <div className="form-group">
      <input className="btn btn-success" name="commit" type="submit" value="Save"/>&nbsp;
	  <a className="btn btn-default" onClick={()=>this.redirectEventAids(organizationid,collectionid,moduleid)}>Cancel</a>
    </div>
  </form>
          </div>
        </div>
      </div>
  </div>
    );
	}else{
	window.location.href = '/';		
	}
  }
}
export default EditChecklist;