import React from 'react';

export default class PDFViewer extends React.Component {

  render() {
    return (
      <div id='viewer' style={{ width: '100%', height: '100%' }}>
        Hello world!
      </div>
    )
  }
}