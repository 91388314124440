import React, { Component } from 'react';
import './rename_style.css';
import axios from 'axios';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Rename extends Component{
	constructor(props){
        super(props);
        this.state = {
        variable_full_name: '',
		ObjComments : [],
        variable_description: '',
        variable_lookup_code: '',
        variable_original_name: '',
		comments_template : {},
        fields: {},
        error: {},
		chartid : '',
		edit_template : {},
		success : '',
		notification : ''
        }
     }
    componentWillMount() {
	}
    handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
     //action 
     fields["action"]="createVariable";
	 fields["organization_id"]=organization_id;
      //organization_name
      if(!fields["variable_full_name"]){
         formIsValid = false;
         errors["variable_full_name"] = "Cannot be empty";
      }
     this.setState({errors: errors});

     return formIsValid;
    }
	
	
	handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
	saveReportName()
	{
	  let edit_template = this.state.edit_template;
	  let session = localStorage.getItem("session");
		let session_array = JSON.parse(session);
		let userid = session_array.id;
	  let selected_checklist = localStorage.getItem("selected_checklist");	
	  //let report_name = this.state.fields.edit_report;
	  let report_name = $("#edit_report").val();
	  edit_template["action"]="saveMultipleReportName";
	  edit_template["selected_checklist"]=selected_checklist;
	  edit_template["userid"]=userid;
	  edit_template["report_name"]=report_name;
	  console.log(JSON.stringify(this.state.edit_template));
      fetch(global.api_url,
         {
             method: 'POST',
             headers: {
                 Accept: 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify(this.state.edit_template)
         })
          .then((response) => response.json())
          .then((response) => { 
            const status=response.status;
			const code=response.code;
			const description=response.description;
			let sucess_message;
           if(status === "true")
           {
			   this.setState({
              notification : description
            })
			   document.getElementById('popup_success').innerHTML = this.state.notification;
           }else{
			   this.setState({
              notification : description
            })
			   document.getElementById('popup_warning').innerHTML = this.state.notification;
		   }
     });
	  
	  
	  
      
	}
	
  render() {
  const styleWarning = {
      color: "red",
      padding: "10px",
      fontFamily: "Arial",
	  fontSize: "12px"
    };
	const styleSuccess = {
      color: "green",
      padding: "10px",
      fontFamily: "Arial",
	  fontSize: "12px"
    };
	  
    return (
      <div className='popup'>
        <div className='popup_inner modal-sm height_maxCont'>
		<div className="modal-header">
          <h4>Rename Report</h4>
		  <div style={styleSuccess} id="popup_success"></div>
		  <div style={styleWarning} id="popup_warning"></div>
		  <button onClick={this.props.closeRenamePopup} className="btn btn-default">close</button>
		  </div>
		        <div className="modal-body">
				<table className="table table-striped table-bordered table wice-grid">
				<tbody>
				<tr>
			  <td>
			  <div>
			  <input
				type="text"
				className="form-control"
				name="edit_report"
				id="edit_report"
				onChange={this.handleChangeName.bind(this,"edit_report")}/>
				<div className="btnDiv">
			    <button type="button" className="btn btn-success" onClick={()=>this.saveReportName()}>Save</button>
				</div>
			  </div>
			  </td>
			  </tr>
				</tbody>
				</table>
                </div>
				<div className="modal-footer">
				</div>
        </div>
      </div>
    );
  }
}
export default Rename;