import React, { Component, Fragment } from 'react';
import logo from './logo.svg';
import './App.css';
import Organization from './admin/Organization';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
import $ from 'jquery';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class Collection extends Component {
    constructor(props){
        super(props);
        this.state = {
		   storage_template: {},
		   ObjStorage: [],
		   ObjCollection: [],
		   ObjOrganization:'',
		   isLoggedIn: false,
		   messages_template : {},
		   ObjMessages : [],
		   loader: 'spinner',
		   filepath: '',
		   org_description:'',
		   render: false
        }
    this.Gotoorg=this.Gotoorg.bind(this);
	this.GotoHelp=this.GotoHelp.bind(this);
    this.GotoDoc=this.GotoDoc.bind(this);
	this.GotoReport=this.GotoReport.bind(this);
	this.GotoForm=this.GotoForm.bind(this);
    this.GotoProfile=this.GotoProfile.bind(this);
	this.GotoSendMessage=this.GotoSendMessage.bind(this);
	this.GotoEmergency=this.GotoEmergency.bind(this);
	this.GotoShooter=this.GotoShooter.bind(this);
	this.GotoViewMessage=this.GotoViewMessage.bind(this);
	this.redirectChart=this.redirectChart.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
     }

    redirectDashboard(){
		this.props.history.push('/Collection');
	}
    Gotoorg(){
        this.props.history.push('/Organization');
        //alert("click");
    }
	GotoHelp(){
		this.props.history.push('/help');
	}
	
	GotoDoc(){
		this.props.history.push('/Doc');
    }
	GotoReport()
	{
		this.props.history.push('/Report');
	}
	GotoForm()
	{
		this.props.history.push('/Form');
	}
	
	redirectChart(moduleid){
		this.props.history.push('/CollectionList?moduleid='+moduleid);
		/*localStorage.setItem('collectionid', '');
		localStorage.setItem('collectionid', collectionid);*/
		localStorage.setItem('moduleid', '');
		localStorage.setItem('moduleid', moduleid);
    }

  GotoProfile(){
    this.props.history.push('/Profile');
  }
 
 GotoSendMessage()
  {
	  this.props.history.push('compose');
  }
  
  GotoEmergency()
  {
	  this.props.history.push('emergency');
  }
  
  GotoShooter()
  {
	  this.props.history.push('shooter');
  }
  
  GotoViewMessage()
  {
	this.props.history.push('messages');
	 
  }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  componentWillMount() {
  this.updateLocalStorage();
  localStorage.setItem('drill_log', '');
  }
  updateLocalStorage()
  {
	  if (localStorage.getItem('session')) {
	  
	  
  let storage_template = this.state.storage_template;
  let ObjStorage = this.state.ObjStorage;
  storage_template["action"]="getLocalStorageUpdate";
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  let userid = session_array.id;
  let groupid = session_array.group_id;
  storage_template["organizationid"]=organizationid;
  storage_template["userid"]=userid;
  storage_template["groupid"]=groupid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.storage_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const details = response.details;
		const organization_details = response.organization_details;
       if(status === "true")
       {
		localStorage.setItem('session', JSON.stringify(response.user_details));   
		localStorage.setItem('organization_details', '');   
		localStorage.setItem('safeguard', '');
		localStorage.setItem('organization_details', JSON.stringify(response.organization_details));
        localStorage.setItem('safeguard', JSON.stringify(response.details));
		//localStorage.setItem('safeguard_messages', JSON.stringify(response.messagelist));
		this.getCollectionList();
        this.getOrganizationList();
		this.getMessageList();
		
		let organization_token = localStorage.getItem("organization_details");
	    let organization_token_array=JSON.parse(organization_token);
	    let user_logo = organization_token_array.image_tag;
		let org_description = organization_token_array.description;
		this.setState({
          filepath: ReactHtmlParser(user_logo),
		  org_description: org_description
      })
		
       }       
 });
 
	  }else{
		  window.location.href = '/';
	  }
  }
  getOrganizationList()
  {
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  this.setState({
          ObjOrganization: session_array.organization
      })
  }

  getCollectionList()
  {
	  let safeguard= localStorage.getItem("safeguard");
      let safeguard_array=JSON.parse(safeguard);
	  this.setState({
          ObjCollection: safeguard_array.module,
		  loader: ''
      })
  }
  
  getMessageList(){
  let messages_template = this.state.messages_template;
  let ObjMessages = this.state.ObjMessages;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organization_id = session_array.organization_id;

  messages_template["action"]="getMessageList";
  messages_template["organization_id"]=organization_id;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.messages_template)
     })
      .then((response) => response.json())
      .then((response) => {
        const status=response.status;
        const message_list = response.message_list;
       if(status === "true")
       {
		localStorage.setItem('safeguard_messages', JSON.stringify(message_list));  
       }else{
		   localStorage.setItem('safeguard_messages', '');
	   }
 });
  }
  
  
  render() {
	
		
	let organization_details= localStorage.getItem("organization_details");
    let organization_details_array=JSON.parse(organization_details);
	let organization_messaging_enabled = organization_details_array.messaging_enabled;
	let token = localStorage.getItem("session");
	let token_array=JSON.parse(token);
	let user_level_2 = token_array.admin;
	let user_level_1 = token_array.organization_admin;
	let view_message_button = token_array.view_message_button;
	let user_super_admin = token_array.super_admin;
	let organization_token = localStorage.getItem("organization_details");
	let organization_token_array=JSON.parse(organization_token);
	let user_logo = this.state.filepath;
	let org_description = this.state.org_description;
	let filepath = <img className="orgLogo" src="/assets/images/ses-logo.png" /> ;
	//let org_logo = <img src={user_logo} /> ;
	
	let collectionList_count = this.state.ObjCollection.length;
	let collectionList;
	let collections_header;
	
	if(collectionList_count>0 && collectionList_count!=undefined)
	{
	collections_header = 'Modules';	
	collectionList = this.state.ObjCollection.map(v => (
      <li className="leftNav" key={v.id}><a onClick={()=>this.redirectChart(v.id)} className="ember-view"><img src={v.icon_path} alt="" /> <p>{v.title}</p></a></li>
    ));
	}else{
	collectionList = '';
	collections_header = '';
	}
	
	
/*alert(window.localStorage.length);*/
if (localStorage.getItem('session')) {	  
 return (
	
    <div id="ember361" className="ember-view ember-app"><div className="menu">
        <ul>
          <li></li>
          <li className="back"><a>M</a></li>
          <li className="titlebar"></li>
          <li className="logout"><a onClick= {this.logout}>o<span className="text">Logout</span></a></li>
          <li className="profile" onClick={this.GotoProfile}><a>u<span className="text">Profile</span></a></li>
          <li className="docs" onClick={this.GotoDoc}><a>d<span className="text">Docs</span></a></li>
		  <li className="docs" onClick={this.GotoForm}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/form.png" width="22px" height="20px" /> <span>Form</span></span></a></li>
		  <li className="docs" onClick={this.GotoReport}><a><span className="text no_marginL"><img className="orgLogo" src="/assets/images/report.png" width="22px" height="20px" /> <span>Report</span></span></a></li>
          <li className="help" onClick={this.GotoHelp}><a>?<span className="text">Help</span></a></li>
          {user_level_1==1 || user_super_admin==1 || user_level_2==1?<li className="admin" onClick={this.Gotoorg}><a  >c<span className="text">Admin</span></a></li>:''}
		  
        </ul>
      </div>
      <div id="application">
        <ul className="collections">
		<div className="userLogoBox">
		{user_logo}<div className="userName">{this.state.ObjOrganization}<p>{org_description}</p></div>
		</div>
		{view_message_button==1?
		  <Fragment>
          <li className="messageButtonCell">
            <a className="ember-view danger intruder" onClick={this.GotoEmergency}><div className="buttonText">Emergency</div></a>
            <a className="ember-view danger" onClick={this.GotoShooter} style={{marginLeft:3,}}><div className="buttonText">Active Shooter</div></a>
          </li>
          <li className="messageButtonCell">
            <a className="ember-view" onClick={this.GotoSendMessage}><div className="buttonText">Send Message</div></a>
            <a className="ember-view" onClick={this.GotoViewMessage} style={{marginLeft:3,}}><div className="buttonText">View Messages</div></a>
          </li>
		  </Fragment>
		  :''}
		  <div className={this.state.loader}></div>
		 <div className="moduleHeading">{collections_header}</div>
		 {collectionList}
        </ul>
      
      <div id="welcome">
        <div className="header">
          <div className="content">
          
            <div className="orgLogo-container">
            {filepath}
            </div>
          
          </div>
        </div>
        <div className="about">
          <h2>Safeguard Enterprise Solution (S.E.S.)</h2>
          <p>The Safeguard Enterprise Solution (SES) is a web platform with a mobile App that allows your organization to store and retrieve documents, policies, procedures, and safety plans.  The SES allows a user to create customized checklists for employees to follow for everything from opening/closing procedures, safety protocols, BWC claims, and more.</p>  <p>The SES allows reporting of your procedures and checklists to document your company’s actions of those steps.  The SES allows your company to make updates 24/7 so your employees have the information and documents needed when they need them.</p>
        </div>
        <div className="copyright">
        Copyright © 2020 Safeguard Risk Solutions LLC<br/><strong>Patent Pending</strong>
        </div>
      </div>
      
      </div>
      
      </div>
    );
} else {
 window.location.href = '/';
}	
}
}

export default Collection;
