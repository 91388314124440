import React, { Component } from 'react';
import queryString from 'query-string';
import ReactToPrint from 'react-to-print';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
class Logs extends Component {
  constructor(props){
    super(props);
    this.state = {
      organization_template: {},
      collection_template: {},
	  log_delete_template: {},
	  log_template: {},
	  collection_delete_template: {},
      ObjOrganizations: '',
      ObjCollections: [],
	  ObjLog : []
    }
    this.redirectOrganization=this.redirectOrganization.bind(this);
	this.redirectDashboard=this.redirectDashboard.bind(this);
	this.editProfile=this.editProfile.bind(this);
 }
  editProfile(user_id,organizationid){
  this.props.history.push('/EditProfile?userid='+ user_id+'&organization='+ organizationid);
  }
  redirectOrganization(){
  this.props.history.push('/Organization');
  }
  redirectDashboard(){
  this.props.history.push('/Collection');
  }

  logout() {
    if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
componentDidMount() {
  this.GetOrganizationDetail();
  this.GetLogList();
}

  GetOrganizationDetail(){
  let organization_template = this.state.organization_template;
  let ObjOrganizations = this.state.ObjOrganizations;
  organization_template["action"]="getOrganizationDetails"; 
  let session = localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organizationid = session_array.organization_id;
  organization_template["organizationid"]=organizationid; 
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.organization_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const name = response.name;
       if(status === "true")
       {
        this.setState({
          ObjOrganizations: response.name
      })
       }       
 });
 }
 
 GetLogList(){
  let log_template = this.state.log_template;
  let ObjLog = this.state.ObjLog;
  log_template["action"]="getLogList";
  const query = new URLSearchParams(this.props.location.search);
  const organizationid = query.get('organization');
  log_template["organizationid"]=organizationid;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let userid=session_array.id;
  log_template["userid"]=userid;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.log_template)
     })
      .then((response) => response.json())
      .then((response) => { 
        const status=response.status;
        const log_details = response.log_details;
       if(status === "true")
       {
        this.setState({
          ObjLog: response.log_details
      })
       }       
 });
}
deleteLogSubmit(logid)
{
  if (window.confirm("Are you sure you want to delete this Log")) {
  this.deleteLog(logid);
}
}
viewLog(logid,organization_id)
{
	this.props.history.push('/ViewLog?organizationid='+organization_id+'&logid='+logid+'');
}
deleteLog(logid)
{  
const query = new URLSearchParams(this.props.location.search);
const token = query.get('organization'); 
let log_delete_template = this.state.log_delete_template;
log_delete_template["logid"]=logid;
log_delete_template["action"]="deleteLog";

fetch(global.api_url,
  {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.log_delete_template)
  })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   this.props.history.push('/Logs?action=delete&organization='+ token);
	   window.location.reload();
    }       
});
}
      render() {
	  let sessiondata= localStorage.getItem("session");
      let sdata=JSON.parse(sessiondata);
      let user_id = sdata.id;
	  const query = new URLSearchParams(this.props.location.search);
      const organization_id = query.get('organization');
	
	let logList = this.state.ObjLog.map((v,index) => (
      <tr key={index}>
      <td>{v.created_at}</td>
      <td>{v.chart_title}</td>
      <td>{v.drill}</td>
      <td className="text-right">
          <a onClick={()=>this.viewLog(v.id,organization_id)}>View</a>&nbsp;
          <a data-method="delete" rel="nofollow"  onClick={()=>this.deleteLogSubmit(v.id)}>Delete</a>
      </td>
    </tr>
    ));
	
	if (localStorage.getItem('session')) {
    return (
      <div className="bodyscroll">
      <div className="navbar navbar-inverse navbar-static-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-left">
            <li><a className="home1" onClick={this.redirectDashboard} data-original-title="">{this.state.ObjOrganizations}</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
                <li className="active"><a onClick={this.redirectOrganization} data-original-title="" title="">Admin</a></li>
              <li className=""><a data-original-title="" onClick={()=>this.editProfile(user_id,organization_id)}>Edit Profile</a></li>
              <li><a onClick= {this.logout} data-method="delete"  rel="nofollow" data-original-title="" title="">Log Out</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container">
          <div className="row">
            <div className="col-xs-12">
            <a className="" onClick={this.redirectOrganization}  data-original-title="">Admin</a> » <a onClick={this.redirectOrganization}>Organization</a> » Logs
            </div>
          </div>
          
<div className="row">
<div className="col-xs-12">
<table className="table table-striped">
<tbody><tr>
<th>Log Start Time</th>
<th>Chart</th>
<th>Incident Type</th>
<th className="text-right">Actions</th>
</tr>
{logList}
</tbody>
</table>
</div>
</div>
		  
    </div>
</div>

     
    );
	}else{
	window.location.href = '/';	
	}
  }
}

export default Logs;
