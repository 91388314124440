import React, { Component } from 'react';
import './email_popup_style.css';
import axios from 'axios';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';

class ImportPopup extends Component{
	constructor(props){
        super(props);
        this.state = {
        email_to: '',
        fields: {},
        error: {}
        }
     }
  handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
	  let email_to = document.getElementById('email_to').value;
	  let organization_email = document.getElementById('organization_email').value;
	  let admin_email = document.getElementById('admin_email').value;
     //action 
     fields["action"]="sendEmailImport";
	 fields["organization_id"]=organization_id;
	 fields["email_to"]=email_to;
	 fields["admin_email"]=admin_email;
      //organization_name
      if(organization_email==''){
         formIsValid = false;
         errors["organization_email"] = "Cannot be empty";
      }else{
        fields["organization_email"]=organization_email;
	  }
     this.setState({errors: errors});
     return formIsValid;
    }	 
  handleChangeName(field, e){         
      let fields = this.state.fields;
      fields[field] = e.target.value;        
      this.setState({fields});
    }
	sendEmailPDF(e){
	 e.preventDefault();
	 if(this.handleValidation()){
	 console.log(this.state.fields);
	 this.sendImportEmailApi(); 
	 }else{
	 alert("Organization Email empty!");	 
	 }
	}	 
    sendImportEmailApi()
    {
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {
           const status=response.status;
           if(status === "true")
           { 
              alert('email has successfully sent with attachments');
			  window.location.reload();
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }	 
	handleChange(field, e){         
      let fields = this.state.fields;
      let isChecked = e.target.checked;
      fields[field] = isChecked;     
      this.setState({fields});
    }
  render() {
	  let session_element = localStorage.getItem("session");
      let session_array=JSON.parse(session_element);
	  let organization_id = session_array.organization_id;
	  let user_email = session_array.email;
    return (
      <div className='popup'>
        <div className='popup_inner'>
		<div class="modal-header">
          <h4>Emails</h4>
		  </div>
		        <div class="modal-body">
				<form class="new_variable"
				 id="new_variable"
                 acceptCharset="UTF-8"
				 onSubmit= {this.sendEmailPDF.bind(this)}>
				<div></div>
				<div class="row">Select emails you want to send the error log!</div>
				<br/>
			  <div class="row">
			  <input 
			  onChange={this.handleChange.bind(this,'organization_email')}
			  type="checkbox"
			  value={user_email}
			  id="organization_email"
			  name="organization_email" />&nbsp;
			  <label htmlFor="organization_email">{user_email}</label>
			  </div>
				<br />
				<div class="row">
				<label>Recipient</label>
				<input 
				class="form-control"
				id="email_to"
				name="email_to"
				onChange={this.handleChangeName.bind(this, "email_to")}
                value={this.state.fields["email_to"]}
				type="text" />
				</div>
				<br/>
			  <div class="row">
			  <input 
			  onChange={this.handleChange.bind(this,'admin_email')}
			  type="checkbox"
			  value="it@mobileemergencyresponseplans.com"
			  id="admin_email"
			  name="admin_email" />&nbsp;
			  <label htmlFor="organization_email">it@mobileemergencyresponseplans.com</label>
			  </div>
			  <br/>	
				<div class="row">
			    <input class="btn btn-primary" name="commit" type="submit" value="Send Email" />
				</div>
			    </form>
                </div>
				<div class="modal-footer">
				<button onClick={this.props.closePopup} class="btn btn-default">close</button>
				</div>
        </div>
      </div>
    );
  }
}
export default ImportPopup;