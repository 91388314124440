import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import alasql from 'alasql';
import { BrowserRouter as Router, NavLink, Switch, Link, Route ,HashRouter} from 'react-router-dom';
class messages extends Component {
    constructor(props){
        super(props);
        this.state = {
		   isLoggedIn: false,
		   messages_template: {},
		   delete_template: {},
		   ObjMessages: [],
		   ObjMessagesListCount : 0,
		   storage_template: {},
		   updateText: 'Edit',
		   messages_template: {},
		   ObjStorage: [],
		   ObjMessages: [],
		   messageEditClass: '',
		   ObjMessagesList: [],
		   ObjEachMessagesList: [],
		   ObjNotificationList: [],
		   fields: {},
		   render: false
        }
		this.redirectDashboard=this.redirectDashboard.bind(this);
		this.redirectPageLoad=this.redirectPageLoad.bind(this);
		this.replyMessage=this.replyMessage.bind(this);
		this.messageEdit=this.messageEdit.bind(this);
		this.redirectComposeMessage=this.redirectComposeMessage.bind(this);
     }

    logout() {
      if (window.confirm("Are you sure you want to log out?")) {
    localStorage.clear();
    window.location.href = '/';
    }
  }
  
  componentWillMount() {
  this.getMessageList();
  }
  componentDidMount(){
  this.viewEachMessageList();
  }
  redirectPageLoad()
  {
     window.location.reload();
  }
  messageEdit(keyword)
  {
	  if(keyword=='Edit')
	  {
	   this.setState({
      messageEditClass: 'edit',
	  updateText: 'Done'
      })	  
	  }else{
	  this.setState({
      messageEditClass: '',
	  updateText: 'Edit'
      })	  
	  }
	  
  }
  redirectDashboard(){
  this.props.history.push('/Collection');
  }
  replyMessage(messageid)
  {
	  this.props.history.push('/replymessages?id=' + messageid);
  }
  
  getMessageList(){
  let messages_template = this.state.messages_template;
  let ObjMessages = this.state.ObjMessages;
  let session= localStorage.getItem("session");
  let session_array=JSON.parse(session);
  let organization_id = session_array.organization_id;
  //localStorage.setItem('safeguard_messages', '');
  messages_template["action"]="getMessageList";
  messages_template["organization_id"]=organization_id;
  fetch(global.api_url,
     {
         method: 'POST',
         headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(this.state.messages_template)
     })
      .then((response) => response.json())
      .then((response) => {
        const status=response.status;
        const message_list = response.message_list;
       if(status === "true")
       {
		localStorage.setItem('safeguard_messages', JSON.stringify(message_list));
		this.viewMessageList();
       }else{
		   localStorage.setItem('safeguard_messages', '');
	   }
 });
  }
  
  
  handleValidation(){
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
	  let session= localStorage.getItem("session");
      let session_array=JSON.parse(session);
	  let userid = session_array.id;
	  let organizationid = session_array.organization_id;
	  const query = new URLSearchParams(this.props.location.search);
      const id = query.get('id');
     //param 
     fields["action"]="replyMessage";
	 fields["userid"]=userid;
	 fields["organizationid"]=organizationid;
	 fields["conversation_id"]=id;
      //message
      if(!fields["message"]){
         formIsValid = false;
         errors["message"] = "failed to send message";
      }
     this.setState({errors: errors});
     return formIsValid;
    }
	
  handleChangeName(field, e){         
  let fields = this.state.fields;
  fields[field] = e.target.value;        
  this.setState({fields});
  }
  messageSubmit(e){
      e.preventDefault();
      if(this.handleValidation()){
        this.replyMessageApi();
        console.log(this.state.fields);
      }else{
         alert("failed to send message");
      }
    }
  redirectComposeMessage()
  {
	  this.props.history.push('compose');
  }
  replyMessageApi()
    {
      fetch(global.api_url,
      {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'                 
          },
          body: JSON.stringify(this.state.fields)
      })
      .then((response) => response.json())
      .then((response) => {

           const status=response.status;
           if(status === "true")
           {
              this.props.history.push('/messages');
           }
           else{
              alert("Something went wrong, please try again");
           }
      });
    }
  deleteMessage(id)
  {
	  if (window.confirm("Are you sure you want to delete this message")) {
     this.deleteFullMessage(id);
	  }
  }
  deleteFullMessage(id)
  {
	  let delete_template = this.state.delete_template;
      delete_template["conversation_id"]=id;
      delete_template["action"]="viewMassageDelete";
	  
	  fetch(global.api_url,
      {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.delete_template)
      })
   .then((response) => response.json())
   .then((response) => { 
     const status=response.status;
    if(status === "true")
    {
	   alert('Message Deleted!');
	   window.location.reload();
    }       
   });
  }
  
  viewMessageList()
  {
	  let safeguard_messages = localStorage.getItem("safeguard_messages");
	  if(safeguard_messages!='')
	  {
      let safeguard_messages_array=JSON.parse(safeguard_messages);
	  
		  this.setState({
      ObjMessagesList: safeguard_messages_array.conversation
      })
	  }
	  
	  
  }
  
  viewEachMessageList()
  {
	  const query = new URLSearchParams(this.props.location.search);
      const id = query.get('id');
	  let safeguard_messages = localStorage.getItem("safeguard_messages");
	  if(safeguard_messages!='')
	  {
      let safeguard_messages_array=JSON.parse(safeguard_messages);
	  
	  let res = alasql('SELECT * FROM ? WHERE id = ?', [safeguard_messages_array.conversation,id]);
	  this.setState({
      ObjEachMessagesList: res
      })
	  }
	  
  }
 
render() {
if (localStorage.getItem('session')) {
const query = new URLSearchParams(this.props.location.search);
const id = query.get('id');
let messageList;
let delete_classname = this.state.messageEditClass;
const numRows = this.state.ObjMessagesList.length

//alert(JSON.stringify(this.state.ObjMessagesList));

messageList = this.state.ObjMessagesList.map(v => (
            <li className={v.unread!='false' ? "unread" : ""}>
            <a className="ember-view" onClick={()=>this.replyMessage(v.id)}>
              <div className="msg-replied">
                <span className="icon">
                 {v.unread!='false' ?  '—' : '—'}
                </span>
              </div>
              <div className="msg-inner">
                  <div className="msg-header">
                    <div className="msg-sender">
                        <h4>{v.sender.first_name} {v.sender.last_name}</h4>
                      <strong>{v.metadata.group}</strong>
                    </div>
                    <div className="msg-time">{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(new Date(v.last_message_at).getTime())}</div>
                  </div>
                  <div className="msg-body">{v.snippet}</div>
                  <div className="clear"></div>
                </div>
              </a>
              <button class="delete" data-ember-action={v.id} onClick={()=>this.deleteMessage(v.id)}>x</button>
              <div className="clear"></div>
          </li>
    ));



	
 return (
    <div id="ember361" className="ember-view ember-app">
	<div className="menu">
        <ul>
          <li className="home"><a onClick= {this.redirectDashboard}>h</a></li>
          <li className="back"><a>M</a></li>
          <li className="titlebar">Messages</li>
          <li className="refresh"><a onClick= {this.redirectPageLoad}>⟳</a></li>
          <li className="edit">
            <a onClick={()=>this.messageEdit(this.state.updateText)}>{this.state.updateText}</a>
          </li>
        </ul>
      </div>
	
		<div id="application">
        <ul className={'messages messages-index ' + delete_classname}>
		{messageList}
        <li className="messageButtonCell">
          <a className="ember-view message-button message-button-center" onClick= {this.redirectComposeMessage}>Send Message</a>
        </li>
        </ul>
      </div>
	  
      </div>
    );
} else {
 window.location.href = '/';
}	
}
}
export default messages;